<div class="container">
    <a class="btn btn-info text-white font-weight-normal text-xs">
    Update Recgnition  Details</a>

           <form [formGroup]="evnetform" >

               <div class="input-group input-group-outline my-3">
                   <!-- <label class="form-label"> Course Name</label> -->
                   <select  class="form-control" formControlName="funatworkcategoryid">
                       <option value="">select category</option>

                       <option *ngFor="let subCourse of category" [value]="subCourse.id">{{
                           subCourse.title }}</option>
                           
                   </select>
                  
                     <!-- <label class="form-label select-label">Example label</label> -->
                   <div class="invalid-feedback">category is required</div>
               </div>
              

               <div class="input-group input-group-outline my-3">
                   <!-- <label class="form-label">designation</label> -->
                   <input type="text" class="form-control"
                       formControlName="title" placeholder="Title">
               </div>
               <div class="input-group input-group-outline my-3">
                   <!-- <label class="form-label">Company</label> -->
                   <input type="text"  class="form-control" formControlName="description"
                       placeholder="description">
               </div>

               <div align="right">
                   <div class="input-group input-group-outline my-3">

                       <input type="file" id="selectedFile" class="form-control"
                           (change)="onFileSelected($event)">
                   </div>
  
                   
                   <button type="button" class="btn bg-gradient-secondary"
                       data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                   <button type="submit" (click)="onSubmit()"   class="btn bg-gradient-primary">Save
                       changes</button>
               </div>
           </form>
       </div>
      
    
<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#addhired">
            Upcoming Event</a>
         <!-- Modal -->
         <div class="modal fade" id="addhired" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                     <form [formGroup]="hireform"  >
                       
                      
                        <div class="input-group input-group-outline my-3">
                            
                                <input type="file" id="selectedFile" class="form-control" (change)="onFileSelected($event)" >
                        </div>
                        <div align="right">
                         <button type="button" class="btn bg-gradient-secondary"
                         data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                     <button type="submit"  (click)="onSubmit()" class="btn bg-gradient-primary">Save changes</button>
                    </div>
                     </form>
                 </div>
                 <div class="modal-footer">
                    
                 </div>
             </div>
         </div>
     </div>
     <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table class="table align-items-center mb-0">
                <thead>
                    
                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th> 
                       
                         <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th> 
                       
                         <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Status</th>
                        
                         <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center">
                                Action</th>
                          
                    </tr>
                </thead>
                <tbody >

                    <tr *ngFor="let item of pagedItems">
                        <td class="ps-4">{{item.index}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.image"
                                        class="avatar avatar-sm me-3">
                                </div>
                                </div>
                        </td>
                        <!-- <td>{{item.is_active}}</td> -->
                        <!-- <button (click)="changeStatus(item)">
                            Toggle Status
                          </button> -->
                          <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 
                        
                        <td class="d-flex justify-content-center">

                            <!-- <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs"(click)="editsyllabus(item.id)" >
                                Edit
                            </a> -->
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deleteupcoming1(item.id)">
                                delete
                            </a>

                        </td>
                           
                           

                            <!-- <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#edithire" (click)="openEditModal(item)">
                            Edit
                          </a> -->
                          <!-- Modal -->
                          <div class="modal fade" id="edithire" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form >
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Title</label> -->
                                                <input type="text" id="title" class="form-control" formControlName="title" placeholder="title">
                                                <!-- <input type="text" id="title" class="form-control" formControlName="title" placeholder="title"> -->
                                        </div>
                
                                         <div class="input-group input-group-outline my-3">
                                             <!-- <label class="form-label">description</label> -->
                                                 <input type="text" id="designation" class="form-control" formControlName="description" placeholder="description">
                                         </div>
                                        
                                        <div class="input-group input-group-outline my-3">
                                            
                                                <input type="file" id="selectedFile" class="form-control" (change)="onFileSelected($event)" accept="image/png" >
                                                <div *ngIf="hireform.get('image').hasError('invalidImage')" class="error">
                                                    Invalid image. Please select a PNG image (size <= 100KB).
                                                  </div>
                                            </div>
                                       
        
                                    </form>


                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="submit" class="btn bg-gradient-primary">Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- Modal End -->
                       

                      

                            <!-- <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deleteupcoming1(item.id)"> 
                                delete
                            </a>  -->

                        
                      
                        
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination  pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<br>
<br>



import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-handson-detail',
  templateUrl: './update-handson-detail.component.html',
  styleUrls: ['./update-handson-detail.component.css']
})
export class UpdateHandsonDetailComponent implements OnInit {
  handsonProject: FormGroup;
  handsonid: any;
  editForm: any;
  subcourses: any;
  handsonCategoryDetails: any;

  constructor(private service: CounterService, private fb: FormBuilder,private router:ActivatedRoute) {
    this.handsonProject = this.fb.group({
      handson_category_id: [],
      sub_course_id: [[]],
      title: [''],
      desc: [''],
    });
  }
  getsubcourse() {
    this.service.getsubcourse().subscribe((res) => {
      this.subcourses = res['data'];
      // this.joinTables();
    });
  }

  getCategories() {
    this.service.getHandsonCategory().subscribe((response:any)=>{
      this.handsonCategoryDetails = response.data;
    })
  
  }
    
    setdata(handson: any) {
      this.handsonProject.setValue({
        handson_category_id: handson.handson_category_id,
        sub_course_id: handson.sub_course_id,
        title:handson.title,
        desc: handson.desc // Use the 'image' property from the hire object
      });
    }
  ngOnInit(): void {
    this.getCategories();
    this.getsubcourse();
    this.router.params.subscribe(params => {
      this. handsonid= params['id'];
      console.log(this.handsonid);
      // this.setdata();
      
    });
  }
  onSubmit() {
    if (this.handsonProject.valid) {
      const data = {
        
        
        handson_category_id: this.handsonProject.value.handson_category_id,
        // const subCourseIds : Array.isArray(sub_course_id)
        // ? sub_course_id.map(String) // Convert all values to strings if multiple are selected
        // : [String(sub_course_id)];

        sub_course_id: Array.isArray(this.handsonProject.value.sub_course_id)
        ? this.handsonProject.value.sub_course_id.map(String)
        : [String(this.handsonProject.value.sub_course_id)],
        
        // sub_course_id: this.handsonProject.value.sub_course_id,
        title: this.handsonProject.value.title,
        desc: this.handsonProject.value.desc,
      };
      this.service.updateHandsonProject(this.handsonid,data).subscribe(
        (response: any) => {

          this.handsonProject.reset();
          if(response.StatusCode == '200') {
            // this.router.navigate(['/main/banner'])
            alert("Data added successfully");
            location.reload();
  
          } else {
            alert("Something went wrong");
          }
        },
      );
    }
  }

}

<div class="container">
<div class="row">
    <div class="div">
        <a class="btn btn-info text-white font-weight-normal text-xs" 
           >
            Update handson Category Data
        </a>
        <!-- Modal -->
        <div  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                        <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="handsonProject">

                            <div class="input-group input-group-outline mb-3">
                                <div class="input-group input-group-outline my-3">
                                    
                                    <!-- <label  class="form-label" >select handson category</label> -->
                                    <select formControlName="handsonCategory" class="form-control" formControlName="handson_category_id" >
                                        <option value="">select category</option>
                                        <option *ngFor="let category of handsonCategoryDetails"
                                            [value]="category.id">{{ category.title }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="input-group input-group-outline mb-3">
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label  class="form-label" for="subCourse">select sub course</label> -->
                                    <select class="form-control" formControlName="sub_course_id" multiple>
                                        <option value="">Select sub course</option>
                                        <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.subcourses_id">
                                            {{ subCourse.subcourses_name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="input-group input-group-outline mb-3">
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">title</label> -->
                                    <input type="text" class="form-control" formControlName="title"
                                        placeholder="title">
                                </div>
                            </div>
                            <div class="input-group input-group-outline mb-3">
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label for="description">Description:</label> -->
                                    
                                    <textarea formControlName="desc" placeholder="description" class="form-control"></textarea>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn bg-gradient-secondary"
                            data-bs-dismiss="modal">Close</button>
                        <button type="button" (click)="onSubmit()" class="btn bg-gradient-primary">Save
                            changes</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal End -->
    </div>
</div>
</div>
<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#addmentor">
             Add Mentor</a>
         <!-- Modal -->
         <div class="modal fade" id="addmentor" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                     <form [formGroup]="mentorform"   >
                       
                        <div class="input-group input-group-outline my-3">
                            <label class="form-label"> select sub course</label>
                            <select  class="form-control" formControlName="course_id" multiple>
                                <option value="">select sub course</option>
                                <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.subcourses_id">{{
                                    subCourse.subcourses_name }}</option>
                            </select>
                            <div class="invalid-feedback">Course is required</div>
                     </div>
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Name</label> -->
                                <input type="text" id="name" class="form-control" formControlName="name" placeholder="name">
                        </div>

                         <div class="input-group input-group-outline my-3">
                             <!-- <label class="form-label">designation</label> -->
                                 <input type="text" id="designation" class="form-control" formControlName="designation" placeholder="designation">
                         </div>
                         <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Company</label> -->
                                <input type="text" id="company" class="form-control" formControlName="company" placeholder="company">
                        </div>
                       
                        
                        <div class="input-group input-group-outline my-3">
                            
                                <input type="file"  class="form-control" (change)="onFileSelected($event)" >
                        </div>
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Company</label> -->
                                <input type="text" id="skills" class="form-control" formControlName="skills" placeholder="skill">
                        </div>
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Company</label> -->
                                <input type="text" id="experience" class="form-control" formControlName="experience" placeholder="experience">
                        </div>
                       
                        <div align="right">
                         <button type="button" class="btn bg-gradient-secondary"
                         data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                     <button type="submit"  class="btn bg-gradient-primary" (click)="onSubmit()"> Save changes</button>
                    </div>
                     </form>
                 </div>
                 <div class="modal-footer">
                    
                 </div>
             </div>
         </div>
     </div>
     <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Course Name</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Name</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Designation</th>
                        
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Company</th>

                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Skills</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center"> Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <td>{{item.index}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.image"
                                        class="avatar avatar-sm me-3">
                                </div>
                                </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.subcourse_details}}</p>
                            <p class="text-xs text-secondary mb-0">Developer</p>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.name}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.designation}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.company}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.skills}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.experience}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>

                        
                        <!-- <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td>  -->
                         
                        <!-- <td class="align-middle">
                           
                           

                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editabout" (click)="openEditModal(item)">
                            Edit
                        </a> -->

                          <!-- Modal -->
                          <!-- <div class="modal fade" id="editabout" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form [formGroup]="editForm">
                                       
                                       
                                        
        
                                    </form>


                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="submit" class="btn bg-gradient-primary" (click)="updatementor(item.id)">Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <!-- Modal End -->
                       


                       

                        <td class="d-flex justify-content-center">
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs"
                             (click)="editsyllabus(item.id)">
                            Edit
                        </a>

                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"(click)="deletementor(item.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>
<br>
<br>



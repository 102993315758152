<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#addbroucher">
           Add Cohorts
        </a>
         <!-- Modal -->
         <div class="modal fade" id="addbroucher" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
              <div class="modal-body">
  <form [formGroup]="batchForm" (ngSubmit)="onSubmit()">
    <div class="input-group input-group-outline my-3">
      <!-- <label class="form-label">Start Date</label> -->
      <input type="text" class="form-control" formControlName="start_date" placeholder="start date">
    </div>

    <div class="input-group input-group-outline my-3">
      <!-- <label class="form-label">Start Time</label> -->
      <input type="text" class="form-control" formControlName="start_time" placeholder="start time">
    </div>

    <div class="input-group input-group-outline my-3">
      <!-- <label class="form-label">End Time</label> -->
      <input type="text" class="form-control" formControlName="end_time" placeholder="end time">
    </div>

    <div class="input-group input-group-outline my-3">
      <!-- <label class="form-label">Batch Name</label> -->
      <input type="text" class="form-control" formControlName="batch_name" placeholder="batch name">
    </div>
<div align="right">
    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
    <button type="submit" class="btn bg-gradient-primary">Save changes</button>
</div> 
</form>
</div>
<div class="modal-footer">
  
</div>

     <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table  >
                <thead>
                    
                    <tr>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                             Start Date</th>
                       
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Start Time</th>
                       
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            End Time</th>
                      
                        <th class="text-secondary opacity-7">Batch Name</th>

                        <th class="text-secondary opacity-7">Action</th>

                        
                       
                    </tr>
                </thead>
                <tbody >

                    <tr *ngFor="let item of batches ">
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.start_date}}</p>
                        </td>
                       
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.start_time}}</p>

                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.end_time}}</p>

                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.batch_name}}</p>

                        </td>
                        <td>
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="onDeleteBatch(item.id)">
                                delete
                            </a>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<br>
<br>
<div class="card">
    <div class="div"></div>
    <div class="table-responsive">
        
        <table class="table align-items-center mb-0">
            <thead>
                
                <tr>
                    
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Start Date</th>
                  
                   <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Start Time</th>
                  
                   <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                       End Time</th>
                 
                   <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Batch Name</th>

                   <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of batches ">
                    <td>
                        
                            <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-xs">{{item.start_date}}</h6>
                            </div>
                       
                    </td>
                   
                    <td>
                        <p class="text-xs font-weight-bold mb-0">{{item.start_time}}</p>

                    </td>
                    <td>
                        <p class="text-xs font-weight-bold mb-0">{{item.end_time}}</p>

                    </td>
                    <td>
                        <p class="text-xs font-weight-bold mb-0">{{item.batch_name}}</p>

                    </td>
                    <td>
                        <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="onDeleteBatch(item.id)">
                            delete
                        </a>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>





<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#addTeacher">
            Meet Our Teacher</a>
            &nbsp;&nbsp;<button class="excel btn btn-info font-weight-normal text-xs" (click)="exportToExcel()">Export to Excel</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <!-- Modal -->
         <div class="modal fade" id="addTeacher" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                     <form [formGroup]="aboutForm" (ngSubmit)="onSubmit()"  >
                       
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">name</label> -->
                                <input type="text" id="name" class="form-control" formControlName="name">
                        </div>

                         <div class="input-group input-group-outline my-3">
                             <label class="form-label">designation</label>
                                 <input type="text" id="designation" class="form-control" formControlName="designation">
                         </div>
                        
                        <div class="input-group input-group-outline my-3">
                            
                                <input type="file" id="selectedFile" class="form-control" (change)="onFileSelected($event)" >
                        </div>
                        <div align="right">
                         <button type="button" class="btn bg-gradient-secondary"
                         data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                     <button type="submit"  class="btn bg-gradient-primary">Save changes</button>
                    </div> 
                    </form>
                 </div>
                 <div class="modal-footer">
                    
                 </div>
             </div>
         </div>
     </div>
     <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table class="table align-items-center mb-0" id="tableData">
                <thead >
                    
                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                       
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                       
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Destination</th>
                      
                        <!-- <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                        -->
                    </tr>
                </thead>
                <tbody >

                    <tr *ngFor="let item of pagedItems;let ndx=index ">
                        <td>{{ndx+1}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.image"
                                        class="avatar avatar-sm me-3">
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.name}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.designation}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                      
                        
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<br>
<br>



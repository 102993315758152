<div class="container main-part">
  <div class="row">
    <div class="div">
      <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal" data-bs-target="#addconsult">
        Add Course Fees
      </a>
      <!-- Modal -->
      <div class="modal fade" id="addconsult" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
              <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="ProgramFeesFormData" >
                <div class="input-group input-group-outline my-3">
                  <!-- <label for="course">select Fees Categroy</label> -->
                  <select id="pro_max_id" formControlName="pro_max_id" class="form-control" required>
                  <option value="" >select pro max category</option>
                  <option [value]="pro_max_id_new.id" *ngFor="let pro_max_id_new of pro_max_id" style="color: black;">{{
                    pro_max_id_new.title }}</option>
                </select>
                </div>

                <div class="input-group input-group-outline my-3">
                  
                  <select id="course" formControlName="course_id" class="form-control" required
                    (change)="getSubCourseFromCourse($event)">
                    <option value="" >select course</option>
                    <option [value]="course.id" *ngFor="let course of courseDetails" style="color: black;">{{
                      course.name }}</option>
                  </select>
                  <div class="invalid-feedback">Course is required</div>
                </div>

                <div class="input-group input-group-outline my-3">
                  <!-- <label for="course">Subcourse</label> -->
                  <select class="form-control" formControlName="sub_course_id">
                    <option value="">Select sub course</option>
                    <option *ngFor="let subCourse of subcourses" [value]="subCourse.id">
                        {{ subCourse.name }}
                    </option>
                </select>
                  <div class="invalid-feedback">Sub Course is required</div>
                </div>


              

                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Job Assistance</label> -->
                  <input type="text" class="form-control" formControlName="job_assistance" placeholder="Appointment Letter">
                </div>

                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Live Class Subscription</label> -->
                  <input type="text" class="form-control" formControlName="live_class_subscription"
                    placeholder="Mock Interview">
                </div>

                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">LMS Subscription</label> -->
                  <input type="text" class="form-control" formControlName="lms_subscription"
                    placeholder="Live Project">
                </div>

                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Job Referrals</label> -->
                  <input type="text" class="form-control" formControlName="job_referrals" placeholder="Completion Certificate">
                </div>

                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Industry Projects</label> -->
                  <input type="text" class="form-control" formControlName="industry_projects"
                    placeholder="Experience Letter">
                </div>
                
                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Industry Projects</label> -->
                  <input type="text" class="form-control" formControlName="capstone_projects"
                    placeholder="Job Assistence">
                </div>

                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Domain Training</label> -->
                  <input type="text" class="form-control" formControlName="domain_training"
                    placeholder="Resume Building">
                </div>


                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Project Certification From Companies</label> -->
                  <input type="text" class="form-control" formControlName="project_certification_from_companies"
                    placeholder="Job Guraentee">
                </div>

                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Advance AI DSA</label> -->
                  <input type="text" class="form-control" formControlName="adv_ai_dsa" placeholder="Job Refferal">
                </div>


                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Microsoft Certification</label> -->
                  <input type="text" class="form-control" formControlName="microsoft_certification"
                    placeholder="Training Support (1 Year)">
                </div>
                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Microsoft Certification</label> -->
                  <input type="text" class="form-control" formControlName="sub_course_fee"
                    placeholder=" sub course fee">
                </div>
                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Microsoft Certification</label> -->
                  <input type="text" class="form-control" formControlName="sub_course_duration"
                    placeholder="sub course duration">
                </div>



                <div align="right">
                  <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                  <button type="submit" class="btn bg-gradient-primary" (click)="onSubmit()">Save changes</button>

                </div>
              </form>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
      <!-- Modal End -->
    </div>
  </div>
  <div class="card">
    <div class="div"></div>
    <div class="table-responsive">

      <table class="table align-items-center mb-0">
        <thead>

          <tr> <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
            Sr.no</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center">
             Fees Category</th>
              <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
               Course Name</th>
                 <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              SubCourse Name</th>

            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Appointment Letter</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Mock Interview</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Live Project</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Completion Certificate</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Experience Letter</th>
         
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Job Assistance</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Resume Building</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Job Guarantee</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Job Referral</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Training Support (1 Year)
            </th>
            <!-- <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              project_certification_from_companies</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">adv_ai_dsa</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              microsoft_certification</th> -->
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 left">sub course fee</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 left">sub course duration</th>
            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 left d-flex justify-content-center">Action</th>



          </tr>
        </thead>
        <tbody style="text-align: center;">

          <tr *ngFor="let item of pagedItems ">
            <td>{{item.index}}</td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-middle">{{item.pro_max_name}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-start">{{item.course_name}}</p>

            </td>

            <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-middle">{{item.sub_course_name}}</p>

            </td>

            <!-- <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-middle">{{item.sub_course_fee}}</p>

            </td> -->
            <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-middle">{{item.job_assistance}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.live_class_subscription}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.lms_subscription}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.job_referrals}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.industry_projects}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.capstone_projects}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.domain_training}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.project_certification_from_companies}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0">{{item.adv_ai_dsa}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-middle">{{item.microsoft_certification}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-middle">{{item.sub_course_fee}}</p>

            </td>
            <td>
              <p class="text-center text-xs font-weight-bold mb-0 align-middle">{{item.sub_course_duration}}</p>

            </td>
            <!-- <td class="align-middle">
              <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#editconsult">
                Edit
              </a> -->

              <!-- <div class="modal fade" id="editconsult" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form [formGroup]="ProgramFeesFormData">


                        <div class="input-group input-group-outline my-3">
                          <select id="pro_max_id" formControlName="pro_max_id" class="form-control" required>
                            <option value="" >select pro max category</option>
                            <option [value]="pro_max_id_new.id" *ngFor="let pro_max_id_new of pro_max_id" style="color: black;">{{
                              pro_max_id_new.title }}</option>
                          </select>
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label for="course">Course</label>
                          <select id="course" formControlName="course_id" class="form-control" required
                            (change)="getSubcoursesbyId($event)">
                            <option value="" disabled selected>select main category</option>
                            <option [value]="course.id" *ngFor="let course of courseDetails" style="color: black;">{{
                              course.name }}</option>
                          </select>
                          <div class="invalid-feedback">Course is required</div>
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label for="course">Subcourse</label>
                          <select class="form-control" formControlName="sub_course_id">
                            <option value="">Select sub course</option>
                            <option *ngFor="let subCourse of subcourses" [value]="subCourse.course_id">
                                {{ subCourse.name }}
                            </option>
                        </select>
                          <div class="invalid-feedback">Sub Course is required</div>
                        </div>
        
        
                      
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Job Assistance</label>
                          <input type="text" class="form-control" formControlName="job_assistance" placeholder="Appointment Letter">
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Live Class Subscription</label>
                          <input type="text" class="form-control" formControlName="live_class_subscription"
                            placeholder="Mock Interview">
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">LMS Subscription</label>
                          <input type="text" class="form-control" formControlName="lms_subscription"
                            placeholder="Live Project">
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Job Referrals</label>
                          <input type="text" class="form-control" formControlName="job_referrals" placeholder="Completion Certificate">
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Industry Projects</label>
                          <input type="text" class="form-control" formControlName="industry_projects"
                            placeholder="Experience Letter">
                        </div>
                        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Industry Projects</label>
                          <input type="text" class="form-control" formControlName="capstone_projects"
                            placeholder="Job Assistence">
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Domain Training</label>
                          <input type="text" class="form-control" formControlName="domain_training"
                            placeholder="Resume Building">
                        </div>
        
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Project Certification From Companies</label>
                          <input type="text" class="form-control" formControlName="project_certification_from_companies"
                            placeholder="Job Guraentee">
                        </div>
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Advance AI DSA</label>
                          <input type="text" class="form-control" formControlName="adv_ai_dsa" placeholder="Job Refferal">
                        </div>
        
        
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Microsoft Certification</label>
                          <input type="text" class="form-control" formControlName="microsoft_certification"
                            placeholder="Training Support (1 Year)">
                        </div>
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Microsoft Certification</label>
                          <input type="text" class="form-control" formControlName="sub_course_fee"
                            placeholder=" sub course fee">
                        </div>
                        <div class="input-group input-group-outline my-3">
                          <label class="form-label">Microsoft Certification</label>
                          <input type="text" class="form-control" formControlName="sub_course_duration"
                            placeholder="sub course duration">
                        </div>


                      </form>

                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" (click)="updateProgramFeesData(item.id)"
                        class="btn bg-gradient-primary">Save changes</button>
                    </div>
                  </div>
                </div>
              </div> -->



            <td class="d-flex justify-content-center">
              <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
              data-bs-target="#editconsult">
              Edit
            </a>
              <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                (click)="deleteProgramFeesData(item.fee_details_id)">
                delete
              </a>

            </td>


          </tr>
        </tbody>
      </table>
      <nav aria-label="Page navigation">
        <ul class="pagination pagination-info justify-content-end">
            <li class="page-item" [class.disabled]="currentPage == 1">
                <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                    keyboard_arrow_left
                  </span></a>
            </li>
            <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                <a class="page-link" (click)="setPage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage == totalPages">
                <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                    keyboard_arrow_right
                  </span></a>
            </li>
        </ul>
    </nav>

    </div>
  </div>
</div>
<br>
<br>
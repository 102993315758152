import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'toggle-button',
    template: `<button [ngClass]="state ? onColor : offColor" (click)="onClick()">
      {{ state ? onText : offText }}
    </button>`
})
export class ToggleButtonComponent {
    @Input() state: boolean;
    @Input() onColor: string;
    @Input() onText: string;
    @Input() offColor: string;
    @Input() offText: string;
    @Input() config: ToggleButtonConfig; 
    @Output() onStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public ngOnInit(): void {
      if (this.config !== null) {
        this.state = this.config.state;
        this.onColor = this.config.onColor;
        this.offColor = this.config.offColor;
        this.onText = this.config.onText;
        this.offText = this.config.offText;
      }
      if (typeof(this.state) !== 'boolean')
        this.state = false;
      if (!this.onText || this.onText === '') 
        this.onText = "Button";
      if (!this.offText || this.offText === '')
        this.offText = this.onText;
      if (!this.offColor || this.offColor === '')
        this.offColor = this.onColor;
    }

    public onClick(): void {
        this.state = !this.state;
        this.onStateChange.emit(this.state);
    }
}

export interface ToggleButtonConfig {
  state?: boolean;
  onColor: string;
  onText: string;
  offColor?: string;
  offText?: string;
}
<p>update-eventdata works!</p>
<div class="container">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#eventcategory">
            Event Data
        </a>
         <!-- Modal -->
         <div class="modal fade" id="eventcategory" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                    <form [formGroup]="evnetform" >

                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label"> Course Name</label> -->
                            <select  class="form-control" formControlName="subcourse_id">
                                <option value="">select sub course</option>

                                <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">{{
                                    subCourse.subcourses_name }}</option>
                                    
                            </select>
                           
                              <!-- <label class="form-label select-label">Example label</label> -->
                            <div class="invalid-feedback">Course is required</div>
                        </div>
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Name</label> -->
                            <select formControlName="event_id" class="form-control"  >
                                <option value=""> Event Name</option> 
                                <option *ngFor="let category of list" [value]="category.event_id">{{ category.title }}</option>
                              </select> 
                              
                     
                        </div>

                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">designation</label> -->
                            <input type="text" class="form-control"
                                formControlName="title" placeholder="Title">
                        </div>
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Company</label> -->
                            <input type="text"  class="form-control" formControlName="description"
                                placeholder="description">
                        </div>

                        <div align="right">
                            <div class="input-group input-group-outline my-3">

                                <input type="file" id="selectedFile" class="form-control"
                                    (change)="onFileSelected($event)">
                            </div>
           
                            
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                            <button type="submit" (click)="onSubmit()"   class="btn bg-gradient-primary">Save
                                changes</button>
                        </div>
                    </form>
                </div>
               
             </div>
         </div>
     </div>
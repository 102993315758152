import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.css']
})
export class UpcomingComponent implements OnInit {
  base64Image: string;
  hirelist: any;
  value: boolean;
  config: ToggleButtonConfig;
  status: any;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];

  constructor(private newweb:CounterService,private formBuilder:FormBuilder,private router:Router){
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.newweb.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }
  


  ngOnInit(): void {
    this.addupcoming();
    this.getupcoming();
    // this.getStatus();
    
    // this.createEditForm();
    
  }
  setPage(page: number) {
    this.currentPage = page;
  
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.hirelist.length);
    
    this.pagedItems = this.hirelist.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.hirelist.length / this.itemsPerPage);
    
    // Calculate the index to continue numbering across pages
    const indexOffset = (page - 1) * this.itemsPerPage;
    this.pagedItems = this.pagedItems.map((item, index) => {
        return {
            ...item,
            index: index + 1 + indexOffset
        };
    });
  
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  prevPage() {
      if (this.currentPage > 1) {
          this.setPage(this.currentPage - 1);
      }
  }
  
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.setPage(this.currentPage + 1);
      }
  }
  editsyllabus(syllabusId: number) {
    this.router.navigate(['main/update-upcoming', syllabusId]);
  }
  addupcoming(): void {
    this.hireform = this.formBuilder.group({
      selectedFile: [null, [Validators.required, this.validateImageType, this.validateImageSize]],
      // selectedFile: [null, Validators.required],
      is_active:['',Validators.required]
    });
  }
  validateImageType(control: AbstractControl): ValidationErrors | null {
    const file = control.value;
    if (file) {
      if (file.type !== 'image/png') {
        return { invalidImageType: true };
      }
    }
    return null;
  }
  
  validateImageSize(control: AbstractControl): ValidationErrors | null {
    const file = control.value;
    if (file) {
      if (file.size > 100 * 1024) { // 100 KB in bytes
        return { invalidImageSize: true };
      }
    }
    return null;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onSubmit(): void {
  

    const formData = new FormData();
   
    formData.append('image', this.base64Image);
    formData.append('is_active',this.hireform.value.is_active)

    this.newweb.addupcoming(formData).subscribe(
      (response: any) => {
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }

  getupcoming(){
    this.newweb.getupcoming().subscribe((res:any)=>{
      console.log(res);
      this.hirelist=res;
      
      this.setPage(this.currentPage);

    })
  }

  

  deleteupcoming1(id: number) {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.newweb.deleteupcoming(id).subscribe(
        (response) => {
          console.log('Project deleted:', response);
          alert("Data Deleted")
          // You might want to refresh the categories list after deletion
          this.getupcoming();
          location.reload();
        },
        (error) => {
          console.error('Error deleting Project:', error);
        }
      );
    }
  }

}

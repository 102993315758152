import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { CKEditor4 } from 'ckeditor4-angular';
import { Router } from '@angular/router';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';


@Component({
  selector: 'app-syllabus-module',
  templateUrl: './syllabus-module.component.html',
  styleUrls: ['./syllabus-module.component.css']
})
export class SyllabusModuleComponent  implements OnInit{


  ProgramFeesFormData:FormGroup
  ProgramFeesData:any[]
  courseDetails: any[] = [];
  subcourseDetails: any;
  editData:any
  counterlist: any;
  bannerlist: any;
  subcourses: any;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];


  
  stripTags(value: string): string {
    if (!value) return value;
    // Use a regular expression to remove HTML tags
    return value.replace(/<[^>]*>/g, '');
  }
  get ckeditorTextFormElement() { return this.ProgramFeesFormData.get('description'); }
  ckEditorConfig: any = { toolbar: [

  [ 'NumberedList','Bold', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl' ],
 
	] };
  constructor(private service:CounterService,private formBuilder:FormBuilder,private router:Router){
    
    this.ProgramFeesFormData = this.formBuilder.group({
      
      course_id: [''],
      module_id: [''],
     
      title: ['',Validators.required],
      description: ['',Validators.required],
    
    });
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }



  config: ToggleButtonConfig;
  status: any;

  
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.service.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }
  

 

  ngOnInit(): void {
    this.getProgramFeesData()

    this.getsubcourse();
    this.getcounterdata();
   
  }
  getcounterdata() {
    this.service.getmodule().subscribe((res: any) => {
      this.counterlist = res.data;
     
    });
  }

  getsubcourse() {
    this.service.getsubcourse().subscribe((res: any) => {
      this.subcourses = res.data;
     
    });
  }

  editsyllabus(syllabusId: number) {
    this.router.navigate(['main/update-syllabus', syllabusId]);
  }
  getProgramFeesData() {
    this.service.getsyllabus().subscribe((res: any) => {
      this.ProgramFeesData = res.data;
      this.ProgramFeesData.sort((a, b) => b.id - a.id);//////Desc order
      this.setPage(this.currentPage);
    
     
    });
  }
  
setPage(page: number) {
  this.currentPage = page;

  const startIndex = (page - 1) * this.itemsPerPage;
  const endIndex = Math.min(startIndex + this.itemsPerPage, this.ProgramFeesData.length);
  
  this.pagedItems = this.ProgramFeesData.slice(startIndex, endIndex);
  this.totalPages = Math.ceil(this.ProgramFeesData.length / this.itemsPerPage);
  
  // Calculate the index to continue numbering across pages
  const indexOffset = (page - 1) * this.itemsPerPage;
  this.pagedItems = this.pagedItems.map((item, index) => {
      return {
          ...item,
          index: index + 1 + indexOffset
      };
  });

  this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
}
prevPage() {
    if (this.currentPage > 1) {
        this.setPage(this.currentPage - 1);
    }
}

nextPage() {
    if (this.currentPage < this.totalPages) {
        this.setPage(this.currentPage + 1);
    }
}


 
  onSubmit(){
    const formData = new FormData();
  const formValue = this.ProgramFeesFormData.value;
  
    Object.keys(formValue).forEach(key => {
    formData.append(key, formValue[key]);
  });

  this.service.addsyllabus(formData).subscribe(
    (response: any) => {
      if(response.StatusCode == '200') {
        // this.router.navigate(['/main/banner'])
        alert("Data added successfully");
        location.reload();

      } else {
        alert("Something went wrong");
      }
    },
  );
}



  deleteProgramFeesData(id:number){
    const confirmDelete = confirm('Are you sure you want to delete this record?');
    
    if (confirmDelete) {
      this.service.deletesyllabus(id).subscribe(
        () => {
          console.log('Data deleted successfully');
          // You can also refresh the data or perform other actions here
        },
        error => {
          console.error('Failed to delete data:', error);
        }
      );
    }
  }

}

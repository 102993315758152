<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#addhigh">
                Add Highlight
            </a>
            <!-- Modal -->
            <div class="modal fade" id="addhigh" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="expertform">




                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">title</label> -->
                                    <input type="text" class="form-control" formControlName="title" placeholder="title">
                                </div>



                                <div class="input-group input-group-outline my-3">

                                    <input type="file" id="selectedFile" class="form-control"
                                        (change)="onFileSelected($event)">
                                </div>
                                <div align="right">
                                    <button type="button" class="btn bg-gradient-secondary"
                                        data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                                    <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save
                                        changes</button>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            title</th>



                        <th class="text-secondary opacity-7">Action</th>



                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of expertlist ">
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.icon" class="avatar avatar-sm me-3">
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                    <p class="text-xs font-weight-bold mb-0">{{item.title}}</p>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>

                        <td class="align-middle">



                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                                (click)="openEditModal(item)" data-bs-target="#editexpert">
                                Edit
                            </a>

                            <!-- Modal -->
                            <div class="modal fade" id="editexpert" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit
                                                Course</h5>
                                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form [formGroup]="editForm1">



                                                <div class="input-group input-group-outline my-3">
                                                    <!-- <label class="form-label">title</label> -->
                                                    <input type="text" id="title" class="form-control"
                                                        formControlName="title" placeholder="title">
                                                </div>



                                                <div class="input-group input-group-outline my-3">

                                                    <input type="file" id="selectedFile" class="form-control"
                                                        (change)="onFileSelected($event)">
                                                </div>


                                            </form>

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn bg-gradient-secondary"
                                                data-bs-dismiss="modal">Close</button>
                                            <button type="submit" (click)="updateexpert(item)"
                                                class="btn bg-gradient-primary">Save changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal End -->


                        <td>


                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                                (click)="deletereview(item.id)">
                                delete
                            </a>

                        </td>


                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<br>
<br>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';
import { CKEditor4 } from 'ckeditor4-angular';

@Component({
  selector: 'app-update-syllabus',
  templateUrl: './update-syllabus.component.html',
  styleUrls: ['./update-syllabus.component.css']
})
export class UpdateSyllabusComponent implements OnInit {
  syllabusId: any;
  ProgramFeesFormData: FormGroup;
  counterlist: any;
  subcourses: any;
  ProgramFeesData: any;
  syllabus: any;
  course_id: number;
  paramid:any;
  id:number;
  data: any;
  get ckeditorTextFormElement() { return this.ProgramFeesFormData.get('description'); }
  ckEditorConfig: any = { toolbar: [

  [ 'NumberedList','Bold', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl' ],
 
	] };

  constructor(private service:CounterService,private formBuilder:FormBuilder,private router:ActivatedRoute){
    this.ProgramFeesFormData = this.formBuilder.group({
      
      course_id: [''],
      module_id: [''],
     
      title: ['',Validators.required],
      description: ['',Validators.required],
    

      
    });
  }


  ngOnInit(): void {
    this.getcounterdata();
    this.getsubcourse();
    this.getProgramFeesData();
    this.ProgramFeesFormData.patchValue(this.data);
    // this.getsyllabusbyid();
  
  this.router.params.subscribe(params => {
    this.id= params['id'];
    console.log(this.id);
    this.paramid = this.id;
    this.ProgramFeesFormData.patchValue(this.data);
  })
  this.service.getsyllabus().subscribe((res: any) => {
    this.ProgramFeesData = res.data;
    for( var i=0;i<this.ProgramFeesData.length;i++){
      if (parseInt(this.ProgramFeesData[i].id) === this.id){
        this.data=this.ProgramFeesData[i];
        // console.log(this.data);
        
        break;

      }
    }
  })
 
  }
getsyllabusbyid(){
  this.service.getsyllabusid(this.syllabusId).subscribe((res: any) => {
    const syllabus = res.data;
    
     
    console.log(this.syllabusId);
     // Assuming 'data' contains the syllabus information

    this.ProgramFeesFormData.patchValue({
      course_id: syllabus.course_id,
      module_id: syllabus.module_id,
      title: syllabus.title,
      description: syllabus.description
      // Assuming other form fields to be patched similarly if available
    });
  });
}

  
  getcounterdata() {
    this.service.getmodule().subscribe((res: any) => {
      this.counterlist = res.data;
     
    });
  }

  getsubcourse() {
    this.service.getsubcourse().subscribe((res: any) => {
      this.subcourses = res.data;
     
    });
  }
  getProgramFeesData() {
    this.service.getsyllabus().subscribe((res: any) => {
      this.ProgramFeesData = res.data;
     
    });
  }
  

  onSubmit(){
    
    // const formData = new FormData();
    // const formValue = this.ProgramFeesFormData.value;
    
    // Object.keys(formValue).forEach(key => {
    //   formData.append(key, formValue[key]);
    // });
    if (this.ProgramFeesFormData.valid) {
      if (this.data) {
        this.service.updatesyllabus(this.data.syllabusId, this.ProgramFeesFormData.value)
        .subscribe(
  
    // this.service.updatesyllabus(this.syllabusId,formData).subscribe(
      (response: any) => {
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          // location.reload();
          this.getProgramFeesData();
  
        } else {
          alert("Something went wrong");
        }
      },
    );
  }}
}
  }

        

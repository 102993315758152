<div class="container main-part">
    <div class="row">
      
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table class="table align-items-center mb-0">
                <thead>
                    
                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Subject
                        </th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Email</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Phone</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Message</th>
                       
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of contactlist">
                        <td>
                            <div class="d-flex px-2 py-1">
                                <!-- <div>
                                    <img src="https://demos.creative-tim.com/test/material-dashboard-pro/assets/img/team-4.jpg"
                                        class="avatar avatar-sm me-3">
                                </div> -->
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.name}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.subject}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.email}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.phone}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.message}}</span>
                        </td>
                        <td class="align-middle">
                           
                           <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deletecontact(item.id)" >
                      delete
                  </a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
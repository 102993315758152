<div class=" container modal-body">
    <a class="btn btn-info text-white font-weight-normal text-xs">
     Update Mentor
 </a>
            <form [formGroup]="mentorform"   >
              
               <div class="input-group input-group-outline my-3">
                   <label class="form-label"> select sub course</label>
                   <select  class="form-control" formControlName="course_id" multiple>
                       <option value="">select sub course</option>
                       <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.subcourses_id">{{
                           subCourse.subcourses_name }}</option>
                   </select>
                   <div class="invalid-feedback">Course is required</div>
            </div>
               <div class="input-group input-group-outline my-3">
                   <!-- <label class="form-label">Name</label> -->
                       <input type="text" id="name" class="form-control" formControlName="name" placeholder="name">
               </div>

                <div class="input-group input-group-outline my-3">
                    <!-- <label class="form-label">designation</label> -->
                        <input type="text" id="designation" class="form-control" formControlName="designation" placeholder="designation">
                </div>
                <div class="input-group input-group-outline my-3">
                   <!-- <label class="form-label">Company</label> -->
                       <input type="text" id="company" class="form-control" formControlName="company" placeholder="company">
               </div>
              
               
               <div class="input-group input-group-outline my-3">
                   
                       <input type="file"  class="form-control" (change)="onFileSelected($event)" >
               </div>
               <div class="input-group input-group-outline my-3">
                   <!-- <label class="form-label">Company</label> -->
                       <input type="text" id="skills" class="form-control" formControlName="skills" placeholder="skill">
               </div>
               <div class="input-group input-group-outline my-3">
                   <!-- <label class="form-label">Company</label> -->
                       <input type="text" id="experience" class="form-control" formControlName="experience" placeholder="experience">
               </div>
              
               <div align="right">
                <button type="button" class="btn bg-gradient-secondary"
                data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
            <button type="submit"  class="btn bg-gradient-primary" (click)="onSubmit()"> Save changes</button>
           </div>
            </form>
      </div>
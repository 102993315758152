<div class=" container modal-body">
    <a class="btn btn-info text-white font-weight-normal text-xs">
     Update Certificate
 </a>
                            <form [formGroup]="certificate">

                                <!-- <div class="input-group input-group-outline my-3"> -->
                                    <!-- <label class="form-label"> C /?ourse Name</label> -->
                                    <!-- <label class="form-label"> Course Name</label> -->
                                    <!-- <select class="form-control" formControlName="course_id">
                                        <option value="">select sub course</option>
                                        <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">
                                            {{
                                            subCourse.subcourses_name }}</option>
                                    </select>
                                    <div class="invalid-feedback">Course is required</div>
                                </div> -->
                                <div class="input-group input-group-outline my-3">

                                    <input type="file" class="form-control" (change)="onFileSelected($event)">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Title</label> -->
                                    <input type="text" class="form-control" formControlName="title" placeholder="Title">
                                </div>
                                <div class="input-group input-group-outline my-3 full">
                                    <!-- <input type="text" class="form-control" formControlName="description" placeholder="description"> -->
                                    <ckeditor name="ckeditor_text" formControlName="description"
                                        editorUrl="https://cdn.ckeditor.com/4.14.0/full/ckeditor.js"
                                        [config]="ckEditorConfig"></ckeditor>



                                </div>



                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save
                                changes</button>
                        </div>
                   
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-counselling',
  templateUrl: './counselling.component.html',
  styleUrls: ['./counselling.component.css']
})

export class CounsellingComponent implements OnInit {
  bannerForm: any;
  base64Image: string;
  ban: any;
  bannerlist: any;
  editForm: any;
  currentPage = 1;
  itemsPerPage = 7; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];
  data: unknown[][];

  

  constructor(private banner:CounterService,private formBuilder:FormBuilder ){}

  ngOnInit(){
    this.addOffice();
    this.getoffice();
    this.createEditForm();
    this.bannerlist.sort((a, b) => b.id - a.id);
    // this.setPage(3);
  }
  
//   setPage(page: number) {
//     this.currentPage = page;
//     this.pagedItems = this.bannerlist.slice((page - 1) * this.itemsPerPage, page * this.itemsPerPage);
//     this.totalPages = Math.ceil(this.bannerlist.length / this.itemsPerPage);
//     this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
// }

setPage(page: number) {
  this.currentPage = page;

  const startIndex = (page - 1) * this.itemsPerPage;
  const endIndex = Math.min(startIndex + this.itemsPerPage, this.bannerlist.length);
  
  this.pagedItems = this.bannerlist.slice(startIndex, endIndex);
  this.totalPages = Math.ceil(this.bannerlist.length / this.itemsPerPage);
  
  // Calculate the index to continue numbering across pages
  const indexOffset = (page - 1) * this.itemsPerPage;
  this.pagedItems = this.pagedItems.map((item, index) => {
      return {
          ...item,
          index: index + 1 + indexOffset
      };
  });

  this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
}

prevPage() {
    if (this.currentPage > 1) {
        this.setPage(this.currentPage - 1);
    }
}

nextPage() {
    if (this.currentPage < this.totalPages) {
        this.setPage(this.currentPage + 1);
    }
}
  addOffice(): void {
    this.bannerForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      email: ['', Validators.required],
      contact: ['', Validators.required],
      course:['', Validators.required],
      duration:['', Validators.required],
      interest:['', Validators.required],
     
    });
  }

 

  onSubmit(): void {
    const formData = new FormData();
    formData.append('fullname', this.bannerForm.value.fullname);
    formData.append('email', this.bannerForm.value.email);
    formData.append('contact', this.bannerForm.value.contact);
    formData.append('course', this.bannerForm.value.course);
    formData.append('duration', this.bannerForm.value.duration);
    formData.append('interest', this.bannerForm.value.interest);
    
    

    this.banner.addcouns(formData).subscribe(
      (response: any) => {
        if(response.statusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }

  getoffice(){
    this.banner.getcouns().subscribe((res:any)=>{
      console.log(res);
      this.bannerlist=res.data;
      
      this.bannerlist.sort((a, b) => b.id - a.id);
      this.setPage(this.currentPage);
      
    })
  }
  
  // exportToExcel(): void {
  //   this.banner.getcouns().subscribe((allData: any[]) => {
  //     const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(allData);

  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'All Data');

  //     XLSX.writeFile(wb, 'allData.xlsx');
  //   });
  // }

  
//   exportToExcel(): void {
    
//     const allData =  this.getoffice();
   
//     const element = document.getElementById('allData'); // Assuming table's ID is 'bannerlist'
//     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.bannerlist);
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     XLSX.writeFile(wb, 'teacherData.xlsx');
// }
exportToExcel(): void {
  const date = new Date();
  const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}_${date
    .getHours()
    .toString()
    .padStart(2, '0')}-${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}-${date
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.bannerlist);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  // saveAs(data, 'contacts.xlsx');
  
  saveAs(data, `contacts_${dateString}.xlsx`);
}
  deleteoffice(id: number) {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.banner.deletecouns(id).subscribe(
        (response) => {
          console.log('Counselling deleted:');
          alert(`Counselling Deleted`)
          // You might want to refresh the categories list after deletion
          this.getoffice();
        },
        (error) => {
          console.error('Error deleting Project:', error);
        }
      );
    }
  }
  createEditForm() {
    this.editForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      email: ['', Validators.required],
      contact: ['', Validators.required],
      course:['', Validators.required],
      duration:['', Validators.required],
      interest:['', Validators.required],
    });
  }
  
  // Function to open the edit modal and populate form fields with the selected counter data
  openEditModal(counter: any) {
    this.editForm.setValue({
      fullname: counter.fullname,
      email: counter.email,
      contact:counter.contact,
      course:counter.course,
      duration:counter.duration,
      interest:counter.interest,
    });
  }

  // Function to handle the update operation in the edit modal
  updateoffice(archive: any): void {
    const updatedData = this.editForm.value;
    this.banner.updatecouns(archive.id, updatedData).subscribe(
      (res: any) => {
        console.log('Data updated successfully:', res);
        alert('Data updated')
        // Optionally, update the local list with the updated counter or fetch the updated list again
        this.getoffice();
      },
      (error) => {
        console.error('Failed to update archivement data:', error);
      }
    );
  }

}

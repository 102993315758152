import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-module',
  templateUrl: './update-module.component.html',
  styleUrls: ['./update-module.component.css']
})
export class UpdateModuleComponent implements OnInit {
  eventId: any;
  counterForm: any;

  constructor(private route: ActivatedRoute, private service: CounterService,private fb:FormBuilder) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });

  this.counterForm = this.fb.group({
    title: ['', Validators.required],
    
  });

  }
onSubmit() {
  const formData = new FormData();
  formData.append('title', this.counterForm.value.title);
 ;
 
  // Call your CourseService method to upload the course with the formData
  this.service.updatemodule(this.eventId,formData).subscribe(
    (response: any) => {
      if(response.statusCode == '200') {
        // this.router.navigate(['/main/banner'])
        alert("Data added successfully");
        location.reload();

      } else {
        alert("Something went wrong");
      }
    },
  );
}
}

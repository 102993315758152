import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';


@Component({
  selector: 'app-event-data',
  templateUrl: './event-data.component.html',
  styleUrls: ['./event-data.component.css']
})
export class EventDataComponent  implements OnInit{
  name:any
  eventlist: any;
  base64Images: string[] = [];
  evnetform: any;
  eventdata: any;
  details: any;
  base64Image: string;
  subcourses: any;
  title: string;
  list: any;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];


  constructor(private event:CounterService,private formBuilder:FormBuilder,private router:Router){
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }



  config: ToggleButtonConfig;
  status: any;

  
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.event.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }
ngOnInit(): void {
  this.eventdetail();
  this.geteventlist();
  this.geteventdetail();
  this.getsubcoure();
  
}
eventdetail(): void {
  this.evnetform = this.formBuilder.group({
    title:['',Validators.required],
    description: ['', Validators.required],
    event_id:['',Validators.required],
    subcourse_id:['',Validators.required],
    selectedFile: [null, Validators.required]
  });
}
geteventlist(){
  this.event.geteventlist().subscribe((res:any)=>{
    this.list =res.data;
  })
}
getsubcoure() {
  this.event.getsubcourse().subscribe((res) => {
    this.subcourses = res['data'];
  });
}



onFileSelected(event: any): void {
  const file = event.target.files[0];
  this.convertToBase64(file);
}

convertToBase64(file: File): void {
  const reader = new FileReader();
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
  reader.readAsDataURL(file);
}

onSubmit(): void {
  const formData = new FormData();
  formData.append('event_id', this.evnetform.value.event_id);
  formData.append('title', this.evnetform.value.title);
  formData.append('description', this.evnetform.value.description);
  formData.append('subcourse_id', this.evnetform.value.subcourse_id);
  formData.append('image',this.base64Image)
  

  this.event.addeventdetail(formData).subscribe(
    (response: any) => {
        if (response.StatusCode == '200') {
          alert("Data added successfully");
          location.reload();
        } else {
          alert("Something went wrong");
        }
      },
      (error) => {
        console.error('Failed to add course:', error);
      }
    );
  }

geteventdetail(){
  this.event.geteventdetail().subscribe((res:any)=>{
    console.log(res);
    
    this.details=res;
    this.details.sort((a, b) => b.id - a.id);//////Desc order
    this.setPage(this.currentPage);

  })
}
setPage(page: number) {
  this.currentPage = page;

  const startIndex = (page - 1) * this.itemsPerPage;
  const endIndex = Math.min(startIndex + this.itemsPerPage, this.details.length);
  
  this.pagedItems = this.details.slice(startIndex, endIndex);
  this.totalPages = Math.ceil(this.details.length / this.itemsPerPage);
  
  // Calculate the index to continue numbering across pages
  const indexOffset = (page - 1) * this.itemsPerPage;
  this.pagedItems = this.pagedItems.map((item, index) => {
      return {
          ...item,
          index: index + 1 + indexOffset
      };
  });

  this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
}
prevPage() {
    if (this.currentPage > 1) {
        this.setPage(this.currentPage - 1);
    }
}

nextPage() {
    if (this.currentPage < this.totalPages) {
        this.setPage(this.currentPage + 1);
    }
}




updateeventdata(id: number) {
  this.router.navigate(['/main/update-eventdata', id]);
}



 
  deleteeventdetail1(id: number) {
    this.event.deleteeventdetail(id).subscribe(
      () => {
        console.log('Event  deleted successfully');
        // Optionally, update the local list by removing the deleted expert review or fetch the updated list again
        this.geteventdetail();
      },
      (error) => {
        console.error('Failed to delete event details:', error);
      }
    );
    
  }

}

<div class="container main-part">
    <div class="row">
        <div class="div">
            <!-- <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#addbroucher">
           Add Broucher
        </a> -->
         <!-- Modal -->
         <div class="modal fade" id="addbroucher" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                     <form [formGroup]="broucherform">
                       

                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">name</label> -->
                                <input type="text"  class="form-control" formControlName="name" placeholder="Name">
                        </div>
                       
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Email</label> -->
                                <input type="text"  class="form-control" formControlName="email" placeholder="Email">
                        </div>

                        <div class="input-group input-group-outline my-3">
                            <!-- <lab class="form-label">Contact</lab el> -->
                                <input type="text"  class="form-control" formControlName="contact" placeholder="Contact">
                        </div>

                        
     
                         <button type="button" class="btn bg-gradient-secondary"
                         data-bs-dismiss="modal">Close</button>
                     <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                     </form>
                 </div>
                 <div class="modal-footer">
                    
                 </div>
             </div>
         </div>
     </div>
     <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table class="table align-items-center mb-0">
                <thead>
                    
                    <tr>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Sr.no</th>
                      
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                             Name</th>
                       
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
                       
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Contact</th>
                      
                            <th class="d-flex justify-content-center text-secondary text-xxs font-weight-bolder opacity-7">
                                Action</th>
                          

                        
                       
                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems ">
                        <td>{{item.index}}</td>
                        
                            
                            <td>
                                <p class="text-xs font-weight-bold mb-0 d-flex justify-content-start">{{item.name}}</p>
                                <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                            </td>
                           
                     
                       
                        <td>
                            <p class="text-xs font-weight-bold mb-0  d-flex justify-content-start">{{item.email}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0  d-flex justify-content-start">{{item.contact}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <!-- <td class="align-middle">
                           
                           <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editbroucher" (click)="openEditModal(item)">
                            Edit
                            </a> -->

                          <!-- Modal -->
                          <!-- <div class="modal fade" id="editbroucher" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form [formGroup]="editForm">
                       

                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">name</label>
                                                <input type="text"  class="form-control" formControlName="name" placeholder="Name">
                                        </div>
                                       
                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">Email</label>
                                                <input type="text"  class="form-control" formControlName="email" placeholder="Email">
                                        </div>
                
                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">Contact</label>
                                                <input type="text"  class="form-control" formControlName="contact" placeholder="Contact">
                                        </div>
                
                                        
                                     </form>

                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="submit" (click)="updatebrocher(item)" class="btn bg-gradient-primary">Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div> -->

                      <!-- Modal End -->
                       <!-- </td> -->

                        <td class="d-flex justify-content-center">

                            <!-- <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editbroucher" (click)="openEditModal(item)">
                            Edit
                            </a> -->
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deletebrochuer(item.id)">
                                delete
                            </a>

                        </td>
                      
                        
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>
<br>
<br>



import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-reccat',
  templateUrl: './update-reccat.component.html',
  styleUrls: ['./update-reccat.component.css']
})
export class UpdateReccatComponent implements OnInit {
  eventId: any;
  funForm: any;
  base64Image: string | Blob;

  constructor(private route: ActivatedRoute, private formBuilder:FormBuilder,  private service: CounterService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.funForm = this.formBuilder.group({

      title: ['', Validators.required],
      selectedFile: [null, Validators.required]
     
    });
  } 
 


  onSubmit(): void {


    const formData = new FormData();

    formData.append('title', this.funForm.value.title);
    formData.append('image',this.base64Image)
   


    this.service.updatereccategory(this.eventId,formData).subscribe(
      (response: any) => {
        if(response.statusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }
  
  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }


}

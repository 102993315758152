import { Component } from '@angular/core';

@Component({
  selector: 'app-update-moudetail',
  templateUrl: './update-moudetail.component.html',
  styleUrls: ['./update-moudetail.component.css']
})
export class UpdateMoudetailComponent {

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-mentor',
  templateUrl: './update-mentor.component.html',
  styleUrls: ['./update-mentor.component.css']
})
export class UpdateMentorComponent implements OnInit {
  eventId: any;
  mentorform: any;
  base64Image: string;
  subcourses: any;

  constructor(private route: ActivatedRoute, private service: CounterService,private formBuilder:FormBuilder) {}

  ngOnInit(): void {

    this.getsubcoure();
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.mentorform = this.formBuilder.group({
      course_id: [[], Validators.required] ,
    //  course_id: [, ] ,
      name: ['', Validators.required],
      designation: ['', Validators.required],
      company:['',Validators.required],
      selectedFile: [null, Validators.required],
      skills:['',Validators.required],
      experience:['',Validators.required]
     
    });

}
getsubcoure(){
  this.service.getsubcourse().subscribe((res) => {
    this.subcourses = res['data'];
    // this.joinTables()
})
}
onFileSelected(event: any): void {
  const file = event.target.files[0];
  this.convertToBase64(file);
}

convertToBase64(file: File): void {
  const reader = new FileReader();
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
  reader.readAsDataURL(file);
}
onSubmit(): void {
  const formData = new FormData();
  let subCourseIds = this.mentorform.value.course_id;

  // Check if subCourseIds is an array; if not, convert it to an array
  if (!Array.isArray(subCourseIds)) {
    subCourseIds = [subCourseIds];
  }
  
  // Loop through the subCourseIds array and append each value to formData
  for (const subCourseId of subCourseIds) {
    formData.append('course_id[]', subCourseId);
  }
  // formData.append('course_id', this.mentorform.value.course_id); 
  formData.append('name', this.mentorform.value.name);
  formData.append('designation', this.mentorform.value.designation);
  formData.append('company', this.mentorform.value.company);
  formData.append('image', this.base64Image);
  formData.append('skills', this.mentorform.value.skills);
  formData.append('experience', this.mentorform.value.experience);



  this.service.updatementor(this.eventId,formData).subscribe(
    (response: any) => {
      if(response.StatusCode == '200') {
        // this.router.navigate(['/main/banner'])
        alert("Data added successfully");
        location.reload();

      } else {
        alert("Something went wrong");
      }
    },
  );
}
}
import { Component } from '@angular/core';

@Component({
  selector: 'app-update-eventdetail',
  templateUrl: './update-eventdetail.component.html',
  styleUrls: ['./update-eventdetail.component.css']
})
export class UpdateEventdetailComponent {

}

<div class="modal-body">
    <form [formGroup]="expertform">


        <div class="input-group input-group-outline my-3">
            <!-- <label class="form-label">Review</label> -->
            <input type="text" id="review" class="form-control" formControlName="review"
                placeholder="review">
        </div>

        <div class="input-group input-group-outline my-3">
            <!-- <label class="form-label">Name</label> -->
            <input type="text" id="name" class="form-control" formControlName="name"
                placeholder="name">
        </div>

        <div class="input-group input-group-outline my-3">
            <!-- <label class="form-label">Company_position</label> -->
            <input type="text" id="company_position" class="form-control"
                formControlName="company_position" placeholder="company_position">
        </div>



        <div class="input-group input-group-outline my-3">

            <input type="file" id="selectedFile" class="form-control"
                (change)="onFileSelected($event)">
        </div>
        <div align="right">
            <button type="button" class="btn bg-gradient-secondary"
                data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
            <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save
                changes</button>
        </div>
    </form>
</div>
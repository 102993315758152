<!-- our achievements -->

<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#achievement">
                Add Counter
            </a>
            <!-- Modal -->
            <div class="modal fade" id="achievement" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="counterForm">
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Name</label> -->
                                    <input type="text" id="name" class="form-control" formControlName="name" placeholder="name">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Count</label> -->
                                    <input type="text" id="count" class="form-control" formControlName="count" placeholder="count">
                                </div>
                                

                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead >

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                        <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Function</th> -->
                        <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Counter</th> -->
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Counter</th>
                            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Action</th>

                    </tr>
                </thead>
                <tbody >

                    <tr *ngFor="let counter of counterlist">
                        <td>
                            <div class="d-flex px-2 py-1">
                                
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs"> {{counter.name}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{counter.count}}</p>
                           
                        </td>
                        <td class="align-middle">
                           
                           

                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editabout" (click)="openEditModal(counter)">
                            Edit
                        </a>

                          <!-- Modal -->
                          <div class="modal fade" id="editabout" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form [formGroup]="editForm">
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Name</label> -->
                                            <input type="text" id="name" class="form-control" formControlName="name" placeholder="name">
                                        </div>
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Count</label> -->
                                            <input type="text" id="count" class="form-control" formControlName="count" placeholder="count">
                                        </div>
                                        
        
                                    </form>


                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="submit" class="btn bg-gradient-primary" (click)="updatecounter(counter)">Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- Modal End -->
                       

                        <td>


                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deletecounter(counter.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

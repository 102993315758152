import { Component } from '@angular/core';

@Component({
  selector: 'app-update-training-stud',
  templateUrl: './update-training-stud.component.html',
  styleUrls: ['./update-training-stud.component.css']
})
export class UpdateTrainingStudComponent {

}

<div class="container">
    <a class="btn btn-info text-white font-weight-normal text-xs"
  >
    Update Course</a>

    <form [formGroup]="bannerForm" >
                              
        <div class="input-group input-group-outline my-3 w-50">
            <!-- <label class="form-label">Price</label> -->
            <input type="text" class="form-control"  formControlName="name" placeholder="name">
        </div>
        <div class="input-group input-group-outline my-3 w-50">
            <!-- <label class="form-label">Price</label> -->
            <input type="file" class="form-control" (change)="onFileSelected($event)" placeholder="image" >
        </div>
        <div align="right">
            <button type="button" class="btn bg-gradient-secondary"
                data-bs-dismiss="modal">Close</button>
            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
      </div>
       
    </form>
 


    </div>

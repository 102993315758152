import { Component } from '@angular/core';

@Component({
  selector: 'app-update-coursehighlight',
  templateUrl: './update-coursehighlight.component.html',
  styleUrls: ['./update-coursehighlight.component.css']
})
export class UpdateCoursehighlightComponent {

}

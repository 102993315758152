<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#our_office">
                Add Our Office
            </a>
            <!-- Modal -->
            <div class="modal fade" id="our_office" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="bannerForm" >
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Name</label> -->
                                    <input type="text" class="form-control" id="title" formControlName="title" placeholder=" title">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Price</label> -->
                                    <input type="text" class="form-control" id="description" formControlName="description" placeholder="description">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="link" formControlName="link" placeholder="link">
                                </div>
                               

                                <div class="input-group input-group-outline my-3">

                                    <input type="file"  id="image"class="form-control" placeholder="image" (change)="onFileSelected($event)">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="email" formControlName="email" placeholder="email">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="mobile_no" formControlName="mobile_no" placeholder="mobile_no">
                                </div>
              

                            </form>


                        </div>
                        <div class="modal-footer">
                            <div align="right">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead >

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Description
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Link</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Email</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Mobile</th>
                            
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Status</th>

                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center"> Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <td>{{item.index}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.image" class="avatar avatar-sm me-3">
                                    <!-- <img src="https://demos.creative-tim.com/test/material-dashboard-pro/assets/img/team-4.jpg"
                                        class="avatar avatar-sm me-3"> -->
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.title}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.description |slice:0:50}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.link}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.email}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.mobile_no}}</span>
                        </td>
                        <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 

                        <!-- <td class="align-middle">
                           
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                             data-bs-target="#editoffice" (click)="openEditModal(item)">
                             Edit
                             </a> -->
 
                           <!-- Modal -->
                           <!-- <div class="modal fade" id="editoffice" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                           <div class="modal-dialog modal-dialog-centered" role="document">
                               <div class="modal-content">
                                   <div class="modal-header">
                                       <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                       <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                           aria-label="Close">
                                           <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div class="modal-body">
                                     <form [formGroup]="editForm">
                        
 
                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">Name</label>
                                            <input type="text" class="form-control" formControlName="title" placeholder="title">
                                        </div>
                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">Price</label>
                                            <input type="text" class="form-control"  formControlName="description" placeholder="description">
                                        </div>
                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">Enrolled_students</label>
                                            <input type="text" class="form-control"  formControlName="link" placeholder="link">
                                        </div>
                                       
        
                                        <div class="input-group input-group-outline my-3">
        
                                            <input type="file" class="form-control" placeholder="image" (change)="onFileSelected($event)">
                                        </div>
                      
                                         
                                      </form>
 
                                   </div>
                                   <div class="modal-footer">
                                    <div align="right">
                                       <button type="button" class="btn bg-gradient-secondary"
                                           data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                                       <button type="submit" (click)="updateoffice(item)" class="btn bg-gradient-primary">Save changes</button>
                                    </div>
                                    </div>
                               </div>
                           </div>
                       </div> -->
 
                       <!-- Modal End -->
                        <!-- </td> -->
                       

                        <td class="d-flex justify-content-center">
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                                (click)="openEditModal(item)" data-bs-target="#editexpert">
                                Edit
                            </a>

                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                                (click)="deleteoffice(item.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>

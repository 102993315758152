<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#addconsult">
           Add Consulting
        </a>
         <!-- Modal -->
         <div class="modal fade" id="addconsult" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                     <form [formGroup]="consultform">
                       

                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">First name</label> -->
                                <input type="text"  class="form-control" formControlName="fname" placeholder="First Name">
                        </div>
                       
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Last Name</label> -->
                                <input type="text" id="name" class="form-control" formControlName="lname" placeholder="Last Name">
                        </div>

                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Email</label> -->
                                <input type="text"  class="form-control" formControlName="email" placeholder="Email">
                        </div>
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Contact</label> -->
                                <input type="text"  class="form-control" formControlName="contact" placeholder="Contact">
                        </div>
                        <div class="input-group input-group-outline my-3">
                            <!-- <label class="form-label">Company_name</label> -->
                                <input type="text"  class="form-control" formControlName="company_name" placeholder="Company_name">
                        </div>

                        
     
                         <button type="button" class="btn bg-gradient-secondary"
                         data-bs-dismiss="modal">Close</button>
                     <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                     </form>
                 </div>
                 <div class="modal-footer">
                    
                 </div>
             </div>
         </div>
     </div>
     <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table class="table align-items-center mb-0">
                <thead>
                    
                    <tr>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            First Name</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Last Name</th>
                        
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
                       
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Contact</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Company_name</th>
                      
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 left" >Action</th>

                        
                       
                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of consultlist ">
                        <td>
                            
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.fname}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                           
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.lname}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.email}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.contact}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.company_name}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td class="align-middle">
                           
                           

                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editconsult"  (click)="openEditModal(item)">
                            Edit
                        </a>

                          <!-- Modal -->
                          <div class="modal fade" id="editconsult" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form [formGroup]="editForm">
                       

                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">First name</label> -->
                                                <input type="text"  class="form-control" formControlName="fname" placeholder="First Name">
                                        </div>
                                       
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Last Name</label> -->
                                                <input type="text" id="name" class="form-control" formControlName="lname" placeholder="Last Name">
                                        </div>
                
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Email</label> -->
                                                <input type="text"  class="form-control" formControlName="email" placeholder="Email">
                                        </div>
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Conatct</label> -->
                                                <input type="text"  class="form-control" formControlName="contact" placeholder="Contact">
                                        </div>
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Company_name</label> -->
                                                <input type="text"  class="form-control" formControlName="company_name" placeholder="Company_name">
                                        </div>
                
                                        
                                     </form>

                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="submit" (click)="updateconsult(item)" class="btn bg-gradient-primary">Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- Modal End -->
                       

                        <td>


                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deleteconsulting(item.id)">
                                delete
                            </a>

                        </td>
                      
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<br>
<br>



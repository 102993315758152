<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#adddetail">
                Subcourse Details</a>
            <!-- Modal -->
            <!-- Modal -->
            <div class="modal fade" id="adddetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="subCourseDetails">
                                <div class="input-group input-group-outline mb-3">
                                    <select id="course" formControlName="course_id" class="form-control" required
                                        (change)="getSubCourseFromCourse($event)">
                                        <option value="" disabled selected>select course</option>
                                        <option [value]="course.id" *ngFor="let course of courseDetails"
                                            style="color: black;">{{ course.name }}</option>
                                    </select>
                                </div>




                                <div class="input-group input-group-outline my-3">
                                    <select class="form-control" formControlName="sub_course_id">
                                        <option value="">Select sub course</option>
                                        <option *ngFor="let subCourse of subcourses" [value]="subCourse.id">
                                            {{ subCourse.name }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">Sub Course is required</div>
                                </div>


                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">duration</label> -->
                                    <input type="text" class="form-control" formControlName="title" placeholder="title">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">training period</label> -->
                                    <input type="text" class="form-control" formControlName="description"
                                        placeholder="description">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">batch</label> -->
                                    <input type="text" class="form-control" formControlName="custome_text"
                                        placeholder="custome text">
                                </div>
                                <div class="input-group input-group-outline my-3">

                                    <input type="file" class="form-control" formControlName="banner"
                                        (change)="onFileSelectedBanner($event)">
                                </div>
                                <div class="input-group input-group-outline my-3">

                                    <input type="file" class="form-control" formControlName="back_image"
                                        (change)="onFileSelectedBackImage($event)">
                                </div>

                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save
                                changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Course name</th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Subcourse name
                        </th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>

                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Description</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Custome text
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Status
                        </th>


                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center"> Action
                        </th>


                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <td>{{item.index}}</td>
                        <td>
                            <div class="d-flex flex-row mb-0justify-content-center">
                                <div class="p-2"> <img [src]="item.banner" class="avatar avatar-sm me-3"></div>
                                <div class="p-2">
                                    <p class="  mt-2 text-xs font-weight-bold mb-0">{{item.courses_name}}</p>
                                </div>

                            </div>

                        </td>

                        <td>
                            <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs font-weight-bold mb-0">{{item.subcourses_name}}</p>
                                <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                            </div>
                        </td>

                        <td>
                            <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs font-weight-bold mb-0">{{item.title}}</p>
                                <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                            </div>
                        </td>
                        <td class="col-3">
                            <div class="d-flex flex-column justify-content-center" >
                                <p class="text-xs font-weight-bold mb-0">{{item.description |slice:0:50}}</p>
                                <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                            </div>
                        </td>
                        
                        <td>
                            <p class="text-xs font-weight-bold mb-0 ">{{item.custome_text}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>

                        <td><toggle-button [config]="config" (click)="changeStatus(item)">
                            </toggle-button></td>
                        <!-- <td class="align-middle"> -->



                            <!-- <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                                data-bs-target="#edithire" (click)="openEditModal(item)">
                                Edit
                            </a> -->
                            <!-- Modal -->
                            <!-- <div class="modal fade" id="edithire" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit
                                                Subcourse</h5>
                                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form [formGroup]="editForm">
                                                <div class="input-group input-group-outline mb-3">
                                                    <select id="course" formControlName="course_id" class="form-control"
                                                        required (change)="getSubCourseFromCourse($event)">
                                                        <option value="" disabled selected>select course</option>
                                                        <option [value]="course.id" *ngFor="let course of courseDetails"
                                                            style="color: black;">{{ course.name }}</option>
                                                    </select>
                                                </div>


                                                <div class="input-group input-group-outline mb-3">

                                                    <select class="form-control" formControlName="sub_course_id">
                                                        <option value="">select sub course</option>
                                                        <option *ngFor="let subCourse of subcourses"
                                                            [value]="subCourse.id">
                                                            {{subCourse.nmae }}</option>
                                                    </select>
                                                </div>

                                                <div class="input-group input-group-outline my-3">
                                                    <label class="form-label">duration</label>
                                                    <input type="text" class="form-control" formControlName="title"
                                                        placeholder="title">
                                                </div>
                                                <div class="input-group input-group-outline my-3">
                                                    <label class="form-label">training period</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="description" placeholder="description">
                                                </div>
                                                <div class="input-group input-group-outline my-3">
                                                    <label class="form-label">batch</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="custome_text" placeholder="custome text">
                                                </div>
                                                <div class="input-group input-group-outline my-3">

                                                    <input type="file" class="form-control" formControlName="banner"
                                                        (change)="onFileSelectedBanner($event)">
                                                </div>


                                            </form>


                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn bg-gradient-secondary"
                                                data-bs-dismiss="modal">Close</button>
                                            <button type="submit" class="btn bg-gradient-primary"
                                                (click)="updateSubcourseDetail(item)">Save
                                                changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- Modal End -->


                        <td>

                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" (click)="editsyllabus(item.id)">
                                Edit
                            </a>
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                                (click)="deletehired(item.id)">
                                delete
                            </a>

                        </td>


                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>
<br>
<br>
<div class="container">
    <div class="modal-header">
        <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
        <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="subCourseDetails">
            <div class="input-group input-group-outline mb-3 w-50">
                <select id="course" formControlName="course_id" class="form-control" required
                   >
                    <option value="" disabled selected>select course</option>
                    <option [value]="course.id" *ngFor="let course of courseDetails"
                        style="color: black;">{{ course.name }}</option>
                </select>
            </div>




            <div class="input-group input-group-outline my-3 w-50">
                <select class="form-control" formControlName="sub_course_id">
                    <option value="">Select sub course</option>
                    <option *ngFor="let subCourse of subcourses" [value]="subCourse.id">
                        {{ subCourse.name }}
                    </option>
                </select>
                <div class="invalid-feedback">Sub Course is required</div>
            </div>


            <div class="input-group input-group-outline my-3 w-50">
                <!-- <label class="form-label">duration</label> -->
                <input type="text" class="form-control" formControlName="title" placeholder="title">
            </div> 
            <div class="input-group input-group-outline my-3 w-50">
                <!-- <label class="form-label">training period</label> -->
                <input type="text" class="form-control" formControlName="description"
                    placeholder="description">
            </div>
            <div class="input-group input-group-outline my-3 w-50">
                <!-- <label class="form-label">batch</label> -->
                <input type="text" class="form-control" formControlName="custome_text"
                    placeholder="custome text">
            </div> 
            <div class="input-group input-group-outline my-3 w-50">

                <input type="file" class="form-control" formControlName="banner"
                    (change)="onFileSelectedBanner($event)">
            </div>
            <div class="input-group input-group-outline my-3 w-50">

                <input type="file" class="form-control" formControlName="back_image"
                    (change)="onFileSelectedBackImage($event)">
            </div>

        </form>


    </div>
    <div class="modal-footer">
        <button type="button" class="btn bg-gradient-secondary"
            data-bs-dismiss="modal">Close</button>
        <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save
            changes</button>
    </div>
</div>
import { Component } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-program-fees-category',
  templateUrl: './program-fees-category.component.html',
  styleUrls: ['./program-fees-category.component.css']
})
export class ProgramFeesCategoryComponent {
  programform: FormGroup;
  programlist: any[];
  editForm: FormGroup;
  counter:any;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];
  config: ToggleButtonConfig;
  status: any;


  
  constructor(private company: CounterService, private formBuilder: FormBuilder,private router:Router) {
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.company.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }

  
  ngOnInit(): void {
    this.createForms();
    this.getCategories();
  }
  createForms(): void {
    this.programform = this.formBuilder.group({
      title: ['', Validators.required],
    });

    this.editForm = this.formBuilder.group({
      title: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.programform.valid) {
      const formData = new FormData();
      formData.append('title', this.programform.value.title);

      this.company.addprogramdetailcategory(formData).subscribe(
        (response: any) => {
          if (response.statusCode === '200') {
            alert('Data added successfully');
            this.getCategories();
            location.reload();
          } else {
            alert('Something went wrong');
          }
        },
      );
    }
  }
  editsyllabus(syllabusId: number) {
    this.router.navigate(['main/update_feecategory', syllabusId]);
  }
  getCategories(): void {
    this.company.getprogramdetailcategory().subscribe((res: any) => {
      console.log(res);
      this.programlist = res.data;
      this.programlist.sort((a, b) => b.id - a.id);//////Desc order
      this.setPage(this.currentPage);
    
    });
  }
  setPage(page: number) {
    this.currentPage = page;
  
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.programlist.length);
    
    this.pagedItems = this.programlist.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.programlist.length / this.itemsPerPage);
    
    // Calculate the index to continue numbering across pages
    const indexOffset = (page - 1) * this.itemsPerPage;
    this.pagedItems = this.pagedItems.map((item, index) => {
        return {
            ...item,
            index: index + 1 + indexOffset
        };
    });
  
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  prevPage() {
      if (this.currentPage > 1) {
          this.setPage(this.currentPage - 1);
      }
  }
  
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.setPage(this.currentPage + 1);
      }
  }
  
  

  deleteCategory(id: number): void {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.company.deletepgmdetailcategory(id).subscribe(
        () => {
          console.log('Category deleted:', id);
          alert('Category Deleted');
          this.getCategories();
        },
        (error) => {
          console.error('Error deleting category:', error);
        }
      );
    }
  }

  openEditModal(category: any): void {
    this.programform.setValue({
      title: category.title,
    });
  }

  updateCategory(id: number): void {
    if (this.programform.valid) {
      const updatedData = this.programform.value;

      const formData = new FormData();
      formData.append('title', updatedData.title);

      this.company.updateprogramdetailscategory(id, formData).subscribe(
        (res: any) => {
          console.log('Data updated successfully:', res);
          alert('Data Updated');
          this.getCategories();
        },
        (error) => {
          console.error('Failed to update category data:', error);
        }
      );
    }
  }
}
<div class=" container modal-body">
    <a class="btn btn-info text-white font-weight-normal text-xs">
     Update Hire
 </a>
    <form [formGroup]="hireform" (ngSubmit)="onSubmit()"  >
      
       <div class="input-group input-group-outline my-3">
           <!-- <label class="form-label">Title</label> -->
               <input type="text" id="title" class="form-control" formControlName="title" placeholder="title">
       </div>

        <div class="input-group input-group-outline my-3">
            <!-- <label class="form-label">description</label> -->
                <input type="text" id="designation" class="form-control" formControlName="description" placeholder="description">
        </div>
       
       <div class="input-group input-group-outline my-3">
           
               <input type="file" id="selectedFile" class="form-control" (change)="onFileSelected($event)" >
       </div>
       <div align="right">
        <button type="button" class="btn bg-gradient-secondary"
        data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
    <button type="submit"  class="btn bg-gradient-primary">Save changes</button>
   </div>
    </form>
</div>
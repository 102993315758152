<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" 
                >
                Enquiry Data
            </a>&nbsp;&nbsp;
            <button  class="btn btn-info text-white font-weight-normal text-xs" (click)="exportToExcel()">Export to Excel</button>
            <!-- Modal -->
            <div class="modal fade" id="counselling" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="bannerForm" >
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Name</label> -->
                                    <input type="text" class="form-control" id="fullname" formControlName="fullname" placeholder="fullname">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Price</label> -->
                                    <input type="text" class="form-control" id="email" formControlName="email" placeholder="email">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="contact" formControlName="contact" placeholder="contact">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="course" formControlName="course"placeholder="course">
                                </div>
                               
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="duration" formControlName="duration" placeholder="duration">
                                </div>
                               
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="interest" formControlName="interest" placeholder="interest">
                                </div>
                               
                               

              

                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0" id="allData">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Full name</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Email</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Contact</th>
                            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Course</th>
                            <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Duration</th>
                                <th class=" text-uppercase text-secondary text-xxs  opacity-7 d-flex justify-content-center">
                                        Interest</th>
                                      
                        
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <!-- <td>{{ndx+1}}</td> -->
                        <td>{{ item.index }}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                               
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.fullname}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.email}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.contact}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.course}}</span>
                        </td>

                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.duration}}</span>
                        </td>

                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.interest}}</span>
                        </td>
                         <td>


                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"(click)="deleteoffice(item.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination  pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

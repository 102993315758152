import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';

@Component({
  selector: 'app-update-certificate',
  templateUrl: './update-certificate.component.html',
  styleUrls: ['./update-certificate.component.css']
})
export class UpdateCertificateComponent implements OnInit{
  eventId: any;
  certificate: any;
  base64Image: string;

  stripTags(value: string): string {
    if (!value) return value;
    // Use a regular expression to remove HTML tags
    return value.replace(/<[^>]*>/g, '');
  }
  get ckeditorTextFormElement() { return this.certificate.get('description'); }
  ckEditorConfig: any = { toolbar: [

  [ 'NumberedList','Bold', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl' ],
 
	] };
  constructor(private newweb: CounterService, private formBuilder: FormBuilder,private router:ActivatedRoute) { 
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }
  config: ToggleButtonConfig;
  status: any;

 
  hireform: any;
  is_active:number;
 
  ngOnInit(): void {
    this.router.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.certificate = this.formBuilder.group({
      // course_id:['',Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      selectedFile: [null, Validators.required]
    });
  }
 

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onSubmit(): void {
   

    const formData = new FormData();
    // formData.append('course_id',this.certificate.value.course_id);
    formData.append('title', this.certificate.value.title);
    formData.append('description',this.certificate.value.description),
    formData.append('image', this.base64Image);

    this.newweb.updatecertificate(this.eventId,formData).subscribe(
      (response: any) => {
        if(response.statusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }
  }


<div class=" container modal-body">
    <a class="btn btn-info text-white font-weight-normal text-xs">
     Update Top Rank
  </a>
            <form [formGroup]="hireform"  >
              
             
               <div class="input-group input-group-outline my-3">
                   
                       <input type="file" id="selectedFile" class="form-control" (change)="onFileSelected($event)" >
               </div>
               <div align="right">
                <button type="button" class="btn bg-gradient-secondary"
                data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
            <button type="submit"  (click)="onSubmit()" class="btn bg-gradient-primary">Save changes</button>
           </div>
            </form>
        </div>
        <div class="modal-footer">
           
        </div>
   
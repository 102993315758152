import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';

// import { CKEditorModule } from 'ng2-ckeditor';
import { CerticateComponent } from './certicate/certicate.component';
// import { CKEditorModule } from 'ckeditor4-angular/ckeditor.module';
import { CKEditorModule } from 'ckeditor4-angular';

import { AboutComponent } from './about/about.component';
import { DiplomaProgramCategoryComponent } from './Our_Program/diploma-program-category/diploma-program-category.component';
import { ContactComponent } from './contact/contact.component';
import { AddPopularCourseComponent } from './add-popular-course/add-popular-course.component';

import { AboutCounterComponent } from './about-counter/about-counter.component';
import { ProgramsDetailsComponent } from './programs-details/programs-details.component';
import { ArchievementComponent } from './archievement/archievement.component';
import { ApplyNowComponent } from './apply-now/apply-now.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ExpertReviewComponent } from './expert-review/expert-review.component';
import { GoogleReviewComponent } from './google-review/google-review.component';
import { ConsultingComponent } from './consulting/consulting.component';
import { BrochuerComponent } from './brochuer/brochuer.component';
import { BannerComponent } from './banner/banner.component';
import { HireComponent } from './hire/hire.component';
import { MentorComponent } from './mentor/mentor.component';
import { FaqComponent } from './faq/faq.component';
import { AluminiComponent } from './alumini/alumini.component';
import { FormsModule } from '@angular/forms';
import { AddOurofficeComponent } from './add-ouroffice/add-ouroffice.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { AddLogoComponent } from './add-logo/add-logo.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AddSubcourseComponent } from './add-subcourse/add-subcourse.component';

import { CourseComponent } from './course/course.component';
import { SubcourseDetailsComponent } from './subcourse-details/subcourse-details.component';
import { CohortsComponent } from './cohorts/cohorts.component';
import { HandsonCategoryComponent } from './handson-category/handson-category.component';
import { HandsonProjectComponent } from './handson-project/handson-project.component';
import {TrainedStudentComponent} from './trained-student/trained-student.component';
import { ModuleComponent } from './module/module.component';
import { LearnerReviewComponent } from './learner-review/learner-review.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { CourseHighlightComponent } from './course-highlight/course-highlight.component';
import { CounsellingComponent } from './counselling/counselling.component';
import { SyllabusModuleComponent } from './syllabus-module/syllabus-module.component';
import { ProgramCityComponent } from './program-city/program-city.component';
import { ProgramFeesCategoryComponent } from './program-fees-category/program-fees-category.component';
import { SyllabusPdfComponent } from './syllabus-pdf/syllabus-pdf.component';
import { UpcomingComponent } from './upcoming/upcoming.component';
// import { EmptyGridComponent } from './empty-grid/empty-grid.component';
import { TopRankComponent } from './top-rank/top-rank.component';
import { UpdateAluminiComponent } from './update-alumini/update-alumini.component';
import { AddEventComponent } from './add-event/add-event.component';
import { EventDataComponent } from './event-data/event-data.component';
import { UpdateUpcomingComponent } from './update-upcoming/update-upcoming.component';
import { UpdateEventdataComponent } from './update-eventdata/update-eventdata.component';
import { EmptyGridComponent } from './empty-grid/empty-grid.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { FunatworkComponent } from './funatwork/funatwork.component';
import { FunatworkdetailComponent } from './funatworkdetail/funatworkdetail.component';
import { RecognitioncategoryComponent } from './recognitioncategory/recognitioncategory.component';
import { RecognitiondetailComponent } from './recognitiondetail/recognitiondetail.component';
import { NewsComponent } from './news/news.component';
import { MoucategoryComponent } from './moucategory/moucategory.component';
import { MoudetailComponent } from './moudetail/moudetail.component';
import { UpdateFunatworkComponent } from './update-funatwork/update-funatwork.component';
import { UpdateSyllabusComponent } from './update-syllabus/update-syllabus.component';
import { UpdateHandsonComponent } from './update-handson/update-handson.component';
import { UpdateHandsonDetailComponent } from './update-handson-detail/update-handson-detail.component';
import { UpdateCourseComponent } from './update-course/update-course.component';
import { UpdateSubcourseComponent } from './update-subcourse/update-subcourse.component';
import { UpdateSubcoursedetailsComponent } from './update-subcoursedetails/update-subcoursedetails.component';
import { UpdateEmptygridComponent } from './update-emptygrid/update-emptygrid.component';
import { UpdateFeecategoryComponent } from './update-feecategory/update-feecategory.component';
import { UpdateFeedetailsComponent } from './update-feedetails/update-feedetails.component';
import { UpdateBrochureComponent } from './update-brochure/update-brochure.component';
import { UpdateSyllabusPdfComponent } from './update-syllabus-pdf/update-syllabus-pdf.component';
import { UpdateModuleComponent } from './update-module/update-module.component';
import { UpdateCertificateComponent } from './update-certificate/update-certificate.component';
import { UpdateEventComponent } from './update-event/update-event.component';
import { UpdateEventdetailComponent } from './update-eventdetail/update-eventdetail.component';
import { UpdateFuncategoryComponent } from './update-funcategory/update-funcategory.component';
import { UpdateFundetailsComponent } from './update-fundetails/update-fundetails.component';
import { UpdateReccatComponent } from './update-reccat/update-reccat.component';
import { UpdateRecdetailComponent } from './update-recdetail/update-recdetail.component';
import { UpdateMoucatComponent } from './update-moucat/update-moucat.component';
import { UpdateMoudetailComponent } from './update-moudetail/update-moudetail.component';
import { UpdateNewsComponent } from './update-news/update-news.component';
import { UpdateMentorComponent } from './update-mentor/update-mentor.component';
import { UpdateFaqComponent } from './update-faq/update-faq.component';
import { UpdateTrainingStudComponent } from './update-training-stud/update-training-stud.component';
import { UpdateLearnerReviewComponent } from './update-learner-review/update-learner-review.component';
import { UpdateLeanerComponent } from './update-leaner/update-leaner.component';
import { UpdateProgramcityComponent } from './update-programcity/update-programcity.component';
import { UpdateHighlightComponent } from './update-highlight/update-highlight.component';
import { UpdateCoursehighlightComponent } from './update-coursehighlight/update-coursehighlight.component';
import { UpdateBannerComponent } from './update-banner/update-banner.component';
import { UpdateExpertComponent } from './update-expert/update-expert.component';
import { UpdateHireComponent } from './update-hire/update-hire.component';
import { UpdateToprankComponent } from './update-toprank/update-toprank.component';
import { UpdateGoogleReviewComponent } from './update-google-review/update-google-review.component';
import { NewsLetterComponent } from './news-letter/news-letter.component';
import { UpdateNewsLetterComponent } from './update-news-letter/update-news-letter.component';
// import { PracticeComponent } from './practice/practice.component';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [


    CerticateComponent,

    AboutComponent,
    DiplomaProgramCategoryComponent,
    ContactComponent,
    AddPopularCourseComponent,
  
    AboutCounterComponent,
    ProgramsDetailsComponent,
    ArchievementComponent,
    ApplyNowComponent,
    ExpertReviewComponent,
    GoogleReviewComponent,
    ConsultingComponent,
    BrochuerComponent,
    BannerComponent,
    HireComponent,
    MentorComponent,
    FaqComponent,
    AluminiComponent,
    AddOurofficeComponent,
    CompanyDetailsComponent,
    AddLogoComponent,
    AddProductComponent,
    AddSubcourseComponent,
    CohortsComponent,
    HandsonCategoryComponent,
    HandsonProjectComponent,
    CourseComponent,
    SubcourseDetailsComponent,
    TrainedStudentComponent,
    ModuleComponent,
    LearnerReviewComponent,
    HighlightsComponent,
    CourseHighlightComponent,
    CounsellingComponent,
    SyllabusModuleComponent,
    ProgramCityComponent,
    ProgramFeesCategoryComponent,
    SyllabusPdfComponent,
    UpcomingComponent,
   
    TopRankComponent,
    UpdateAluminiComponent,
    AddEventComponent,
    EventDataComponent,
    UpdateUpcomingComponent,
    UpdateEventdataComponent,
    EmptyGridComponent,
    ToggleButtonComponent,
    FunatworkComponent,
    FunatworkdetailComponent,
    RecognitioncategoryComponent,
    RecognitiondetailComponent,
    NewsComponent,
    MoucategoryComponent,
    MoudetailComponent,
    UpdateFunatworkComponent,
    UpdateSyllabusComponent,
    UpdateHandsonComponent,
    UpdateHandsonDetailComponent,
    UpdateCourseComponent,
    UpdateSubcourseComponent,
    UpdateSubcoursedetailsComponent,
    UpdateEmptygridComponent,
    UpdateFeecategoryComponent,
    UpdateFeedetailsComponent,
    UpdateBrochureComponent,
    UpdateSyllabusPdfComponent,
    UpdateModuleComponent,
    UpdateCertificateComponent,
    UpdateEventComponent,
    UpdateEventdetailComponent,
    UpdateFuncategoryComponent,
    UpdateFundetailsComponent,
    UpdateReccatComponent,
    UpdateRecdetailComponent,
    UpdateMoucatComponent,
    UpdateMoudetailComponent,
    UpdateNewsComponent,
    UpdateMentorComponent,
    UpdateFaqComponent,
    UpdateTrainingStudComponent,
    UpdateLearnerReviewComponent,
    UpdateLeanerComponent,
    UpdateProgramcityComponent,
    UpdateHighlightComponent,
    UpdateCoursehighlightComponent,
    UpdateBannerComponent,
    UpdateExpertComponent,
    UpdateHireComponent,
    UpdateToprankComponent,
    UpdateGoogleReviewComponent,
    NewsLetterComponent,
    UpdateNewsLetterComponent,
        
    
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
   
   
    

  ]
})
export class MainModule { }

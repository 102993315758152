import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{
  counter: any;


  constructor(private apiService:CounterService,private auth:AuthService){}
  ngOnInit(): void {
    this.getCounter();
    
  }
  getCounter(){
    this.apiService.getDashboardCounters().subscribe((res)=>{
      console.log(res)
    this.counter=res
    })
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-update-moucat',
  templateUrl: './update-moucat.component.html',
  styleUrls: ['./update-moucat.component.css']
})
export class UpdateMoucatComponent {

}

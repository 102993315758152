<div class="container main-part">

    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    
                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                        <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Email -->
                        <!-- </th> -->
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Email</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Contact</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Technology</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            CV</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Cover_letter</th>
                        <th class="t text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Duration</th>
                        <th class="text-secondary opacity-7"></th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of applynowlist" >
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img src="https://demos.creative-tim.com/test/material-dashboard-pro/assets/img/team-4.jpg"
                                        class="avatar avatar-sm me-3">
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.name}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>

                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.email}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.contact}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.technology}}</span>
                        </td>
                        <td class="align-middle text-center">
                            
                          <a [href]="item.cv.download_url" class="text-secondary text-xs font-weight-normal">Download</a>  
                        </td>
                        <td class="align-middle text-center">
                            <a [href]="item.cover_letter.download_url" class="text-secondary text-xs font-weight-normal">Download</a>  
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.duration}}</span>
                        </td>

                        <td>


                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deleteapplynow(item.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<br>

<!-- add popular Course -->

<br>
<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#add_product">
                Add Product
            </a>
            <!-- Modal -->
            <div class="modal fade" id="add_product" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="bannerForm" >
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Name</label> -->
                                    <input type="text" class="form-control" id="title" formControlName="title" placeholder=" Title">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Price</label> -->
                                    <input type="text" class="form-control" id="link" formControlName="link" placeholder="Link">
                                </div>
                               
                               

              
              

                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Link
                        </th>
                       
                            
                        
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of bannerlist">
                        <td>
                            <div class="d-flex px-2 py-1">
                               
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.title}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.link}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                      

                        <td class="align-middle">
                           
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                             data-bs-target="#edit_product" (click)="openEditModal(item)">
                             Edit
                             </a>
 
                           <!-- Modal -->
                           <div class="modal fade" id="edit_product" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                           <div class="modal-dialog modal-dialog-centered" role="document">
                               <div class="modal-content">
                                   <div class="modal-header">
                                       <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                       <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                           aria-label="Close">
                                           <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div class="modal-body">
                                     <form [formGroup]="editForm">
                        
 
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Name</label> -->
                                            <input type="text" class="form-control" formControlName="title" placeholder="Title">
                                        </div>
                                        <div class="input-group input-group-outline my-3">
                                            <!-- <label class="form-label">Price</label> -->
                                            <input type="text" class="form-control"  formControlName="link" placeholder="link">
                                        </div>
                                      
        
                   
                      
                                         
                                      </form>
 
                                   </div>
                                   <div class="modal-footer">
                                       <button type="button" class="btn bg-gradient-secondary"
                                           data-bs-dismiss="modal">Close</button>
                                       <button type="submit" (click)="updateProduct(item)" class="btn bg-gradient-primary">Save changes</button>
                                   </div>
                               </div>
                           </div>
                       </div>
 
                       <!-- Modal End -->
                        </td>
                       

                        <td>


                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"(click)="deleteProduct(item.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<div class=" container modal-body">
    <a class="btn btn-info text-white font-weight-normal text-xs">
     Update Alumini
 </a>
        <!-- <div class="container modal-body"> -->
            <form [formGroup]="aluminiform" >

                <div class="input-group input-group-outline my-3">
                    <!-- <label class="form-label"> Course Name</label> -->
                    <!-- <select  class="form-control" formControlName="sub_course_id" >
                        <option value="">select sub course</option>

                        <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">{{
                            subCourse.subcourses_name }}</option>
                            
                    </select> -->

                    <select class="form-control" formControlName="sub_course_id" multiple>
                        <option value="">Select sub course</option>
                        <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.subcourses_id">
                            {{ subCourse.subcourses_name }}
                        </option>
                    </select>
                   
                      <!-- <label class="form-label select-label">Example label</label> -->
                    <div class="invalid-feedback">Course is required</div>
                </div>
                <div class="input-group input-group-outline my-3">
                    <!-- <label class="form-label">Name</label> -->
                    <input type="text" id="name" class="form-control" formControlName="name"
                        placeholder="name">
                </div>

                <div class="input-group input-group-outline my-3">
                    <!-- <label class="form-label">designation</label> -->
                    <input type="text" id="designation" class="form-control"
                        formControlName="designation" placeholder="designation">
                </div>
                <div class="input-group input-group-outline my-3">
                    <!-- <label class="form-label">Company</label> -->
                    <input type="text" id="designation" class="form-control" formControlName="company"
                        placeholder="company">
                </div>

                <div align="right">
                    <div class="input-group input-group-outline my-3">

                        <input type="file" id="selectedFile" class="form-control"
                            (change)="onFileSelected($event)">
                    </div>
                    <div class="input-group input-group-outline my-3">

                        <input type="file" id="company_logo" class="form-control"
                            (change)="onFileSelectedlogo($event)">
                    </div>
                    
                    <button type="button" class="btn bg-gradient-secondary"
                        data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                    <button type="submit" (click)="onSubmit()"   class="btn bg-gradient-primary">Save
                        changes</button>
                </div>
            </form>
        </div>
        

    
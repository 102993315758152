<!-- our achievements -->

<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#addfaq">
                Add Faq
            </a>
            <!-- Modal -->
            <div class="modal fade" id="addfaq" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="FaqForm">

                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">select sub course</label> -->
                                    <select  class="form-control" formControlName="course_id">
                                        <option value="">select sub course</option>
                                        <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">{{
                                            subCourse.subcourses_name }}</option>
                                    </select>
                                    
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Question</label> -->
                                    <input type="text" id="question" class="form-control" formControlName="question"
                                        placeholder="question">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Answer</label> -->
                                    <input type="text" id="answer" class="form-control" formControlName="answer"
                                        placeholder="answer">
                                </div>


                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save
                                changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Question</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Course
                        </th>
                        <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Counter</th> -->
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Answer</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Action</th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let counter of faqlist">
                        <td>
                            <div class="d-flex px-2 py-1">

                                <div class="d-flex flex-column justify-content-center">
                                    <p class="text-xs font-weight-bold mb-0">{{counter.question}}</p>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <div class="d-flex flex-column justify-content-center">
                            <p class="text-xs font-weight-bold mb-0 mt-3">{{counter.course_id}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                        </div>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{counter.answer}}</p>

                        </td>
                        <td class="align-middle">



                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                                data-bs-target="#editfaq" (click)="openEditModal(counter)">
                                Edit
                            </a>

                            <!-- Modal -->
                            <div class="modal fade" id="editfaq" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title 
                                      font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form [formGroup]="editForm">
                                                <div class="input-group input-group-outline my-3">
                                                    <label class="form-label">select course</label>
                                                    <select  class="form-control" formControlName="course_id">
                                                        <option value="">select sub course</option>
                                                        <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">{{
                                                            subCourse.subcourses_name }}</option>
                                                    </select>
                                                    <div class="invalid-feedback">Course is required</div>
                                                </div>
                                                <div class="input-group input-group-outline my-3">
                                                    <!-- <label class="form-label">Question</label> -->
                                                    <input type="text" id="question" class="form-control"
                                                        formControlName="question" placeholder="question">
                                                </div>
                                                <div class="input-group input-group-outline my-3">
                                                    <!-- <label class="form-label">Answer</label> -->
                                                    <input type="text" id="answer" class="form-control"
                                                        formControlName="answer" placeholder="answer">
                                                </div>



                                            </form>


                                        </div>
                                        <div class="modal-footer">
                                            <div align="right">
                                                <button type="button" class="btn bg-gradient-secondary"
                                                    data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                                                <button type="submit" (click)="updatefaq(counter)"
                                                    class="btn bg-gradient-primary">Save changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal End -->


                        <td>


                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                                (click)="deletefaq(counter.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
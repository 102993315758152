import { Component } from '@angular/core';

@Component({
  selector: 'app-update-handson',
  templateUrl: './update-handson.component.html',
  styleUrls: ['./update-handson.component.css']
})
export class UpdateHandsonComponent {

}

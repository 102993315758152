import { Component } from '@angular/core';

@Component({
  selector: 'app-update-funcategory',
  templateUrl: './update-funcategory.component.html',
  styleUrls: ['./update-funcategory.component.css']
})
export class UpdateFuncategoryComponent {

}

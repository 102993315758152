import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-course',
  templateUrl: './update-course.component.html',
  styleUrls: ['./update-course.component.css']
})
export class UpdateCourseComponent  implements OnInit{
  syllabusId: any;
  bannerForm: any;
  base64Image: string;
  bannerlist: any;
  
  constructor(private service:CounterService,private formBuilder:FormBuilder,private router:ActivatedRoute){
    this.bannerForm = this.formBuilder.group({

      name: ['', Validators.required],
      image: [null, Validators.required]
    });
  }


  ngOnInit(): void {
    this.router.params.subscribe(params => {
      this. syllabusId= params['id'];
      console.log(this.syllabusId);
  
    
  });
  }
  
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
      console.log(this.base64Image);
    };
    reader.readAsDataURL(file);
  }
  onSubmit(): void {


    const formData = new FormData();

    formData.append('name', this.bannerForm.value.name);
    formData.append('image', this.base64Image);


    this.service.updatecourse(this.syllabusId,formData).subscribe(
      (response: any) => {
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }

  getcompany() {
    this.service.getcourse().subscribe((res: any) => {
      console.log(res);
      this.bannerlist = res.data;
    })
  }
 

}

<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#add_subcor">
                Add SubCourse
            </a>
            <!-- Modal -->
            <div class="modal fade" id="add_subcor" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="bannerForm">
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label for="course">Course</label> -->
                        <select id="course" formControlName="course_id" class="form-control" required>
                          <option value="" disabled selected>select course</option>
                          <option [value]="course.id" *ngFor="let course of courseDetails" style="color: black;">{{ course.name }}</option>
                        </select>
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Price</label> -->
                                    <input type="text" class="form-control" id="name" formControlName="name" placeholder="name">
                                </div>
                                <div class="input-group input-group-outline my-3">

                                    <input type="file" id="selectedFile" class="form-control"
                                        (change)="onFileSelected($event)">
                                </div>
                               

              
              

                            </form>


                        </div>
                        <div align="right">
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Course name</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name
                        </th>
                       
                            
                        
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center"> Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <td>{{item.index}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.image"
                                        class="avatar avatar-sm me-3">
                                </div>
                               
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.name}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                                
                            </div>
                        </td>
                       <td>
                       
                        <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-xs">{{item.subcourses_name}}</h6>
                            <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                        </div>
                       </td>
                      
                       <!-- <td>
                        <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" (click)="editsyllabus(item.id)">
                            Edit
                        </a>
                       </td> -->
                        <!-- <td class="align-middle">
                           
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                             data-bs-target="#edit_product" (click)="openEditModal(item)">
                             Edit
                             </a> -->
 
                           <!-- Modal -->
                           <!-- <div class="modal fade" id="edit_product" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                           <div class="modal-dialog modal-dialog-centered" role="document">
                               <div class="modal-content">
                                   <div class="modal-header">
                                       <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                       <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                           aria-label="Close">
                                           <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div class="modal-body">
                                     <form [formGroup]="editForm">
                        
 
                                        <div class="input-group input-group-outline my-3"> -->
                                            <!-- <label class="form-label">Name</label> -->
                                            <!-- <input type="text" class="form-control" formControlName="title" placeholder="Title">
                                        </div>
                                        <div class="input-group input-group-outline my-3"> -->
                                            <!-- <label class="form-label">Price</label> -->
                                            <!-- <input type="text" class="form-control"  formControlName="link" placeholder="link">
                                        </div>
                                      
        
                   
                      
                                         
                                      </form>
 
                                   </div>
                                   <div class="modal-footer">
                                       <button type="button" class="btn bg-gradient-secondary"
                                           data-bs-dismiss="modal">Close</button>
                                       <button type="submit" (click)="updateProduct(item)" class="btn bg-gradient-primary">Save changes</button>
                                   </div>
                               </div>
                           </div>
                       </div>
                    </td> -->
                       <!-- Modal End -->
                       
                       

                        <td class="d-flex justify-content-center">
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" (click)="editsyllabus(item.id)">
                                Edit
                            </a>

                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"(click)="deleteSubCourse(item.subcourses_id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';

@Component({
  selector: 'app-handson-category',
  templateUrl: './handson-category.component.html',
  styleUrls: ['./handson-category.component.css']
})
export class HandsonCategoryComponent implements OnInit {

  handsonCategory: FormGroup;
  handsonCategoryDetails: any[]
  handsonCategoryUpdateForm: FormGroup; // Add this form for update
  bannerlist: any;
  handson: any;
  certificate: any;
  editForm: any;
  
 currentPage = 1;
 itemsPerPage = 5; // Number of items per page
 totalPages: number;
 pagedItems: any[];
 pages: number[];



  ngOnInit(): void {
    this.getCategories()
    this.addcompany();
    this.createEditForm()
  }
  constructor(private service: CounterService, private fb: FormBuilder) {
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };

  }
  config: ToggleButtonConfig;
  status: any;

 
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.service.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }





 
  addcompany(): void {
    this.certificate = this.fb.group({
     
      title: ['', Validators.required]
    
    });
  }


  onSubmit(): void {
   

    const formData = new FormData();
    // formData.append('course_id',this.certificate.value.course_id);
    formData.append('title', this.certificate.value.title);
  

    this.service.addHandosnCategory(formData).subscribe(
      (response: any) => {
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }
  // getcompany() {
  //   this.service.getHandsonCategory().subscribe((res: any) => {
  //     console.log(res);
  //     this.bannerlist = res.data;
  //   })
  // }
  getCategories() {
    this.service.getHandsonCategory().subscribe(
      (response: any) => {
        this.bannerlist = response.data;
        this.bannerlist.sort((a, b) => b.id - a.id);//////Desc order
        this.setPage(this.currentPage);
      
        console.log(this.bannerlist)
      },
      (error) => {
        console.error('Error getting categories:', error);
      }
    );
  }
  
setPage(page: number) {
  this.currentPage = page;

  const startIndex = (page - 1) * this.itemsPerPage;
  const endIndex = Math.min(startIndex + this.itemsPerPage, this.bannerlist.length);
  
  this.pagedItems = this.bannerlist.slice(startIndex, endIndex);
  this.totalPages = Math.ceil(this.bannerlist.length / this.itemsPerPage);
  
  // Calculate the index to continue numbering across pages
  const indexOffset = (page - 1) * this.itemsPerPage;
  this.pagedItems = this.pagedItems.map((item, index) => {
      return {
          ...item,
          index: index + 1 + indexOffset
      };
  });

  this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
}
prevPage() {
    if (this.currentPage > 1) {
        this.setPage(this.currentPage - 1);
    }
}

nextPage() {
    if (this.currentPage < this.totalPages) {
        this.setPage(this.currentPage + 1);
    }
}

  deleteCategory(id: number) {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.service.deleteHandsonCategory(id).subscribe(
        (response) => {
          console.log('Category deleted:', response);
          alert('Data Deleted')
          // You might want to refresh the categories list after deletion
          this.getCategories();
        },
        (error) => {
          console.error('Error deleting category:', error);
        }
      );
    }
  }
  createEditForm() {
    this.editForm = this.fb.group({
      title: ['', Validators.required],
      
    });
  }
  // Function to open the edit modal and populate form fields with the selected counter data
  openEditModal(about: any) {
    this.editForm.setValue({
      title: about.title,
    
     
    });
  }

  // Function to handle the update operation in the edit modal
  updatecounter(about: any): void {
    const updatedData = this.editForm.value;
    
      const formData = new FormData();
      formData.append('title', updatedData.title);
      this.service.updateHandsonCategory(about.id, updatedData).subscribe(
        (response) => {
          console.log('Category updated:', response);
          alert("Data Updated")
          this.getCategories(); // Refresh the category list after successful update
        },
        (error) => {
          console.error('Error updating category:', error);
        }
      );
    }
  }



import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';


@Component({
  selector: 'app-alumini',
  templateUrl: './alumini.component.html',
  styleUrls: ['./alumini.component.css']
})
export class AluminiComponent implements OnInit {
  aluminiform: any;
  base64Image: string;
  aluminies: any;
  aluminilist: any;
  courseDetails: any;
  editForm:any;
  joinedAlumni: any;
  subcourses: any;
  base64CompanyLogo: string;
  base64Imagelogo: string;
  
 currentPage = 1;
 itemsPerPage = 5; // Number of items per page
 totalPages: number;
 pagedItems: any[];
 pages: number[];


// sub_course_id: string|number;
let 
  constructor(private newweb: CounterService, private formBuilder: FormBuilder,private router:Router) {
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
   }

  config: ToggleButtonConfig;
  status: any;

 
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.newweb.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }



  ngOnInit(): void {
    this.addalumini();
    this.getalumini();
    // this.getCourse();
    this.getsubcoure();
    this.createEditForm()

  }
  getCourse() {
    this.newweb.getcourse().subscribe((res: any) => {
      this.courseDetails = res.data;
      console.log(this.courseDetails.name);
    });
  }

  getsubcoure() {
    this.newweb.getsubcourse().subscribe((res) => {
      this.subcourses = res['data'];
    });
  }

  getalumini() {
    this.newweb.getalumini().subscribe((res: any) => {
      console.log(res);
      this.aluminilist = res.data;
      this.aluminilist.sort((a, b) => b.id - a.id);//////Desc order
      this.setPage(this.currentPage);
    
    });
  }

  addalumini(): void {
    this.aluminiform = this.formBuilder.group({
      sub_course_id: [[], Validators.required],
      name: ['', Validators.required],
      designation: ['', Validators.required],
      company: ['', Validators.required],
      selectedFile: [null, Validators.required],
      company_logo:[null,Validators.required]
      
    });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  
  onFileSelectedlogo(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64logo(file);
  }

  

  convertToBase64logo(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Imagelogo = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  setPage(page: number) {
    this.currentPage = page;
  
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.aluminilist.length);
    
    this.pagedItems = this.aluminilist.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.aluminilist.length / this.itemsPerPage);
    
    // Calculate the index to continue numbering across pages
    const indexOffset = (page - 1) * this.itemsPerPage;
    this.pagedItems = this.pagedItems.map((item, index) => {
        return {
            ...item,
            index: index + 1 + indexOffset
        };
    });
  
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  prevPage() {
      if (this.currentPage > 1) {
          this.setPage(this.currentPage - 1);
      }
  }
  
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.setPage(this.currentPage + 1);
      }
  }
  

  onSubmit(): void {
    const formData = new FormData();
    let subCourseIds = this.aluminiform.value.sub_course_id;

// Check if subCourseIds is an array; if not, convert it to an array
if (!Array.isArray(subCourseIds)) {
  subCourseIds = [subCourseIds];
}

// Loop through the subCourseIds array and append each value to formData
for (const subCourseId of subCourseIds) {
  formData.append('sub_course_id[]', subCourseId);
}
    // formData.append('sub_course_id', this.aluminiform.value.sub_course_id);
    formData.append('name', this.aluminiform.value.name);
    formData.append('designation', this.aluminiform.value.designation);
    formData.append('company', this.aluminiform.value.company);
    formData.append('image', this.base64Image);
    formData.append('company_logo', this.base64Imagelogo);
   





    
    this.newweb.addalumini(formData).subscribe(
      (response: any) => {
        if (response.StatusCode == '200') {
          alert("Data added successfully");
          location.reload();
        } else {
          alert("Something went wrong");
        }
      },
      (error) => {
        console.error('Failed to add course:', error);
      }
    );
  }


  createEditForm() {
    this.editForm = this.formBuilder.group({
      sub_course_id: [[], Validators.required],
      name: ['', Validators.required],
      designation: ['', Validators.required],
      company: ['', Validators.required],
      selectedFile: [null, Validators.required],
      company_logo:[null,Validators.required]
    
    });
  }
  // Function to open the edit modal and populate form fields with the selected counter data
  openEditModal(consult: any) {
    this.editForm.setValue({
      sub_course_id:consult.sub_course_id,
      name: consult.name,
      designation: consult.designation,
      company:consult.company,
      selectedFile:null,
      company_logo:null
      
      
    });
  }
  updateAlumini(id:any,alumini:any): void {

    const updatedData = this.editForm.value;
    const formData = new FormData();
    let subCourseIds = this.aluminiform.value.sub_course_id;

    // Check if subCourseIds is an array; if not, convert it to an array
    if (!Array.isArray(subCourseIds)) {
      subCourseIds = [subCourseIds];
    }
    
    // Loop through the subCourseIds array and append each value to formData
    for (const subCourseId of subCourseIds) {
      formData.append('sub_course_id[]', subCourseId);
    }
    formData.append('name', this.editForm.value.name);
    formData.append('designation', this.editForm.value.designation);
    formData.append('company', this.editForm.value.company);
    formData.append('image', this.base64Image);
    formData.append('company_logo', this.base64Imagelogo);
    

    this.newweb.updateAlumni(alumini.id, updatedData).subscribe(
      (res: any) => {
        console.log('Data updated successfully:', res);
        alert("data updated")
        // Optionally, update the alumini in the local list or fetch the updated list again
        this.getalumini();
      },
      (error) => {
        console.error('Failed to update alumini data:', error);
      }
    );
  }
  updateAlumini1(id:number
    ){
    
    this.router.navigate(['/main', 'update_alumini', id]);
  }
  deletealuminies(id: number) {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.newweb.deletealumini(id).subscribe(
        (response) => {
          console.log('logo deleted:', response);
          // You might want to refresh the categories list after deletion
          this.getalumini();
        },
        (error) => {
          console.error('Error deleting Project:', error);
        }
      );
    }
  }

 
  }
 




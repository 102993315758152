<div class=" container modal-body">
    <a class="btn btn-info text-white font-weight-normal text-xs">
     Update New Letter
 </a>
 <form [formGroup]="evnetform" >

                    
    <div align="right">
        <div class="input-group input-group-outline my-3">
            <label for="file">Pdf:</label>
            <input style="margin-left: 10px;" type="file" id="selectedFile" class="form-control" formControlName="file"
                (change)="onFileSelected($event)" placeholder="Pdf">
        </div>
        <div class="input-group input-group-outline my-3">
            <label for="image">Image:</label>
            <input type="file" class="form-control" formControlName="image"
                (change)="onFileSelectedBanner($event)">
        </div>
        
        
        
        <button type="button" class="btn bg-gradient-secondary"
            data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
        <button type="submit" (click)="onSubmit()"   class="btn bg-gradient-primary">Save
            changes</button>
    </div>
</form>
</div>
<div class=" container modal-body">
    <a class="btn btn-info text-white font-weight-normal text-xs">
     Update Learner Review
 </a>
                            <form [formGroup]="bannerForm" >
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Name</label> -->
                                    <input type="text" class="form-control" id="title" formControlName="title" placeholder=" title">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Price</label> -->
                                    <input type="text" class="form-control" id="description" formControlName="description" placeholder="description">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Enrolled_students</label> -->
                                    <input type="text" class="form-control" id="link" formControlName="link" placeholder="link">
                                </div>
                               

                                <div class="input-group input-group-outline my-3">

                                    <input type="file"  id="image"class="form-control" placeholder="image" (change)="onFileSelected($event)">
                                </div>
              

                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                        </div>
                 
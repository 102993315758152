import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';

@Component({
  selector: 'app-moucategory',
  templateUrl: './moucategory.component.html',
  styleUrls: ['./moucategory.component.css']
})
export class MoucategoryComponent {
  funForm: any;
  funlist: any;
  editForm: any;
  base64Image: string;
  
 currentPage = 1;
 itemsPerPage = 5; // Number of items per page
 totalPages: number;
 pagedItems: any[];
 pages: number[];

  constructor(private company: CounterService, private formBuilder: FormBuilder) {let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    }; }
  config: ToggleButtonConfig;
  status: any;

  
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.company.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }

  ngOnInit(): void {
    this.addfun();
    this.getfun();
    this.createEditForm();


  }

  addfun(): void {
    this.funForm = this.formBuilder.group({

      title: ['', Validators.required],
      selectedFile: [null, Validators.required]
    
     
    });
  } 
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }
  
  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }


  onSubmit(): void {


    const formData = new FormData();

    formData.append('title', this.funForm.value.title);
    formData.append('image', this.base64Image);


    this.company.addmoucategory(formData).subscribe(
      (response: any) => {
        if(response.statusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }

  getfun() {
    this.company.getmoucategory().subscribe((res: any) => {
      console.log(res);
      this.funlist = res.data;
      this.funlist.sort((a, b) => b.id - a.id);//////Desc order
      this.setPage(this.currentPage);
    
    })
  }
  setPage(page: number) {
    this.currentPage = page;
  
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.funlist.length);
    
    this.pagedItems = this.funlist.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.funlist.length / this.itemsPerPage);
    
    // Calculate the index to continue numbering across pages
    const indexOffset = (page - 1) * this.itemsPerPage;
    this.pagedItems = this.pagedItems.map((item, index) => {
        return {
            ...item,
            index: index + 1 + indexOffset
        };
    });
  
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  prevPage() {
      if (this.currentPage > 1) {
          this.setPage(this.currentPage - 1);
      }
  }
  
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.setPage(this.currentPage + 1);
      }
  }
  deletefun(id: number) {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.company.deletemoucategory(id).subscribe(
        (response) => {
          console.log('category deleted:', response);
          alert(`category Deleted`)
          // You might want to refresh the categories list after deletion
          this.getfun();
        },
        (error) => {
          console.error('Error deleting Project:', error);
        }
      );
    }
  }
  createEditForm() {
    this.editForm = this.formBuilder.group({
      title: ['', Validators.required],
      
    });
  }
  // Function to open the edit modal and populate form fields with the selected counter data
  openEditModal(consult: any) {
    this.editForm.setValue({
      title: consult.title,
    
     
    });
  }

  // Function to handle the update operation in the edit modal
  updatecounter(about:number,id:any): void {
    const updatedData = this.editForm.value;
    
      const formData = new FormData();
      formData.append('title', updatedData.title);
  
    this.company.updatefunatwork(about, updatedData).subscribe(
      (res: any) => {
        console.log('Data updated successfully:', res);
        alert("Data Updated")
        // Optionally, update the local list with the updated counter or fetch the updated list again
        this.getfun();
      },
      (error) => {
        console.error('Failed to update archivement data:', error);
      }
    );
  }



}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';


@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {
  eventform: any;
  eventdata: any;
  eventlist: any;
  base64Images: any;
  editForm: any;
  courseDetails: any;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];


  constructor(private event:CounterService,private fb:FormBuilder){
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }
  config: ToggleButtonConfig;
  status: any;

 
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.event.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }

  ngOnInit(): void {
    this.CreateEvent();

    this.getevent();
    // this.getCourse();
  }

  // getCourse(){
  //   this.event.getcourse().subscribe((res: any) => {
  //     this.courseDetails = res.data; // Assign directly, assuming the data is an array
  //     console.log(this.courseDetails);
  //   });

  // }
  CreateEvent() {
    this.eventform = this.fb.group({
      title: ['', Validators.required],
      selectedFiles: [null, Validators.required]
     
    });

  }
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }
  
  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Images = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  onSubmit() {
    const formData = new FormData();
    formData.append('title', this.eventform.value.title);
    formData.append('image',this.base64Images)
   
   
    // Call your CourseService method to upload the course with the formData
    this.event.addeventlist(formData).subscribe(
    (response: any)=>{
      console.log('Created Successfully:', response);
            this.eventdata = response;    
            
          },
          (error)=>console.error('failed to add course')
        );
    }

    getevent(){

      this.event.geteventlist().subscribe((res:any)=>{
        this.eventlist=res.data;
        console.log(this.eventlist);
        this.eventlist.sort((a, b) => b.id - a.id);//////Desc order
        this.setPage(this.currentPage);
      
        
      })
    }
    setPage(page: number) {
      this.currentPage = page;
    
      const startIndex = (page - 1) * this.itemsPerPage;
      const endIndex = Math.min(startIndex + this.itemsPerPage, this.eventlist.length);
      
      this.pagedItems = this.eventlist.slice(startIndex, endIndex);
      this.totalPages = Math.ceil(this.eventlist.length / this.itemsPerPage);
      
      // Calculate the index to continue numbering across pages
      const indexOffset = (page - 1) * this.itemsPerPage;
      this.pagedItems = this.pagedItems.map((item, index) => {
          return {
              ...item,
              index: index + 1 + indexOffset
          };
      });
    
      this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }
    prevPage() {
        if (this.currentPage > 1) {
            this.setPage(this.currentPage - 1);
        }
    }
    
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.setPage(this.currentPage + 1);
        }
    }
    
    createEditForm() {
      this.editForm = this.fb.group({
        name: ['', Validators.required],
        selectedFiles: [null, Validators.required]
      });
    }
    // Function to open the edit modal and populate form fields with the selected counter data
    openEditModal(consult: any) {
      this.editForm.setValue({
        name: consult.name,
       selectedFiles:consult.base64Images
       
      });
    }
  
    // Function to handle the update operation in the edit modal
    updateevent(event: any): void {
      const updatedData = this.editForm.value;
      
      this.event.updateevent(event.id, updatedData).subscribe(
        (res: any) => {
          console.log('Data updated successfully:', res);
          // Optionally, update the local list with the updated counter or fetch the updated list again
          this.getevent();
        },
        (error) => {
          console.error('Failed to update archivement data:', error);
        }
      );
    }
    deleteevent(id: number) {
      this.event.deleteevent(id).subscribe(
        () => {
          console.log('Event deleted successfully');
          // Optionally, update the local list by removing the deleted expert review or fetch the updated list again
          this.getevent();
        },
        (error) => {
          console.error('Failed to delete Event:', error);
        }
      );
    }

  
}

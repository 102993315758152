import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-syllabus-pdf',
  templateUrl: './syllabus-pdf.component.html',
  styleUrls: ['./syllabus-pdf.component.css']
})
export class SyllabusPdfComponent implements OnInit {
  syllabuspdfs: FormGroup;
  courseDetails: any[] = [];
  alluploadeddata:any;
  subcourses: any[] = [];
  base64Data : string;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];


  constructor(private service: CounterService, private formBuilder: FormBuilder,private router:Router) {
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
   
  }
  config: ToggleButtonConfig;
  status: any;

 
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.service.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }

  ngOnInit(): void {
    this.getSubcoursesbyId()
    this.getsyllabuspdfs()
    this.addpdf();
  }
  addpdf(){
  this.syllabuspdfs = this.formBuilder.group({
    selectedFile:[null,Validators.required],
    subcourse_id: ['',Validators.required],
    // file:[]
  });
}
  

  // convertToBase64(file: File): void {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     this.file = reader.result as string;
  //   };
  //   reader.readAsDataURL(file);
  // }



//   onFileSelected(event: any): void {
//   const file = event.target.files[0];
//   const maxSizeInBytes = 50 * 1024 * 1024; 
//   const allowedFileType = 'application/pdf';

//   if (file.size <= maxSizeInBytes && file.type === allowedFileType) {
//     this.convertToBase64(file);
//   } else {
//     // Handle the case where the file is too large
//     alert('File is too large. Please select a less than 50 mb file.');
//     // You can also reset the file input to clear the selected file if needed
//     event.target.value = null;
//   }
// }
onFileSelected(event: any): void {
  const file = event.target.files[0];
  this.convertToBase64(file);
}
convertToBase64(file: File): void {
  const reader = new FileReader();
  reader.onload = () => {
    this.base64Data  = reader.result as string;
  };
  reader.readAsDataURL(file);
}


  getCourse() {
    this.service.getcourse().subscribe((res: any) => {
      this.courseDetails = res.data;
    });
  }
  getsyllabuspdfs() {
    this.service.getsyllabuspdf().subscribe((res: any) => {
      this.alluploadeddata = res.data;
      this.alluploadeddata.sort((a, b) => b.id - a.id);//////Desc order
      this.setPage(this.currentPage);
    });
  }
  setPage(page: number) {
    this.currentPage = page;
  
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.alluploadeddata.length);
    
    this.pagedItems = this.alluploadeddata.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.alluploadeddata.length / this.itemsPerPage);
    
    // Calculate the index to continue numbering across pages
    const indexOffset = (page - 1) * this.itemsPerPage;
    this.pagedItems = this.pagedItems.map((item, index) => {
        return {
            ...item,
            index: index + 1 + indexOffset
        };
    });
  
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  prevPage() {
      if (this.currentPage > 1) {
          this.setPage(this.currentPage - 1);
      }
  }
  
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.setPage(this.currentPage + 1);
      }
  }
  getSubcoursesbyId() {
    this.service.getSubcoursesdetail().subscribe((res: any) => {
      this.subcourses = res.data;
    });
  }

  onSubmit() {
    console.log("subcourse_id",this.syllabuspdfs.value.subcourse_id,this.base64Data )

    const formData = new FormData();
    formData.append('subcourse_id', this.syllabuspdfs.value.subcourse_id);
    formData.append('file', this.base64Data );
    this.service.addsyllabuspdf(formData).subscribe(
      (response: any) => {
        if (response.statusCode == '200') {
          alert("Data added successfully");
          console.log(response);
          
          location.reload();
        } else {
          alert("Something went wrong");
        }
      },
    );
  }
  editpdf(syllabusId: number) {
    
    this.router.navigate(['main/update-syllabus-pdf', syllabusId]);
  }
  getSubCourseFromCourse(event) {
    console.log(event);
    var obj = {
      course_id: event.target.value
    };

    this.service.getSubCourse(obj).subscribe(alldist => {
      this.subcourses = alldist['data'];
    });
  }
   
  deletepdf(id: number): void {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.service.deletebsyllabuspdf(id).subscribe(
        (response) => {
          console.log('Data deleted:', response);
          alert(`Data Deleted`);
          // this.getsyllabus();
          location.reload();
        },
        (error) => {
          console.error('Failed to delete data:', error);
        }
      );
    }
  }
}

<div class=" container modal-body">
  <a class="btn btn-info text-white font-weight-normal text-xs">
   Update Syllabus
</a>
           <form [formGroup]="ProgramFeesFormData">
            

             <div class="input-group input-group-outline my-3">
               <!-- <label class="form-label">select subcourse</label> -->
               <select class="form-control" formControlName="course_id">
                   <option value="">Select sub course</option>
                   <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">
                       {{ subCourse.subcourses_name }}
                   </option>
               </select>
               <div class="invalid-feedback">Course is required</div>
             </div>

             <div class="input-group input-group-outline my-3">
               <!-- <label for="course">Module</label> -->
               <select id="module" formControlName="module_id" class="form-control" required>
                 <option value="" disabled selected>select module</option>
                 <option [value]="sub.id" *ngFor="let sub of counterlist" style="color: black;">{{ sub.title }}</option>
               </select>
               
             </div>
             
         
             <div class="input-group input-group-outline my-3">
               
               <input type="text" class="form-control" formControlName="title" placeholder="title">
             </div>
         
             <div class="input-group input-group-outline my-3">
        
               <!-- <input type="text" class="form-control" formControlName="description" placeholder="description"> -->
               <!-- <ckeditor formControlName="description" [config]="ClassicEditor"></ckeditor> -->
               <ckeditor name="ckeditor_text" formControlName="description"
               editorUrl="https://cdn.ckeditor.com/4.14.0/full/ckeditor.js"
               [config]="ckEditorConfig"></ckeditor>

              
             </div>

              

         <div align="right">
           <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
           <button type="submit" class="btn bg-gradient-primary" (click)="onSubmit()">Save changes</button>
        
         </div>
             </form>
         </div>
       
<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal" data-bs-target="#course">
                Add Syllabus Pdf
            </a>
            <!-- Modal -->
            <div class="modal fade" id="course" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="syllabuspdfs">
                                <div class="input-group input-group-outline mb-3">
                                    <select class="form-control" formControlName="subcourse_id">
                                        <option value="">Select sub course</option>
                                        <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.sub_course_id">
                                            {{ subCourse.subcourses_name }}
                                        </option>
                                    </select>
                                    <!-- <select class="form-control" formControlName="sub_course_id"  class="form-control" >
                                        <option value="">select sub course</option>
                                        <option *ngFor="let subCourse of subcourses" [value]="subCourse.sub_course_id">
                                            {{ subCourse.title }}</option>
                                    </select> -->
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    
                                    <input type="file" id="selectedFile" class="form-control-file" (change)="onFileSelected($event)">
                                    <!-- <div *ngIf="syllabuspdfs.get('selectedFile').hasError('fileSizeExceeded')">
                                        File size exceeds the allowed limit (20 MB).
                                      </div> -->
                                </div>

                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn bg-gradient-primary" (click)="onSubmit()" >Save changes</button>
                                                                                                                                
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                        
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">File</th>
                          
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Subcourse name</th>
                        
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                        <th class=" d-flex justify-content-center  text-secondary text-xxs font-weight-bolder opacity-7">Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <td>{{item.index}}</td>
                        <td>
                            <div class="d-flex flex-row mb-0justify-content-center">
                                <div class="p-2"><p class="  mt-2 text-xs font-weight-bold mb-0">{{item.file}}</p></div>
                                
                            </div>
                            </td>
                            <td>
                                <div class="d-flex flex-row mb-0justify-content-center">
                                <div class="p-2"><p class="  mt-2 text-xs font-weight-bold mb-0">{{item.name}}</p></div>
                                
                            </div>
                           
                        </td>
                        
                        <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 
                       
                        <td class="align-middle">
                            
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs"(click)="editpdf(item.id)" >
                                Edit
                            </a>
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                            (click)="deletepdf(item.id)">
                            delete
                        </a>
                        <td>          
                        </td>


                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>

<div class="container-fluid">
  <aside
    class=" offcanvas active  offcanvas-start show navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark"
    id="sidenav-main" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling"
    aria-labelledby="offcanvasScrollingLabel">
    <div class="offcanvas-header">
      <a class="navbar-brand m-0" target="_blank"
      routerLink="dashboard">
        
        <img src="../assets/img/SCOPE1.png"    class="navbar-brand-img " alt="main_logo">
        <span class="ms-1 font-weight-bold text-white"></span>
      </a>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <hr class="horizontal light mt-0 mb-2">
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white active bg-gradient-primary" routerLink="dashboard">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">dashboard</i>
            </div>
            <span class="nav-link-text ms-1">Dashboard</span>
          </a>
        </li>

        <li class="nav-item"><a class="dropdown-toggle nav-link text-white " href="#collapse1" data-bs-toggle="collapse"
            aria-expanded="false" aria-controls="e-commerce" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <div class="d-flex align-items-center"><i class="material-icons opacity-10">table_view</i><span
                class="nav-link-text ps-1">Home</span></div>
          </a>
          <ul class=" collapse dropdown-menu " id="collapse1">
          
            <!-- <li><a class="dropdown-item" routerLink="apply_now">Apply Now</a></li> -->
            <li><a class="dropdown-item" routerLink="banner">Banner</a></li>
            <li><a class="dropdown-item"  routerLink="add_event_upcoming">Home Model</a></li>
            <li><a class="dropdown-item" routerLink="alumini"> Alumini</a></li>
            <li><a class="dropdown-item" routerLink="hire">Hire</a></li>
            <li><a class="dropdown-item" routerLink="expert_review">Expert_Review</a></li>
           </ul>
        </li>
        <li class="nav-item"><a class=" dropdown-toggle nav-link text-white " href="#collapse2"
            data-bs-toggle="collapse" aria-expanded="false" aria-controls="e-commerce" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex align-items-center"><i class="material-icons opacity-10">table_view</i><span
                class="nav-link-text ps-1">Contact</span></div>
          </a>
          <ul class=" collapse dropdown-menu " id="collapse2">
            <li><a class="dropdown-item" routerLink="counselling">  Website Enquiry</a></li>
            <!-- <li><a class="dropdown-item" routerLink="contact">Contact</a></li> -->
            <!-- <li><a class="dropdown-item" routerLink="Company_detail"> Company Detail</a></li> -->
            <li><a class="dropdown-item" routerLink="Our_Office"> Our Offices</a></li>
          </ul>
        </li>
        <li class="nav-item"><a class=" dropdown-toggle nav-link text-white " href="#collapse3"
            data-bs-toggle="collapse" aria-expanded="false" aria-controls="e-commerce" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex align-items-center"><i class="material-icons opacity-10">table_view</i><span
                class="nav-link-text ps-1">About</span></div>
          </a>
          <ul class=" collapse dropdown-menu " id="collapse3">
            <!-- <li><a class="dropdown-item" routerLink="about">About</a></li> -->
            
            <!-- <li><a class="dropdown-item" routerLink="about_counter">About Counter</a></li> -->
            <li><a class="dropdown-item" routerLink="google_review"> Google Review</a></li>
          </ul>
        </li>
        <li class="nav-item"><a class=" dropdown-toggle nav-link text-white " href="#collapse4"
            data-bs-toggle="collapse" aria-expanded="false" aria-controls="e-commerce" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex align-items-center"> <i class="material-icons opacity-10">receipt_long</i><span
                class="nav-link-text ps-1">Our Courses</span></div>
          </a>
          <ul class=" collapse dropdown-menu " id="collapse4">
            <li><a class="dropdown-item" routerLink="add_course">Add Course</a></li>

            <li><a class="dropdown-item" routerLink="add_subcourse">Add Sub Course</a></li>
            <!-- <li><a class="dropdown-item" routerLink="program_details">Program Details</a></li> -->
            <li><a class="dropdown-item" routerLink="subcourse_detail"> Sub Coures Detail</a></li>
            <li><a class="dropdown-item"  routerLink="add_emptygrid"> Empty Grid </a></li>
            <li><a class="dropdown-item" routerLink="Syllanuspdf_info">Syllabus Pdf Info</a></li>
            <li><a class="dropdown-item" routerLink="syllabus-pdf"> Add Syllabus Pdf</a></li>
            <li><a class="dropdown-item" routerLink="add_module"> Add Module</a></li>
            <li><a class="dropdown-item" routerLink="add_syllabus"> Add Syllabus</a></li>
            <li><a class="dropdown-item" routerLink="mentor">Mentor</a></li>
          
            <li><a class="dropdown-item" routerLink="program-fees-category">Program Fees Category</a></li>
            <li><a class="dropdown-item" routerLink="program-fees-list">Program Fees List</a></li>
            <li><a class="dropdown-item" routerLink="certificate">Certificate</a></li>

            <!-- <li><a class="dropdown-item" routerLink="cohorts">Cohorts</a></li> -->

            <li><a class="dropdown-item" routerLink="handsonCategory">Handson Category</a></li>
            <li><a class="dropdown-item" routerLink="handsonProject">Handson Project Details</a></li>
            <li><a class="dropdown-item" routerLink="learner_review"> Learner Review</a></li>
            <li><a class="dropdown-item"  routerLink="add_top_rank"> Top Rank </a></li>
          </ul>

        </li>
        <li class="nav-item"><a class=" dropdown-toggle nav-link text-white " href="#collapse5"
            data-bs-toggle="collapse" aria-expanded="false" aria-controls="e-commerce" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex align-items-center"> <i class="material-icons opacity-10">receipt_long</i><span
                class="nav-link-text ps-1">Event</span></div>
          </a>
          <ul class=" collapse dropdown-menu " id="collapse5">
            <!-- <li><a class="dropdown-item"  routerLink="event"> Event </a></li> -->
            <!-- <li><a class="dropdown-item"  routerLink="eventdata"> Event Details </a></li> -->
            <li><a class="dropdown-item"  routerLink="funatwork"> Fun at work Category</a></li>
            <li><a class="dropdown-item"  routerLink="funatworkdetails"> Fun at work Details </a></li>

            <li><a class="dropdown-item"  routerLink="recognition"> Recognition Category </a></li>
            <li><a class="dropdown-item"  routerLink="recognitiondetails"> Recognition Details </a></li>
            
            <li><a class="dropdown-item"  routerLink="mou"> MOU  Category</a></li>
            <li><a class="dropdown-item"  routerLink="moudetails"> MOU Details </a></li>
            
            <li><a class="dropdown-item"  routerLink="News"> News </a></li>
            <li><a class="dropdown-item"  routerLink="new_letter"> News  Letter</a></li>

            

            
          
            <!-- <li><a class="dropdown-item" routerLink="faq"> Faq</a></li>
         

            <li><a class="dropdown-item" routerLink="training_student"> Training Student</a></li>

            
            <li><a class="dropdown-item" routerLink="program_city"> Program City</a></li>
            <li><a class="dropdown-item" routerLink="highlights"> Highlights</a></li>
            
            <li><a class="dropdown-item" routerLink="course_highlights"> Course Highlights</a></li> -->

          </ul>
        </li>
      </ul>
    </div>
  </aside>

  <main class="main-content main-part position-relative max-height-vh-100 h-100 border-radius-lg ps ps--active-y ">
    <!-- Navbar -->
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur">
      <div class="container-fluid py-1 px-3">
        <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
          <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Dashboard</li>
        </ol>
        <h6 class="font-weight-bolder mb-0">Dashboard</h6>
      </nav> -->
        <div class="collapse navbar-collapse d-flex flex-row-reverse  mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">

          <ul class="navbar-nav ">

            <li class="nav-item d-flex align-items-center">
              <a href="../pages/sign-in.html" class="nav-link text-body font-weight-bold px-0">
                <i class="fa fa-user me-sm-1"></i>
                <span class="d-sm-inline d-none" (click)="logout()">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->

  </main>
 

  <router-outlet></router-outlet>
  <div class="footer">
  <span>© 2023, Made by Sumago Infotech with   <img src="../assets/img/red.png" width="25" height="25"> From Nashik</span>
  </div>
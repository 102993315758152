import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent implements OnInit{
  counterForm: any;
  counter: any;
  counterlist: any;
  editForm: any;
  
 currentPage = 1;
 itemsPerPage = 5; // Number of items per page
 totalPages: number;
 pagedItems: any[];
 pages: number[];

  constructor(private about:CounterService, private fb:FormBuilder,private router:Router){
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }

  config: ToggleButtonConfig;
  status: any;

 
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.about.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }

  ngOnInit(): void {
    this.createCounterForm();
    this.getcounterdata();
    this.createEditForm();
  }

  createCounterForm() {
    this.counterForm = this.fb.group({
      title: ['', Validators.required],
      
    });

  }
  onSubmit() {
    const formData = new FormData();
    formData.append('title', this.counterForm.value.title);
   ;
   
    // Call your CourseService method to upload the course with the formData
    this.about.addmodule(formData).subscribe(
      (response: any) => {
        if(response.statusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }
  editsyllabus(syllabusId: number) {
    this.router.navigate(['main/update_module', syllabusId]);
  }
    getcounterdata(){
      this.about.getmodule().subscribe((res:any)=>{
        this.counterlist=res.data;
        console.log(this.counterlist);
        this.counterlist.sort((a, b) => b.id - a.id);//////Desc order
        this.setPage(this.currentPage);
      
        
      })
    }
    

setPage(page: number) {
  this.currentPage = page;

  const startIndex = (page - 1) * this.itemsPerPage;
  const endIndex = Math.min(startIndex + this.itemsPerPage, this.counterlist.length);
  
  this.pagedItems = this.counterlist.slice(startIndex, endIndex);
  this.totalPages = Math.ceil(this.counterlist.length / this.itemsPerPage);
  
  // Calculate the index to continue numbering across pages
  const indexOffset = (page - 1) * this.itemsPerPage;
  this.pagedItems = this.pagedItems.map((item, index) => {
      return {
          ...item,
          index: index + 1 + indexOffset
      };
  });

  this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
}
prevPage() {
    if (this.currentPage > 1) {
        this.setPage(this.currentPage - 1);
    }
}

nextPage() {
    if (this.currentPage < this.totalPages) {
        this.setPage(this.currentPage + 1);
    }
}


    deletecounter(id: number) {
      const confirmation = confirm('Are you sure you want to delete this category?');
      if (confirmation) {
        this.about.deletemodule(id).subscribe(
          (response) => {
            console.log('Project deleted:', response);
            // You might want to refresh the categories list after deletion
            this.getcounterdata();
          },
          (error) => {
            console.error('Error deleting Project:', error);
          }
        );
      }
    }
    createEditForm() {
      this.editForm = this.fb.group({
        title: ['', Validators.required],
        
      });
    }
    // Function to open the edit modal and populate form fields with the selected counter data
    openEditModal(consult: any) {
      this.editForm.setValue({
        title: consult.title,
      
       
      });
    }
  
    // Function to handle the update operation in the edit modal
    updatecounter(about: any): void {
      const updatedData = this.editForm.value;
      this.about.updatemodule(about.id, updatedData).subscribe(
        (res: any) => {
          console.log('Data updated successfully:', res);
          alert("Data Updated")
          // Optionally, update the local list with the updated counter or fetch the updated list again
          this.getcounterdata();
        },
        (error) => {
          console.error('Failed to update archivement data:', error);
        }
      );
    }


}

<!-- Category -->
<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#eventcategory">
                ADD handson Category Data
            </a>
            <!-- Modal -->
            <div class="modal fade" id="eventcategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="handsonProject">

                                <div class="input-group input-group-outline mb-3">
                                    <div class="input-group input-group-outline my-3">
                                        <!-- <label  class="form-label" >select handson category</label> -->
                                        <select formControlName="handsonCategory" class="form-control" formControlName="handson_category_id" >
                                            <option value="">select category</option>
                                            <option *ngFor="let category of handsonCategoryDetails"
                                                [value]="category.id">{{ category.title }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="input-group input-group-outline mb-3">
                                    <div class="input-group input-group-outline my-3">
                                        <!-- <label  class="form-label" for="subCourse">select sub course</label> -->
                                        <select class="form-control" formControlName="sub_course_id" multiple>
                                            <option value="">Select sub course</option>
                                            <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.subcourses_id">
                                                {{ subCourse.subcourses_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="input-group input-group-outline mb-3">
                                    <div class="input-group input-group-outline my-3">
                                        <!-- <label class="form-label">title</label> -->
                                        <input type="text" class="form-control" formControlName="title"
                                            placeholder="title">
                                    </div>
                                </div>
                                <div class="input-group input-group-outline mb-3">
                                    <div class="input-group input-group-outline my-3">
                                        <!-- <label for="description">Description:</label> -->
                                        
                                        <textarea formControlName="desc" placeholder="description" class="form-control"></textarea>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="button" (click)="addProjects()" class="btn bg-gradient-primary">Save
                                changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Course name</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">  Handson Category</th>
                        
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Title</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Description</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 ">Status</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-flex justify-content-center">Action</th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <td class="ps-4">{{item.index}}</td>
                       
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.subcourse_details}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.category_name}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.title}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>

                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.desc|slice:0:50}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 

                        <!-- <td class="align-middle">
                            <a  class="btn btn-warning ms-2 text-white font-weight-normal text-xs" 
                            (click)="updateHandson(item.id)" >
                            Edit
                        </a>  -->

                            <!-- Modal -->
                            <!-- <div class="modal fade" id="editeventdata2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit
                                                Course</h5>
                                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form [formGroup]="editForm">

                                                <div class="input-group input-group-outline mb-3">
                                                    <div class="input-group input-group-outline my-3">
                                                        <label class="form-label">handson category</label>
                                                        <select class="form-control" formControlName="handson_category_id">
                                                            <option value="">handson category</option>
                                                            <option *ngFor="let category of handsonCategoryDetails"
                                                                [value]="category.id">{{ category.title }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="input-group input-group-outline mb-3">
                                                    <div class="input-group input-group-outline mb-3">

                                                        <select class="form-control" formControlName="sub_course_id">
                                                            <option value="">sub course</option>
                                                            <option *ngFor="let subCourse of subcourses"
                                                            [value]="subCourse.subcourses_id">{{
                                                            subCourse.subcourses_name }}</option>
                                                        </select>
                                                    </div>

                                                    
                                                </div>
                                                <div class="input-group input-group-outline mb-3">
                                                    <div class="input-group input-group-outline my-3">
                                                        <label class="form-label">title</label>
                                                        <input type="text" class="form-control" formControlName="title"
                                                            placeholder="title">
                                                    </div>
                                                </div>
                                                <div class="input-group input-group-outline mb-3">
                                                    <div class="input-group input-group-outline my-3">
                                                        <label for="description">Description:</label>
                                                        <textarea class="form-control" formControlName="desc"
                                                            placeholder="Description"></textarea>
                                                    </div>
                                                </div>

                                            </form>


                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn bg-gradient-secondary"
                                                data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn bg-gradient-primary"
                                                (click)="updateHired(item) ">Save changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- Modal End -->

                            <td class="d-flex justify-content-center">
                                <a  class="btn btn-warning ms-2 text-white font-weight-normal text-xs" 
                                (click)="updateHandson(item.id)" >
                                Edit
                            </a> 
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                                (click)="deleteProjects(item.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
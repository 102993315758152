import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-toprank',
  templateUrl: './update-toprank.component.html',
  styleUrls: ['./update-toprank.component.css']
})
export class UpdateToprankComponent implements OnInit {
  eventId: any;
  hireform: any;
  base64Image: string;

  constructor(private route: ActivatedRoute, private formBuilder:FormBuilder,  private service: CounterService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.hireform = this.formBuilder.group({
    
      selectedFile: [null, Validators.required]
    });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  onSubmit(): void {
  

    const formData = new FormData();
   
    formData.append('image', this.base64Image);

    this.service.updaterank(this.eventId,formData).subscribe(
      (response: any) => {
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }

}

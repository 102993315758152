import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-feecategory',
  templateUrl: './update-feecategory.component.html',
  styleUrls: ['./update-feecategory.component.css']
})
export class UpdateFeecategoryComponent implements OnInit{
  eventId: any;
  programform: any;
  programlist: any;
  constructor(private route: ActivatedRoute, private service: CounterService,private formBuilder:FormBuilder) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.programform = this.formBuilder.group({
      title: ['', Validators.required],
    });

   
  }

  onSubmit(): void {
    if (this.programform.valid) {
      const formData = new FormData();
      formData.append('title', this.programform.value.title);

      this.service.updateprogramdetailscategory(this.eventId,formData).subscribe(
        (response: any) => {
          if (response.statusCode === '200') {
            alert('Data added successfully');
            this.getCategories();
            location.reload();
          } else {
            alert('Something went wrong');
          }
        },
      );
    }
  }
  getCategories(): void {
    this.service.getprogramdetailcategory().subscribe((res: any) => {
      console.log(res);
      this.programlist = res.data;
      this.programlist.sort((a, b) => b.id - a.id);//////Desc order
      // this.setPage(this.currentPage);
    
    });
  }
}

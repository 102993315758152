import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-eventdata',
  templateUrl: './update-eventdata.component.html',
  styleUrls: ['./update-eventdata.component.css']
})
export class UpdateEventdataComponent  {
  name:any
  eventlist: any;
  base64Images: string[] = [];
  evnetform: any;
  eventdata: any;
  details: any;
  base64Image: string;
  subcourses: any;
  title: string;
  list: any;
  id: number;

  constructor(private event:CounterService,private formBuilder:FormBuilder,private router:Router){}

ngOnInit(): void {
  this.eventdetail();
  this.geteventlist();
  this.geteventdetail();
  this.getsubcoure();
  
}
eventdetail(): void {
  this.evnetform = this.formBuilder.group({
    title:['',Validators.required],
    description: ['', Validators.required],
    event_id:['',Validators.required],
    subcourse_id:['',Validators.required],
    selectedFile: [null, Validators.required]
  });
}
geteventlist(){
  this.event.geteventlist().subscribe((res:any)=>{
    this.list =res.data;
  })
}
getsubcoure() {
  this.event.getsubcourse().subscribe((res) => {
    this.subcourses = res['data'];
  });
}



onFileSelected(event: any): void {
  const file = event.target.files[0];
  this.convertToBase64(file);
}

convertToBase64(file: File): void {
  const reader = new FileReader();
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
  reader.readAsDataURL(file);
}

onSubmit(): void {
  const formData = new FormData();
  formData.append('event_id', this.evnetform.value.event_id);
  formData.append('title', this.evnetform.value.title);
  formData.append('description', this.evnetform.value.description);
  formData.append('subcourse_id', this.evnetform.value.subcourse_id);
  formData.append('image',this.base64Image)
  

  this.event.updateeventdetail(this.id,formData).subscribe(
    (response: any) => {
        if (response.StatusCode == '200') {
          alert("Data added successfully");
          location.reload();
        } else {
          alert("Something went wrong");
        }
      },
      (error) => {
        console.error('Failed to add course:', error);
      }
    );
  }

geteventdetail(){
  this.event.geteventdetail().subscribe((res:any)=>{
    console.log(res);
    
    this.details=res;

  })
}


updateeventdata(id: number) {
  this.router.navigate(['/main/update-eventdata', id]);
}



 
  deleteeventdetail(id: number) {
    this.event.deleteeventdetail(id).subscribe(
      () => {
        console.log('Event  deleted successfully');
        // Optionally, update the local list by removing the deleted expert review or fetch the updated list again
        this.geteventdetail();
      },
      (error) => {
        console.error('Failed to delete event details:', error);
      }
    );
  }

}




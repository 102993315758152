import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CKEditor4 } from 'ckeditor4-angular';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';
// import { CKEditorModule } from 'ckeditor4-angular';



@Component({
  selector: 'app-certicate',
  templateUrl: './certicate.component.html',
  styleUrls: ['./certicate.component.css']
})


export class CerticateComponent implements OnInit{

  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];


  stripTags(value: string): string {
    if (!value) return value;
    // Use a regular expression to remove HTML tags
    return value.replace(/<[^>]*>/g, '');
  }
  get ckeditorTextFormElement() { return this.certificate.get('description'); }
  ckEditorConfig: any = { toolbar: [

  [ 'NumberedList','Bold', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl' ],
 
	] };
  constructor(private newweb: CounterService, private formBuilder: FormBuilder,private router:Router) { 
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }
  config: ToggleButtonConfig;
  status: any;

 
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.newweb.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }

  certificate: any;
  base64Image: string;
  certificatelist: any;
  courseDetails: any;
  subcourses: any;
  joinedFaqs: any;
  joinedAlumni: any;
  // editor: { toolbar: { name: string; items: string[]; }[]; };

  // public editor: CKEditor4.Config;
// description: string|number;



  

  ngOnInit(): void {
    this.addcerticate();
    this.getcertificate();
    // this.getCourse();
    this.getsubcoure()
    
  }

//   getCourse(){
//     this.counter.getcourse().subscribe((res: any) => {
//       this.courseDetails = res.data; // Assign directly, assuming the data is an array
//       console.log(this.courseDetails);
//     });

//   }
  getsubcoure(){
    this.newweb.getsubcourse().subscribe((res) => {
      this.subcourses = res['data']
      // this.joinTables()
  })
}
getcertificate(){
  this.newweb.getcertificate().subscribe((res:any)=>{
    console.log(res);
    
    this.certificatelist=res;
    this.certificatelist.sort((a, b) => b.id - a.id);//////Desc order
    this.setPage(this.currentPage);
  
  
  

  })
}
setPage(page: number) {
  this.currentPage = page;

  const startIndex = (page - 1) * this.itemsPerPage;
  const endIndex = Math.min(startIndex + this.itemsPerPage, this.certificatelist.length);
  
  this.pagedItems = this.certificatelist.slice(startIndex, endIndex);
  this.totalPages = Math.ceil(this.certificatelist.length / this.itemsPerPage);
  
  // Calculate the index to continue numbering across pages
  const indexOffset = (page - 1) * this.itemsPerPage;
  this.pagedItems = this.pagedItems.map((item, index) => {
      return {
          ...item,
          index: index + 1 + indexOffset
      };
  });

  this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
}
prevPage() {
    if (this.currentPage > 1) {
        this.setPage(this.currentPage - 1);
    }
}

nextPage() {
    if (this.currentPage < this.totalPages) {
        this.setPage(this.currentPage + 1);
    }
}

// joinTables() {
//   if (this.subcourses.length > 0 && this.certificatelist.length > 0) {
//     this.joinedAlumni = this.certificatelist.map((alumni) => {
//       const matchingSubcourse = this.subcourses.find(subcourse => subcourse.subcourse_id === alumni.course_id);
//       return {
//         ...alumni,
//         subcourses_name: matchingSubcourse ? matchingSubcourse.subcourses_name : 'Unknown Subcourse'
//       };
//     });
//   }
// }

  addcerticate(): void {
    this.certificate = this.formBuilder.group({
      // course_id:['',Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      selectedFile: [null, Validators.required]
    });
  }
 

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onSubmit(): void {
   

    const formData = new FormData();
    // formData.append('course_id',this.certificate.value.course_id);
    formData.append('title', this.certificate.value.title);
    formData.append('description',this.certificate.value.description),
    formData.append('image', this.base64Image);

    this.newweb.addcertificate(formData).subscribe(
      (response: any) => {
        if(response.statusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }
  editsyllabus(syllabusId: number) {
    this.router.navigate(['main/update_certificate', syllabusId]);
  }
  
  deletecertificate(id: number) {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.newweb.deletecertificate(id).subscribe(
        (response) => {
          console.log('Certificate deleted:');
          alert(`Certificate Deleted`)
          // You might want to refresh the categories list after deletion
          this.getcertificate();
        },
        (error) => {
          console.error('Error deleting Project:', error);
        }
      );
    }
  }
}


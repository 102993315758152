import { Component } from '@angular/core';

@Component({
  selector: 'app-update-leaner',
  templateUrl: './update-leaner.component.html',
  styleUrls: ['./update-leaner.component.css']
})
export class UpdateLeanerComponent {

}

<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#addsyllabus">
           Add Syllabus
        </a>
         <!-- Modal -->
         <div class="modal fade" id="addsyllabus" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                    <form [formGroup]="ProgramFeesFormData">
                     

                      <div class="input-group input-group-outline my-3">
                        <!-- <label class="form-label">select subcourse</label> -->
                        <select class="form-control" formControlName="course_id">
                            <option value="">Select sub course</option>
                            <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">
                                {{ subCourse.subcourses_name }}
                            </option>
                        </select>
                        <div class="invalid-feedback">Course is required</div>
                      </div>

                      <div class="input-group input-group-outline my-3">
                        <!-- <label for="course">Module</label> -->
                        <select id="module" formControlName="module_id" class="form-control" required>
                          <option value="" disabled selected>select module</option>
                          <option [value]="sub.id" *ngFor="let sub of counterlist" style="color: black;">{{ sub.title }}</option>
                        </select>
                        
                      </div>
                      
                  
                      <div class="input-group input-group-outline my-3">
                        
                        <input type="text" class="form-control" formControlName="title" placeholder="title">
                      </div>
                  
                      <div class="input-group input-group-outline my-3">
                 
                        <!-- <input type="text" class="form-control" formControlName="description" placeholder="description"> -->
                        <!-- <ckeditor formControlName="description" [config]="ClassicEditor"></ckeditor> -->
                        <ckeditor name="ckeditor_text" formControlName="description"
                        editorUrl="https://cdn.ckeditor.com/4.14.0/full/ckeditor.js"
                        [config]="ckEditorConfig"></ckeditor>

                       
                      </div>

                       

                  <div align="right">
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                    <button type="submit" class="btn bg-gradient-primary" (click)="onSubmit()">Save changes</button>
                 
                  </div>
                      </form>
                  </div>
                  <div class="modal-footer"></div>
             </div>
         </div>
     </div>
     <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Sr.no</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Course Name</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Module Name</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                           Title</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Description</th>   
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">Status</th>   
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center">Action</th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">
                    <tr *ngFor="let item of pagedItems ">
                        <td>{{item.index}}</td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0 align-middle">{{item.subcourses_name}}</p>
                           
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0 align-middle">{{item.module_name}}</p>
                           
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0 align-middle">{{item.title}}</p>
                           
                        </td>
                        <td >
                            <p class="text-xs font-weight-bold mb-0 c">{{ stripTags(item.description)|slice:0:50}}</p>
                       
                        </td>

                        <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 

                        <td class="d-flex justify-content-center">
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" (click)="editsyllabus(item.id)">
                            Edit
                        </a>
                            <!-- <i class="fa fa-edit" (click)="editsyllabus(item.id)" title="Edit"></i> -->
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deleteProgramFeesData(item.id)">
                                delete
                            </a>

                        </td>
                        <!-- <td class="align-middle">
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editconsult" >
                            Edit
                        </a>

                            <div class="modal fade" id="editconsult" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form [formGroup]="ProgramFeesFormData">
                       

                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">title</label>
                                                <input type="text"  class="form-control" formControlName="title">
                                        </div>
                                       
                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">description</label>
                                                <input type="text"  class="form-control" formControlName="description">
                                        </div>
                
                                       
                                        
                                     </form>

                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="submit" (click)="updateProgramFeesData(item.id)" class="btn bg-gradient-primary">Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                    -->
                       

                        
                      
                        
                    </tr> 
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>
<br>
<br>
<script src="https://cdn.ckeditor.com/ckeditor5/28.0.0/classic/ckeditor.js"></script>
<script>

  ClassicEditor
    .create( document.querySelector( '#editor' ) )
    .catch( error => {
        console.error( error );
    } );

</script>



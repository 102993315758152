import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';

@Component({
  selector: 'app-handson-project',
  templateUrl: './handson-project.component.html',
  styleUrls: ['./handson-project.component.css']
})
export class HandsonProjectComponent implements OnInit {
  handsonProjectDetails: any[];
  handsonProject: FormGroup;
  handsonProjectUpdateForm: FormGroup;
  subcourses: any
  handsonCategoryDetails: any[]
  joinedHandsonProjects: any[];
  editForm: any;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];
  config: ToggleButtonConfig;
  status: any;

  ngOnInit(): void {
    this.getHandsonProject()
    this.getCategories()
    this.getsubcourse()
    this.createEditForm()
    
  }
  
  getsubcourse() {
    this.service.getsubcourse().subscribe((res) => {
      this.subcourses = res['data'];
      // this.joinTables();
    });
  }

  getCategories() {
    this.service.getHandsonCategory().subscribe(
      (response: any) => {
        this.handsonCategoryDetails = response.data;
       
      
        // this.joinTables();
      },
      (error) => {
        console.error('Error getting categories:', error);
      }
    );
  }
  
setPage(page: number) {
  this.currentPage = page;

  const startIndex = (page - 1) * this.itemsPerPage;
  const endIndex = Math.min(startIndex + this.itemsPerPage, this.handsonProjectDetails.length);
  
  this.pagedItems = this.handsonProjectDetails.slice(startIndex, endIndex);
  this.totalPages = Math.ceil(this.handsonProjectDetails.length / this.itemsPerPage);
  
  // Calculate the index to continue numbering across pages
  const indexOffset = (page - 1) * this.itemsPerPage;
  this.pagedItems = this.pagedItems.map((item, index) => {
      return {
          ...item,
          index: index + 1 + indexOffset
      };
  });

  this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
}
prevPage() {
    if (this.currentPage > 1) {
        this.setPage(this.currentPage - 1);
    }
}

nextPage() {
    if (this.currentPage < this.totalPages) {
        this.setPage(this.currentPage + 1);
    }
}
  updateHandson(id:number
    ){
    
    this.route.navigate(['/main', 'update-handson-detail', id]);
  }


  getHandsonProject() {
    this.service.getHandsonProject().subscribe(
      (response) => {
        this.handsonProjectDetails = response;
        this.handsonProjectDetails.sort((a, b) => b.id - a.id);//////Desc order
        this.setPage(this.currentPage);
      
        // this.joinTables();
      },
      (error) => {
        console.error('Error getting projects:', error);
      }
    );
  }

 
  constructor(private service: CounterService, private fb: FormBuilder,private route:Router) {
    
    

    this.handsonProject = this.fb.group({
      handson_category_id: [],
      sub_course_id: [[]],
      title: [''],
      desc: [''],
    });

    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }
  hireform: any;
  is_active:number;
 
  changeStatus(item: any) {
    // Toggle the is_active status of the item
    item.is_active = !item.is_active;
  
    // Call the service to update the status
    this.service.changeStatus(item.table_name, item.id, item.is_active).subscribe((res: any) => {
      this.status = res;
    });
  }


    // Initialize the update form
   
  

 


//   onSubmit(): void {
//     const formData = new FormData();
//     formData.append('handson_category_id', this.handsonProjectUpdateForm.value.handson_category_id);
//     let subCourseIds = this.handsonProjectUpdateForm.value.sub_course_id;

// // Check if subCourseIds is an array; if not, convert it to an array
// if (!Array.isArray(subCourseIds)) {
//   subCourseIds = [subCourseIds];
// }

// // Loop through the subCourseIds array and append each value to formData
// for (const subCourseId of subCourseIds) {
//   formData.append('sub_course_id[]', subCourseId);
// }
//     // formData.append('sub_course_id', this.aluminiform.value.sub_course_id);
    
//     formData.append('title', this.handsonProjectUpdateForm.value.title);
//     formData.append('desc', this.handsonProjectUpdateForm.value.desc);
   
//    this.service.addHandosnProject(formData).subscribe(
//       (response: any) => {
//         if (response.StatusCode == '200') {
//           alert("Data added successfully");
//           location.reload();
//         } else {
//           alert("Something went wrong");
//         }
//       },
//       (error) => {
//         console.error('Failed to add course:', error);
//       }
//     );
//   }
  addProjects() {
    if (this.handsonProject.valid) {
      const data = {
        
        
        handson_category_id: this.handsonProject.value.handson_category_id,
        // const subCourseIds : Array.isArray(sub_course_id)
        // ? sub_course_id.map(String) // Convert all values to strings if multiple are selected
        // : [String(sub_course_id)];

        sub_course_id: Array.isArray(this.handsonProject.value.sub_course_id)
        ? this.handsonProject.value.sub_course_id.map(String)
        : [String(this.handsonProject.value.sub_course_id)],
        
        // sub_course_id: this.handsonProject.value.sub_course_id,
        title: this.handsonProject.value.title,
        desc: this.handsonProject.value.desc,
      };
      this.service.addHandosnProject(data).subscribe(
        (response: any) => {

          this.handsonProject.reset();
          if(response.StatusCode == '200') {
            // this.router.navigate(['/main/banner'])
            alert("Data added successfully");
            location.reload();
  
          } else {
            alert("Something went wrong");
          }
        },
      );
    }
  }

  deleteProjects(id: number) {
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.service.deleteHandsonProject(id).subscribe(
        (response) => {
          console.log('Project deleted:', response);
          // You might want to refresh the categories list after deletion
          this.getHandsonProject();
        },
        (error) => {
          console.error('Error deleting Project:', error);
        }
      );
    }
  }
  openEditModal(hire: any) {
    this.editForm.setValue({
      handson_category_id: hire.handson_category_id,
      sub_course_id: hire.sub_course_id,
      title:hire.title,
      desc: hire.desc // Use the 'image' property from the hire object
    });
  }
  
  createEditForm() {
    this.editForm = this.fb.group({
      handson_category_id:['',Validators.required],
      sub_course_id:['',Validators.required],
      title: ['', Validators.required],
      desc: ['', Validators.required],
      
    });
  }
  // Function to handle the update operation in the edit modal
  updateHired(hire: any): void {
    const updatedData = this.editForm.value;
  
    const formData = new FormData();
    formData.append('handson_category_id', updatedData.handson_category_id);
    formData.append('sub_course_id', updatedData.sub_course_id);
    formData.append('title', updatedData.title);
    formData.append('desc', updatedData.desc);
  
  



      this.service.updateHandsonProject(hire.id, updatedData).subscribe(
        (response) => {
          console.log('Project updated:', response);
          this.getHandsonProject(); // Refresh the category list after successful update
        },
        (error) => {
          console.error('Error updating Project:', error);
        }
      );
    }
  }




import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-expert',
  templateUrl: './update-expert.component.html',
  styleUrls: ['./update-expert.component.css']
})
export class UpdateExpertComponent implements OnInit {
  eventId: any;
  expertform: any;
  base64Image: string;

  constructor(private route: ActivatedRoute, private service: CounterService,private formBuilder:FormBuilder) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.expertform = this.formBuilder.group({
      review: ['', Validators.required],
      name: ['', Validators.required],
      company_position: ['', Validators.required],
      selectedFile: [null, Validators.required]
    });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onSubmit(): void {
    const formData = new FormData();
    formData.append('review', this.expertform.value.review);
    formData.append('name', this.expertform.value.name);
    formData.append('company_position', this.expertform.value.company_position);
    formData.append('image', this.base64Image);

    this.service.updateExpert(this.eventId,formData).subscribe(
      
        (response: any) => {
          if(response.statusCode == '200') {
            // this.router.navigate(['/main/banner'])
            alert("Data added successfully");
            location.reload();
  
          } else {
            alert("Something went wrong");
          }
        },
    );
  }
}

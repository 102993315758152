import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CounterService } from 'src/app/services/counter.service';
import { ToggleButtonConfig } from '../toggle-button/toggle-button.component';

@Component({
  selector: 'app-empty-grid',
  templateUrl: './empty-grid.component.html',
  styleUrls: ['./empty-grid.component.css']
})
export class EmptyGridComponent {
  base64Image: string;
  hirelist: any;
  value: boolean;
  config: ToggleButtonConfig;
  currentPage = 1;
  itemsPerPage = 5; // Number of items per page
  totalPages: number;
  pagedItems: any[];
  pages: number[];

  constructor(private newweb:CounterService,private formBuilder:FormBuilder){
    let self = this;
    
    this.config = {
      state: true,
      onText: 'Active',
      offText: 'Deactive',
      onColor: 'btn btn-info',
      offColor: 'btn btn-primary'
    };
  }
  hireform: any;
  // is_active:number;
  is_active: boolean = true; // Initialize to false



  ngOnInit(): void {
    this.addempty();
    this.getempty();
    // this.createEditForm();
    
  }
  addempty(): void {
    this.hireform = this.formBuilder.group({
    
      selectedFile: [null, Validators.required],
      // is_active: ['',Validators]
    });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.is_active = !!this.base64Image; 
    };
    reader.readAsDataURL(file);
  }

  onSubmit(): void {
  

    const formData = new FormData();
   
    formData.append('image', this.base64Image);
    // formData.append('is_active', this.hireform.value.is_active);
  

    this.newweb.addemptygrid(formData).subscribe(
      (response: any) => {
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }

  getempty(){
    this.newweb.getemptygrid().subscribe((res:any)=>{
      console.log(res);
      this.hirelist=res;
   
      this.setPage(this.currentPage);
      // this.hirelist.sort((a, b) => b.id - a.id);//////Desc order
    
    })
  }

  setPage(page: number) {
    this.currentPage = page;
  
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.hirelist.length);
    
    this.pagedItems = this.hirelist.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.hirelist.length / this.itemsPerPage);
    
    // Calculate the index to continue numbering across pages
    const indexOffset = (page - 1) * this.itemsPerPage;
    this.pagedItems = this.pagedItems.map((item, index) => {
        return {
            ...item,
            index: index + 1 + indexOffset
        };
    });
  
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  prevPage() {
      if (this.currentPage > 1) {
          this.setPage(this.currentPage - 1);
      }
  }
  
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.setPage(this.currentPage + 1);
      }
  }
  deleteempty(id:number){
    const confirmation = confirm('Are you sure you want to delete this category?');
    if (confirmation) {
      this.newweb.deleteemptygrid(id).subscribe(
        (response) => {
          console.log('Project deleted:', response);
          alert("Data Deleted")
          // You might want to refresh the categories list after deletion
          this.getempty();
          location.reload();
        },
        (error) => {
          console.error('Error deleting Project:', error);
        }
      );
    }

  }
 

 

  public onStateChanged(value: boolean) {
    this.value = value;
  }

}






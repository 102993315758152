import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-hire',
  templateUrl: './update-hire.component.html',
  styleUrls: ['./update-hire.component.css']
})
export class UpdateHireComponent implements OnInit{
  eventId: any;
  hireform: any;
  formBuilder: any;
  base64Image: string;

    constructor(private route: ActivatedRoute, private service: CounterService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });

  
    this.hireform = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      selectedFile: [null, Validators.required]
    });
  }
 
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onSubmit(): void {
  

    const formData = new FormData();
    formData.append('title', this.hireform.value.title);
    formData.append('description', this.hireform.value.description);
    formData.append('image', this.base64Image);

    this.service.updatehire(this.eventId,formData).subscribe(
      (response: any) => {
        if(response.statusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }


}

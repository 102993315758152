import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-alumini',
  templateUrl: './update-alumini.component.html',
  styleUrls: ['./update-alumini.component.css']
})
export class UpdateAluminiComponent implements OnInit{
  courseDetails: any;
  subcourses: any;
  aluminilist: any;
  aluminiform: any;
  // base64Imagelogo: string;
  base64Image: string;
  base64CompanyLogo: string ;
  aluminiid: any;
  // base64Imagelogo: string | Blob;

  constructor(private newweb: CounterService, private formBuilder: FormBuilder,private router:ActivatedRoute){
    this.aluminiform = this.formBuilder.group({
      sub_course_id: [[], Validators.required],
      name: ['', Validators.required],
      designation: ['', Validators.required],
      company: ['', Validators.required],
      selectedFile: [null, Validators.required],
      company_logo:[null,Validators.required]
      
    });
  }
  ngOnInit(): void {
   
    this.getalumini();
    
    this.getsubcoure();
    this.router.params.subscribe(params => {
      this. aluminiid= params['id'];
      console.log(this.aluminiid);
      
    });
   
   

  }
  
  getsubcoure() {
    this.newweb.getsubcourse().subscribe((res) => {
      this.subcourses = res['data'];
    });
  }

  getalumini() {
    this.newweb.getalumini().subscribe((res: any) => {
      console.log(res);
      this.aluminilist = res.data;
    });
  }

 
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  
  onFileSelectedlogo(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64logo(file);
  }

  

  convertToBase64logo(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64CompanyLogo = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  


  onSubmit(): void {
    const formData = new FormData();
    let subCourseIds = this.aluminiform.value.sub_course_id;

// Check if subCourseIds is an array; if not, convert it to an array
if (!Array.isArray(subCourseIds)) {
  subCourseIds = [subCourseIds];
}

// Loop through the subCourseIds array and append each value to formData
for (const subCourseId of subCourseIds) {
  formData.append('sub_course_id[]', subCourseId);
}
    // formData.append('sub_course_id', this.aluminiform.value.sub_course_id);
    formData.append('name', this.aluminiform.value.name);
    formData.append('designation', this.aluminiform.value.designation);
    formData.append('company', this.aluminiform.value.company);
    formData.append('image', this.base64Image);
    formData.append('company_logo', this.base64CompanyLogo);
   
    this.newweb.updateAlumni(this.aluminiid,formData).subscribe(
      (response: any) => {
        if (response.StatusCode == '200') {
          alert("Data added successfully");
          // location.reload();
          this.getalumini();
        } else {
          alert("Something went wrong");
        }
      },
      (error) => {
        console.error('Failed to add course:', error);
      }
    );
  }


}
 
  




            <form [formGroup]="bannerForm">
                <div class="input-group input-group-outline my-3">
                    <!-- <label for="course">Course</label> -->
        <select id="course" formControlName="course_id" class="form-control" required>
          <option value="" disabled selected>select course</option>
          <option [value]="course.id" *ngFor="let course of courseDetails" style="color: black;">{{ course.name }}</option>
        </select>
                </div>
                <div class="input-group input-group-outline my-3">
                    <!-- <label class="form-label">Price</label> -->
                    <input type="text" class="form-control" id="name" formControlName="name" placeholder="name">
                </div>
                <div class="input-group input-group-outline my-3">

                    <input type="file" id="selectedFile" class="form-control"
                        (change)="onFileSelected($event)">
                </div>
               




            </form>


     
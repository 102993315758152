<div class="modal fade" id="addhired" tabindex="-1" role="dialog"
aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
       <div class="modal-header">
            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="hireform"  >
              
             
               <div class="input-group input-group-outline my-3">
                   
                       <input type="file" id="selectedFile" class="form-control" (change)="onFileSelected($event)" >
               </div>
               <div align="right">
                <button type="button" class="btn bg-gradient-secondary"
                data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
            <button type="submit"  (click)="onSubmit()" class="btn bg-gradient-primary">Save changes</button>
           </div>
            </form>
        </div>
        <div class="modal-footer">
           
        </div>ss
    </div>
</div>
</div>
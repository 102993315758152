import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-subcourse',
  templateUrl: './update-subcourse.component.html',
  styleUrls: ['./update-subcourse.component.css']
})
export class UpdateSubcourseComponent implements OnInit{
  base64Image: string;
  syllabusId: any;
  bannerForm: any;
  courseDetails: any;
  subcourseId: any;
  constructor(private service:CounterService,private formBuilder:FormBuilder,private route:ActivatedRoute){
   
    this.bannerForm = this.formBuilder.group({
      course_id: ['', Validators.required],
      name: ['', Validators.required],
      selectedFile: [null, Validators.required]

    });
  }
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this. syllabusId= params['id'];
      console.log(this.syllabusId);
  
    
  });
  }
  
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
      console.log(this.base64Image);
    };
    reader.readAsDataURL(file);
  }
  getCourse() {
    this.service.getcourse().subscribe((res: any) => {
      this.courseDetails = res.data; // Assign directly, assuming the data is an array
      console.log(this.courseDetails);
      // this.joinTables()
    });
  }

  onSubmit(){
    const formData = new FormData();
    formData.append('course_id', this.bannerForm.value.course_id);
    formData.append('name', this.bannerForm.value.name);
    formData.append('image', this.base64Image);



    this.service.updatesubcourse(this.syllabusId,formData).subscribe(
      (response: any) => {
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Data added successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
    );
  }
  

}

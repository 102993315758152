import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-subcoursedetails',
  templateUrl: './update-subcoursedetails.component.html',
  styleUrls: ['./update-subcoursedetails.component.css']
})
export class UpdateSubcoursedetailsComponent implements OnInit {
  subcourseId: any;
  courseDetails: any;
  subcourses: any;
  subCourseDetails: any;
  base64ImageBanner: string;
  base64ImageBackImage: string;

  constructor(private service:CounterService,private formBuilder:FormBuilder,private router:ActivatedRoute){
    this.subCourseDetails = this.formBuilder.group({
      course_id: ['', Validators.required],
      sub_course_id: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      custome_text: ['', Validators.required],
      selectedFile: [null, Validators.required],
      banner: ['', Validators.required],
      back_image: ['', Validators.required],
    });
  }
  

  ngOnInit(): void {
    this.getsubcourse();
    this.getCourse();
    this.router.params.subscribe(params => {
      this. subcourseId= params['id'];
      console.log(this.subcourseId);
  
    
  });
  }
  getSubCourseFromCourse(event) {
    console.log(event);
    var obj = {
      course_id: event.target.value,
    };

    this.service.getSubCourse(obj).subscribe((alldist) => {
      this.subcourses = alldist['data'];
    });
  }

  

  getCourse() {
    this.service.getcourse().subscribe((res: any) => {
      this.courseDetails = res.data;
    });
  }

  getsubcourse() {
    this.service.getsubcourse().subscribe((res: any) => {
      this.subcourses = res.data;
    });
  }
  convertToBase64Banner(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64ImageBanner = reader.result as string;
      console.log(this.base64ImageBanner)
    };
    reader.readAsDataURL(file);
  }

  convertToBase64BannerBackImage(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64ImageBackImage = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onFileSelectedBanner(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64Banner(file);
  }

  onFileSelectedBackImage(event: any): void {
    const file = event.target.files[0];
    // this.modelFile = file;
    this.convertToBase64BannerBackImage(file);
  }

  onSubmit(): void {
    const formData = new FormData();
    formData.append('course_id', this.subCourseDetails.value.course_id);
    formData.append('sub_course_id', this.subCourseDetails.value.sub_course_id);
    formData.append('title', this.subCourseDetails.value.title);
    formData.append('description', this.subCourseDetails.value.description);
    formData.append('custome_text', this.subCourseDetails.value.custome_text);
    formData.append('banner', this.base64ImageBanner);
    formData.append('back_image', this.base64ImageBackImage); // Use this.modelFile for back_image

    this.service.addSubscoursesdetail(formData).subscribe(
      (response: any) => {
        console.log(response);

        if (response.StatusCode == '200') {
          alert('Data added successfully');
          location.reload();
        } else {
          alert('Something went wrong');
        }
      },
      (error) => {
        console.error('Failed to add course:', error);
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-fundetails',
  templateUrl: './update-fundetails.component.html',
  styleUrls: ['./update-fundetails.component.css']
})
export class UpdateFundetailsComponent implements OnInit {
  eventId: any;
  evnetform: any;
  base64Image: string;
  category: any;

  constructor(private route: ActivatedRoute, private formBuilder:FormBuilder,  private service: CounterService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.evnetform = this.formBuilder.group({
      funatworkcategoryid:['',Validators.required],
      title:['',Validators.required],
      description: ['', Validators.required],
      selectedFile: [null, Validators.required]
    });

}

onFileSelected(event: any): void {
  const file = event.target.files[0];
  this.convertToBase64(file);
}

convertToBase64(file: File): void {
  const reader = new FileReader();
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
  reader.readAsDataURL(file);
}
getcategory() {
  this.service.getfunatwork().subscribe((res) => {
    this.category = res['data'];
  });
}
onSubmit(): void {
  const formData = new FormData();
  formData.append('funatworkcategoryid', this.evnetform.value.funatworkcategoryid);
  formData.append('title', this.evnetform.value.title);
  formData.append('description', this.evnetform.value.description);
  // formData.append('subcourse_id', this.evnetform.value.subcourse_id);
  formData.append('image',this.base64Image)
  

  this.service.updatefundetail(this.eventId,formData).subscribe(
    (response: any) => {
        if (response.statusCode == '200') {
          alert("Data added successfully");
          location.reload();
        } else {
          alert("Something went wrong");
        }
      },
      (error) => {
        console.error('Failed to add course:', error);
      }
    );
  }

}

<div class="container">
    <a class="btn btn-info text-white font-weight-normal text-xs"
  >
    Update Fun Category</a>
                            <form [formGroup]="funForm" >
                              
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Price</label> -->
                                    <input type="text" class="form-control"  formControlName="title" placeholder="title">
                                </div>
                                  <div class="input-group input-group-outline my-3">

                                <input type="file" id="selectedFile" class="form-control"
                                    (change)="onFileSelected($event)">
                            </div>
                                
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                        </div>
                 
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-news-letter',
  templateUrl: './update-news-letter.component.html',
  styleUrls: ['./update-news-letter.component.css']
})
export class UpdateNewsLetterComponent  implements OnInit{
  eventId: any;
  bannerForm: any;
  base64Image: string;
  evnetform: any;
  base64Data: string;
  
  constructor(private route: ActivatedRoute, private formBuilder:FormBuilder,  private service: CounterService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });
    this.evnetform = this.formBuilder.group({
  
      selectedFile:[null,Validators.required],
  
      file:['', Validators.required],
      image:['', Validators.required],
  
    });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64(file);
  }
  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Data  = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  convertToBase64Banner(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
      console.log(this.base64Image)
    };
    reader.readAsDataURL(file);
  }
  onFileSelectedBanner(event: any): void {
    const file = event.target.files[0];
    this.convertToBase64Banner(file);
  }
  
  
  onSubmit(): void {
    const formData = new FormData();
    console.log(this.base64Data )
   
    formData.append('file',this.base64Data);
    formData.append('image',this.base64Image);
  
    
  
    this.service.updatenewsletter(this.eventId,formData).subscribe(
      (response: any) => {
          if (response.statusCode == '200') {
            alert("Data added successfully");
            location.reload();
          } else {
            alert("Something went wrong");
          }
        },
        (error) => {
          console.error('Failed to add course:', error);
        }
      );
    }
  

}

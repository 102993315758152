<!-- Category -->
<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#category">
                Add Handson Category
            </a>
            <!-- Modal -->
            <div class="modal fade" id="category" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="certificate" >
                              
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Price</label> -->
                                    <input type="text" class="form-control"  formControlName="title" placeholder="title">
                                </div>
                               

           
              

                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()"  class="btn bg-gradient-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr class="justify-content-center">
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 justify-content-center">Sr.no</th>
                       
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
                       
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Status</th>

                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of pagedItems">
                        <td class="ps-4">{{item.index}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.title}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 
                        
                        <!-- <td class="align-middle">
                           
                           

                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editabout" (click)="openEditModal(item)">
                            Edit
                        </a> -->

                          <!-- Modal -->
                          <!-- <div class="modal fade" id="editabout" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form [formGroup]="editForm">
                                        <div class="input-group input-group-outline my-3">
                                            <label class="form-label">Name</label>
                                            <input type="text" id="name" class="form-control" formControlName="title" placeholder="title">
                                        </div>
                                      
                                        
        
                                    </form>


                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="submit" class="btn bg-gradient-primary"(click)="updatecounter(item)" >Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <!-- Modal End -->
                       


                       

                        <td>

                            <!-- <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editabout" (click)="openEditModal(item)">
                            Edit
                        </a> -->
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"(click)="deleteCategory(item.id)">
                                delete
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>

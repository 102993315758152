<!-- our program details -->
<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#diploma_program_category">
                Add Program Details
            </a>
            <!-- Modal -->
            <div class="modal fade" id="diploma_program_category" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="programform">
                                <div class="input-group input-group-outline my-3">
                                    <select id="course" formControlName="program_id" class="form-control" required>
                                        <option value="" disabled selected>select course</option>
                                        <option [value]="course.id" *ngFor="let course of courseDetails" style="color: black;">{{ course.name }}</option>
                                      </select>
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">introduction</label>
                                    <input type="text" class="form-control" formControlName="introduction">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">course overview</label>
                                    <input type="text" class="form-control" formControlName="course_overview">
                                </div>
                                <div class="input-group input-group-outline my-3">

                                    <input type="file" class="form-control" (change)="onFileSelected($event)">
                                </div>
                                <div class="input-group input-group-outline mb-3">
                                    <label class="form-label">select subcourse</label>
                                    <select id="course" formControlName="sub_course_id" class="form-control" required>
                                        <option value="" disabled selected>select subcourse</option>
                                        <option [value]="sub.sub_course_id" *ngFor="let sub of subcourseDetails" style="color: black;">{{ sub.name }}
                                        </option>
                                      </select>
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">learning outcome</label>
                                    <input type="text" class="form-control" formControlName="learning_outcome">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">pre requisite</label>
                                    <input type="text" class="form-control" formControlName="prerequisite">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">duration</label>
                                    <input type="text" class="form-control" formControlName="duration">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">training period</label>
                                    <input type="text" class="form-control" formControlName="training_period">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">batch</label>
                                    <input type="text" class="form-control" formControlName="batch">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">project</label>
                                    <input type="text" class="form-control" formControlName="project">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">course level</label>
                                    <input type="text" class="form-control" formControlName="course_level">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">average salary</label>
                                    <input type="text" class="form-control" formControlName="average_salary">
                                </div>
                              
                               
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">career opportunities</label>
                                    <input type="text" class="form-control" formControlName="career_opportunity">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">why sumago for full stack web development diploma
                                        ?</label>
                                    <input type="text" class="form-control" formControlName="why_sumago">
                                </div>

                                <div class="input-group input-group-outline my-3">
                                    <label class="form-label">who will be trainee ?</label>
                                    <input type="text" class="form-control" formControlName="trainee">
                                </div>

                            </form>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>
                            <button type="submit" (click)="onSubmit()" class="btn bg-gradient-primary">Save
                                changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Id</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Course Name
                        </th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Duration</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Training Period</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Batch </th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Project</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Course Level</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Average Salary</th>

                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Introduction</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Course Overview</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Learning Outcome</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Pre-requisite</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Career Opportunity</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Why Sumago For Web Dev Diploma ?</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Who will be Trainee ?</th>
                        <th class="text-secondary opacity-7">Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of programlist ">
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.program_id}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                                <div>
                                    <img [src]="item.image" class="avatar avatar-sm me-3">
                                </div>

                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.course_Name}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.duration}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.training_period}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.batch}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.project}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.course_level}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.average_salary}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.introduction}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.course_overview}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.learning_outcome}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.prerequisite}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.career_opportunity}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.why_sumago}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-normal">{{item.trainee}}</span>
                        </td>
                        <td class="align-middle">



                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs"
                                (click)="editsyllabus(item.id)">
                                Edit
                            </a>
                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                            (click)="deleteprogramdetail(item.id)">
                            delete
                        </a>
                        </td>
                            <!-- Modal -->
                           
                            <!-- Modal End -->


                          

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- Category -->
<div class="container">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
            data-bs-target="#eventcategory">
            News Letter Data
        </a>
         <!-- Modal -->
         <div class="modal fade" id="eventcategory" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                     <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                         aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                    <form [formGroup]="evnetform" >

                    
                        <div align="right">
                            <div class="input-group input-group-outline my-3">
                                <label for="file">Pdf:</label>
                                <input style="margin-left: 10px;" type="file" id="selectedFile" class="form-control" formControlName="file"
                                    (change)="onFileSelected($event)" placeholder="Pdf">
                            </div>
                            <div class="input-group input-group-outline my-3">
                                <label for="image">Image:</label>
                                <input type="file" class="form-control" formControlName="image"
                                    (change)="onFileSelectedBanner($event)">
                            </div>
                            
                            
                            
                            <button type="button" class="btn bg-gradient-secondary"
                                data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                            <button type="submit" (click)="onSubmit()"   class="btn bg-gradient-primary">Save
                                changes</button>
                        </div>
                    </form>
                </div>
               
             </div>
         </div>
     </div>
     <!-- Modal End -->





     
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">
            
            <table class="table align-items-center mb-0">
                <thead>
                    
                    <tr> 
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th>
                         <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Pdf</th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center">Action</th>
                      
                        <!-- <th class="text-secondary opacity-7">Action</th> -->
                       
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of pagedItems">
                        <td>{{item.index}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.image"
                                        class="avatar avatar-sm me-3">
                                </div>
                                </div>
                            </td>
                        <td>
                            <div class="d-flex flex-row mb-0justify-content-center">
                                <div class="p-2"><p class="mt-2 text-xs font-weight-bold mb-0">{{item.file}}</p></div>
                                
                            </div>
                    

                        </td>
                        
                        <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 
                           
                               
                                <!-- <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">Miriam Eric</h6>
                                    <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p>
                                </div> -->
                           
                        <!-- <td class="align-middle"> -->
                            <!-- <a href="javaScript:void(0)" class="btn-floating" title="View Details"
                            style="background-color: #26A69A;"
                            (click)="updateeventdata(item.id)"><i
                                class="material-icons">remove_red_eye</i></a> -->
                           
<!-- 
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                            data-bs-target="#editeventdata2" (click)="updateeventdata(item.id)">
                            Edit
                        </a> -->

                          <!-- Modal -->
                          <!-- <div class="modal fade" id="editeventdata2" tabindex="-1" role="dialog"
                          aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit Course</h5>
                                      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                    <form>

                                        <div class="input-group input-group-outline mb-3">
                
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Event Category</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <div class="input-group input-group-outline my-3">
                
                                            <input type="file" class="form-control" placeholder="Cover_letter">
                                        </div>
                                       
                                     </form>


                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal">Close</button>
                                      <button type="button" class="btn bg-gradient-primary">Save changes</button>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <!-- Modal End -->

                      <td class="d-flex justify-content-center">
                       
                            <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" 
                           (click)="updateeventdata(item.id)">
                            Edit
                        </a>
                      <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs" (click)="deleteeventdetail(item.id)" >
                      delete
                  </a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-google-review',
  templateUrl: './update-google-review.component.html',
  styleUrls: ['./update-google-review.component.css']
})
export class UpdateGoogleReviewComponent implements OnInit {
  eventId: any;

  
  constructor(private route: ActivatedRoute, private service: CounterService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });

}}

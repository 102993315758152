<div class="container">
    <a class="btn btn-info text-white font-weight-normal text-xs"
  >
    Update Fees Category</a>

              <form [formGroup]="programform" >
                <div class="input-group input-group-outline my-3">
                  <!-- <label class="form-label">Program ID</label> -->
                  <input type="text" class="form-control" formControlName="title" placeholder="Enter category name"
                    >
                </div>

                <div align="right">
                  <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                  <button type="submit" class="btn bg-gradient-primary" (click)="onSubmit()">Save changes</button>

                </div>
              </form>
            </div>
           
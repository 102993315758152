import { Component } from '@angular/core';

@Component({
  selector: 'app-update-emptygrid',
  templateUrl: './update-emptygrid.component.html',
  styleUrls: ['./update-emptygrid.component.css']
})
export class UpdateEmptygridComponent {

}

import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonmainComponent } from 'src/app/components/main/commonmain/commonmain.component';
import { DashboardComponent } from 'src/app/components/main/dashboard/dashboard.component';

import { AboutComponent } from './about/about.component';
// import { AddEventComponent } from 'src/app/Event/add-event/add-event.component';
import { DiplomaProgramCategoryComponent } from './Our_Program/diploma-program-category/diploma-program-category.component';
import { CerticateComponent } from './certicate/certicate.component';
import { ContactComponent } from './contact/contact.component';
import { AddPopularCourseComponent } from './add-popular-course/add-popular-course.component';
import { ArchievementComponent } from './archievement/archievement.component';
import { AboutCounterComponent } from './about-counter/about-counter.component';
// import { EventDataComponent } from 'src/app/Event/event-data/event-data.component';
import { ProgramsDetailsComponent } from './programs-details/programs-details.component';
import { ApplyNowComponent } from './apply-now/apply-now.component';
import { ExpertReviewComponent } from './expert-review/expert-review.component';
import { ConsultingComponent } from './consulting/consulting.component';
import { GoogleReviewComponent } from './google-review/google-review.component';
import { BrochuerComponent } from './brochuer/brochuer.component';
import { BannerComponent } from './banner/banner.component';
import { AluminiComponent } from './alumini/alumini.component';
import { FaqComponent } from './faq/faq.component';
import { HireComponent } from './hire/hire.component';
import { MentorComponent } from './mentor/mentor.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { AddOurofficeComponent } from './add-ouroffice/add-ouroffice.component';
import { AddLogoComponent } from './add-logo/add-logo.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AddSubcourseComponent } from './add-subcourse/add-subcourse.component';
import { CourseComponent } from './course/course.component';
import { ProgramFeesListComponent } from 'src/app/components/main/program-fees-list/program-fees-list.component';
import { CohortsComponent } from './cohorts/cohorts.component';
import { HandsonCategoryComponent } from './handson-category/handson-category.component';
import { HandsonProjectComponent } from './handson-project/handson-project.component';
import { SubcourseDetailsComponent } from './subcourse-details/subcourse-details.component';
import { TrainedStudentComponent } from './trained-student/trained-student.component';
import { ModuleComponent } from './module/module.component';
import { SyllabusModuleComponent } from './syllabus-module/syllabus-module.component';
import { LearnerReviewComponent } from './learner-review/learner-review.component';
import { ProgramCityComponent } from './program-city/program-city.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { CounsellingComponent } from './counselling/counselling.component';
import { CourseHighlightComponent } from './course-highlight/course-highlight.component';
import { ProgramFeesCategoryComponent } from './program-fees-category/program-fees-category.component';
import { SyllabusPdfComponent } from './syllabus-pdf/syllabus-pdf.component';
import { UpcomingComponent } from './upcoming/upcoming.component';
import { EmptyGridComponent } from './empty-grid/empty-grid.component';
import { TopRankComponent } from './top-rank/top-rank.component';
import { UpdateAluminiComponent } from './update-alumini/update-alumini.component';
import { AddEventComponent } from './add-event/add-event.component';
import { EventDataComponent } from './event-data/event-data.component';
import { UpdateEventdataComponent } from './update-eventdata/update-eventdata.component';
import { FunatworkComponent } from './funatwork/funatwork.component';
import { FunatworkdetailComponent } from './funatworkdetail/funatworkdetail.component';
import { RecognitioncategoryComponent } from './recognitioncategory/recognitioncategory.component';
import { RecognitiondetailComponent } from './recognitiondetail/recognitiondetail.component';
import { MoucategoryComponent } from './moucategory/moucategory.component';
import { MoudetailComponent } from './moudetail/moudetail.component';
import { NewsComponent } from './news/news.component';
import { UpdateFunatworkComponent } from './update-funatwork/update-funatwork.component';
import { UpdateSyllabusComponent } from './update-syllabus/update-syllabus.component';
import { UpdateHandsonDetailComponent } from './update-handson-detail/update-handson-detail.component';
import { UpdateCourseComponent } from './update-course/update-course.component';
import { UpdateSubcourseComponent } from './update-subcourse/update-subcourse.component';
import { UpdateSubcoursedetailsComponent } from './update-subcoursedetails/update-subcoursedetails.component';
import { UpdateBannerComponent } from './update-banner/update-banner.component';
import { UpdateUpcomingComponent } from './update-upcoming/update-upcoming.component';
import { UpdateHireComponent } from './update-hire/update-hire.component';
import { UpdateExpertComponent } from './update-expert/update-expert.component';
import { UpdateGoogleReviewComponent } from './update-google-review/update-google-review.component';
import { UpdateSyllabusPdfComponent } from './update-syllabus-pdf/update-syllabus-pdf.component';
import { UpdateModuleComponent } from './update-module/update-module.component';
import { UpdateMentorComponent } from './update-mentor/update-mentor.component';
import { UpdateFeecategoryComponent } from './update-feecategory/update-feecategory.component';
import { UpdateFeedetailsComponent } from './update-feedetails/update-feedetails.component';
import { UpdateCertificateComponent } from './update-certificate/update-certificate.component';
import { UpdateLearnerReviewComponent } from './update-learner-review/update-learner-review.component';
import { UpdateToprankComponent } from './update-toprank/update-toprank.component';
import { UpdateFuncategoryComponent } from './update-funcategory/update-funcategory.component';
import { UpdateFundetailsComponent } from './update-fundetails/update-fundetails.component';
import { UpdateReccatComponent } from './update-reccat/update-reccat.component';
import { UpdateRecdetailComponent } from './update-recdetail/update-recdetail.component';
import { NewsLetterComponent } from './news-letter/news-letter.component';
import { UpdateNewsLetterComponent } from './update-news-letter/update-news-letter.component';




const routes: Routes = [
  {path:'',component:CommonmainComponent,children:[
    {path:'dashboard',component:DashboardComponent},

    // {path:'apply_now',component:ApplyNowComponent},
    {path:'popular_course',component:AddPopularCourseComponent},
    {path:'archievement',component:ArchievementComponent},
    {path:'expert_review',component:ExpertReviewComponent},
    {path:'consulting',component:ConsultingComponent},
    {path:'add_logo',component:AddLogoComponent},
    {path:'add_product',component:AddProductComponent},
    {path:'add_course',component:CourseComponent},
    {path:'add_subcourse',component:AddSubcourseComponent},
    {path:'subcourse_detail',component:SubcourseDetailsComponent},
    {path:'syllabus-pdf',component:SyllabusPdfComponent},
    {path:'about',component:AboutComponent},
    {path:'about_counter',component:AboutCounterComponent},
    {path:'google_review',component:GoogleReviewComponent},
    {path:'add_event_upcoming',component:UpcomingComponent},
    {path:'add_emptygrid',component:EmptyGridComponent},
    {path:'add_top_rank',component:TopRankComponent},
    {path:'update_reccat/:id',component:UpdateReccatComponent},
    {path:'update_recdetail/:id',component:UpdateRecdetailComponent},
    {path:'new_letter',component:NewsLetterComponent},
    {path:'update_new_letter/:id',component:UpdateNewsLetterComponent},
  

    {path:'event',component:AddEventComponent},
    {path:'eventdata',component:EventDataComponent},
    {path:'funatwork',component:FunatworkComponent},
    {path:'funatworkdetails',component:FunatworkdetailComponent},
    {path:'recognition',component:RecognitioncategoryComponent},
    {path:'recognitiondetails',component:RecognitiondetailComponent},
    {path:'mou',component:MoucategoryComponent},
    {path:'moudetails',component:MoudetailComponent},
    {path:'News',component:NewsComponent},
    {path:'training_student',component:TrainedStudentComponent},
    {path:'add_module',component:ModuleComponent},
    {path:'add_syllabus',component:SyllabusModuleComponent},
    {path:'learner_review',component:LearnerReviewComponent},
    { path: 'update-eventdata/:id', component: UpdateEventdataComponent },
    { path: 'update-banner/:id', component: UpdateBannerComponent },
    { path:'update-upcoming/:id',component:UpdateUpcomingComponent},
    { path:'update-hire/:id',component:UpdateHireComponent},
    { path:'update-expert/:id',component:UpdateExpertComponent},
    { path:'update_google/:id',component:UpdateGoogleReviewComponent},
    { path:'update-syllabus-pdf/:id',component:UpdateSyllabusPdfComponent},
    { path:'update_learner/:id',component:UpdateLearnerReviewComponent},
    {path:'update_topranked/:id',component:UpdateToprankComponent},

    {path:'diploma_program_category',component:DiplomaProgramCategoryComponent},
    {path:'program_details',component:ProgramsDetailsComponent},
    {path:'Syllanuspdf_info',component:BrochuerComponent},
    {path:'program_city',component:ProgramCityComponent},
    {path:'highlights',component:HighlightsComponent},
    {path:'counselling',component:CounsellingComponent},
    {path:'course_highlights',component:CourseHighlightComponent},
    {path:'brochure',component:BrochuerComponent},
    { path:'update_module/:id',component:UpdateModuleComponent},
    {path:'update_mentor/:id',component:UpdateMentorComponent},
    {path:'update_feecategory/:id',component:UpdateFeecategoryComponent},
    { path:'update_feedetails/:id',component:UpdateFeedetailsComponent},
    { path:'update_certificate/:id',component:UpdateCertificateComponent},
    { path:'update_funcategory/:id',component:UpdateFunatworkComponent},

   


    {path:'certificate',component:CerticateComponent},
    // {path:'contact',component:ContactComponent},
    {path:'Company_detail',component:CompanyDetailsComponent},
    {path:'Our_Office',component:AddOurofficeComponent},
    {path:'update_alumini/:id',component:UpdateAluminiComponent},
    {path:'update_fundetails/:id',component:UpdateFundetailsComponent},
    {path:'update-syllabus/:id',component:UpdateSyllabusComponent},
    {path:'update-handson-detail/:id',component:UpdateHandsonDetailComponent},
    {path:'update-course/:id',component:UpdateCourseComponent},
    {path:'update-subcourse/:id',component:UpdateSubcourseComponent},
    {path:'update-subcoursedetails/:id',component:UpdateSubcoursedetailsComponent},

    {path:'banner',component:BannerComponent},
    {path:'alumini',component:AluminiComponent},
    {path:'faq',component:FaqComponent},
    {path:'hire',component:HireComponent},
    {path:'mentor',component:MentorComponent},
    {path:'program-fees-list',component:ProgramFeesListComponent},
    {path:'cohorts',component:CohortsComponent},
    {path:'handsonCategory',component:HandsonCategoryComponent},
    {path:'handsonProject',component:HandsonProjectComponent},
    {path:'program-fees-category',component:ProgramFeesCategoryComponent},
    {path:'update_alumini/:id',component:UpdateAluminiComponent},

    {path:'',redirectTo:'/main/dashboard',pathMatch:'full'},
    


  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }

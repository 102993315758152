import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CounterService } from 'src/app/services/counter.service';

@Component({
  selector: 'app-update-banner',
  templateUrl: './update-banner.component.html',
  styleUrls: ['./update-banner.component.css']
})
export class UpdateBannerComponent implements OnInit{
 
  eventId: any;
  selectedFile: File | null = null;
  base64Image: string | null = null;
  bannerForm: any;
  formBuilder: any;
  base64Images: any;
  eventid: number;
  ban: any;

  constructor(private route: ActivatedRoute, private service: CounterService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      console.log(this.eventId);
      
    });

    this.bannerForm = this.formBuilder.group({
      // title: ['', Validators.required],
      // description: ['', Validators.required],
      selectedFiles: [null, Validators.required] // Use selectedFiles for multiple files
    });
    

    // Initialize the base64Image with the current image URL from the server
    // this.getBirthday();
  }

  onFilesSelected(event: any): void {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.convertToBase64(files[i], i);
    }
  }

  convertToBase64(file: File, index: number): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Images[index] = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onSubmit(): void {
    const formData = new FormData();
    formData.append('title', this.bannerForm.value.title);
    formData.append('description', this.bannerForm.value.description);

    // Append all images
    for (const image of this.base64Images) {
      formData.append('images',image);
    }

    this.service.updatebanner(this.eventId,formData).subscribe(
      (response: any) => {
        console.log('Data added successfully:', response);
        this.ban = response;
        // this.clearForm(); // Optionally, clear the form after submission
        if(response.StatusCode == '200') {
          // this.router.navigate(['/main/banner'])
          alert("Update successfully");
          location.reload();

        } else {
          alert("Something went wrong");
        }
      },
      (error) => {
        console.error('Failed to add banner:', error);
      }
    );
  }
}



<div class="container main-part">
    <div class="row">
        <div class="div">
            <a class="btn btn-info text-white font-weight-normal text-xs" data-bs-toggle="modal"
                data-bs-target="#addalumini">
                Add Alumini</a>
            <!-- Modal -->
            <div class="modal fade" id="addalumini" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Add Details</h5>
                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="aluminiform" >

                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label"> Course Name</label> -->
                                    <!-- <select  class="form-control" formControlName="sub_course_id" >
                                        <option value="">select sub course</option>

                                        <option *ngFor="let subCourse of subcourses" [value]="subCourse.subcourses_id">{{
                                            subCourse.subcourses_name }}</option>
                                            
                                    </select> -->

                                    <select class="form-control" formControlName="sub_course_id" multiple>
                                        <option value="">Select sub course</option>
                                        <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.subcourses_id">
                                            {{ subCourse.subcourses_name }}
                                        </option>
                                    </select>
                                   
                                      <!-- <label class="form-label select-label">Example label</label> -->
                                    <div class="invalid-feedback">Course is required</div>
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Name</label> -->
                                    <input type="text" id="name" class="form-control" formControlName="name"
                                        placeholder="name">
                                </div>

                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">designation</label> -->
                                    <input type="text" id="designation" class="form-control"
                                        formControlName="designation" placeholder="designation">
                                </div>
                                <div class="input-group input-group-outline my-3">
                                    <!-- <label class="form-label">Company</label> -->
                                    <input type="text" id="designation" class="form-control" formControlName="company"
                                        placeholder="company">
                                </div>

                                <div align="right">
                                    <div class="input-group input-group-outline my-3">

                                        <input type="file" id="selectedFile" class="form-control"
                                            (change)="onFileSelected($event)">
                                    </div>
                                    <div class="input-group input-group-outline my-3">

                                        <input type="file" id="company_logo" class="form-control"
                                            (change)="onFileSelectedlogo($event)">
                                    </div>
                                    
                                    <button type="button" class="btn bg-gradient-secondary"
                                        data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                                    <button type="submit" (click)="onSubmit()"   class="btn bg-gradient-primary">Save
                                        changes</button>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal End -->
        </div>
    </div>
    <div class="card">
        <div class="div"></div>
        <div class="table-responsive">

            <table class="table align-items-center mb-0">
                <thead>

                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sr.no</th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>

                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Designation</th>
                        <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Company</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Logo</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">  Course name</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">  Status</th>


                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-flex justify-content-center">Action</th>

                    </tr>
                </thead>
                <tbody style="text-align: center;">

                    <tr *ngFor="let item of pagedItems">
                        <td>{{item.index}}</td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>
                                    <img [src]="item.image" class="avatar avatar-sm me-3">
                                </div>
                                
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{item.name}}</h6>
                                    <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="text-xs font-weight-bold mb-0">{{item.designation}}</p>
                            <!-- <p class="text-xs text-secondary mb-0">Developer</p> -->
                        </td>
                        <td>
                            <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-xs">{{item.company}}</h6>
                                <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                            </div>
                            
                        </td>
                        <div>
                            <img [src]="item.company_logo" class="avatar avatar-sm me-3">
                        </div>
                        <td>
                            <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-xs">{{item.subcourse_details}}</h6>
                                <!-- <p class="text-xs text-secondary mb-0">miriam@creative-tim.com</p> -->
                            </div>
                            
                        </td>
                        <td><toggle-button  [config]="config" (click)="changeStatus(item)">
                        </toggle-button></td> 


                       
                        <!-- <td class="align-middle"> -->
                            <!-- <a href="javaScript:void(0)" class="btn-floating" title="View Details"
                            style="background-color: #26A69A;"
                            (click)="updateAlumini1(item.id)"><i
                                class="material-icons">remove_red_eye</i></a>
                            </td> -->
<!-- 
                            <a  class="btn btn-warning ms-2 text-white font-weight-normal text-xs" 
                            (click)="updateAlumini1(item.id)" >
                            Edit
                        </a> -->
                        <!-- <a class="btn btn-warning ms-2 text-white font-weight-normal text-xs" data-bs-toggle="modal"
                                (click)="openEditModal(item)" data-bs-target="#editalumini">
                                Edit
                            </a> -->
                            <!-- <a  class="btn btn-warning ms-2 text-white font-weight-normal text-xs" 
                            (click)="updateAlumini1(item.id)" >
                            Edit
                        </a> -->
                            <!-- Modal -->
                            <!-- <div class="modal fade" id="editalumini" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Edit
                                                Course</h5>
                                            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form [formGroup]="editForm" >

                                                <div class="input-group input-group-outline my-3">
                                          
                
                                                    <select class="form-control" formControlName="sub_course_id" multiple>
                                                        <option value="">Select sub course</option>
                                                        <option *ngFor="let subCourse of subcourses" [ngValue]="subCourse.subcourses_id">
                                                            {{ subCourse.subcourses_name }}
                                                        </option>
                                                    </select>
                                                   
                                                      <label class="form-label select-label">Example label</label>
                                                    <div class="invalid-feedback">Course is required</div>
                                                </div>
                                                <div class="input-group input-group-outline my-3">
                                                    <label class="form-label">Name</label>
                                                    <input type="text" id="name" class="form-control" formControlName="name"
                                                        placeholder="name">
                                                </div>
                
                                                <div class="input-group input-group-outline my-3">
                                                    <label class="form-label">designation</label>
                                                    <input type="text" id="designation" class="form-control"
                                                        formControlName="designation" placeholder="designation">
                                                </div>
                                                <div class="input-group input-group-outline my-3">
                                                    <label class="form-label">Company</label>
                                                    <input type="text" id="designation" class="form-control" formControlName="company"
                                                        placeholder="company">
                                                </div>
                
                                                <div align="right">
                                                    <div class="input-group input-group-outline my-3">
                
                                                        <input type="file" id="selectedFile" class="form-control"
                                                            (change)="onFileSelected($event)">
                                                    </div>
                                                    <div class="input-group input-group-outline my-3">
                
                                                        <input type="file" id="company_logo" class="form-control"
                                                            (change)="onFileSelectedlogo($event)">
                                                    </div>
                                                    
                                                    <button type="button" class="btn bg-gradient-secondary"
                                                        data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                                                    <button type="submit" (click)="updateAlumini1(item.id)"   class="btn bg-gradient-primary">Save
                                                        changes</button>
                                                </div>
                                            </form>


                                        </div>
                                       
                                    </div>
                                </div>
                            </div> -->
                            <!-- Modal End -->


                        <td class="d-flex justify-content-center">
                            <a  class="btn btn-warning ms-2 text-white font-weight-normal text-xs" 
                            (click)="updateAlumini1(item.id)" >
                            Edit
                        </a>

                            <a class="btn btn-danger ms-2 text-white font-weight-normal text-xs"
                                (click)="deletealuminies(item.id)">
                                delete
                            </a>

                        </td>


                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-info justify-content-end">
                    <li class="page-item" [class.disabled]="currentPage == 1">
                        <a class="page-link"  (click)="prevPage()"> <span class="material-icons">
                            keyboard_arrow_left
                          </span></a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage == totalPages">
                        <a class="page-link" (click)="nextPage()">  <span class="material-icons">
                            keyboard_arrow_right
                          </span></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>
<br>
<br>